import { AlertTitle, Box, Button, CircularProgress, Dialog, Grid, Paper, Slide, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { live } from '../../App';
import './pricing.css'
import {useCookies} from 'react-cookie'


export default function Pricing() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  const [active_plan,setActive_plan]=useState('')
  const [processing,setProcessing]=useState(false)
  const [open, setOpen] = React.useState(false);
  const [openS, setOpenS] = React.useState(false);
  const [openP, setOpenP] = React.useState(false);

  const[auth,setAuth]=useCookies(['drivewide'])
  const [msg,setMsg]=useState('')
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = () => {
    setOpen(false);
    setOpenS(false);
    setOpenP(false)
    setActive_plan('')


  };
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      // setSuccess(true);
      setOpen(true)
      setSessionId(query.get('id'));
      createPortalSession(query.get('id'))
    }

    if (query.get('canceled')) {
      setOpen(true)

      setSuccess(false);
      Canceled(query.get('id'))
     
    }
  }, [sessionId]);


  const Canceled=(val)=>{
    fetch(live+`create-checkout-session?id=${val}`).then(res=>res.json()).then(resp=>cancelRes(resp))

  }

  const cancelRes=(resp)=>{
    if(resp.status){
      setOpen(false)
      setMessage(
        "Subscription canceled -- subscribe to DriveWide when you're ready."
      );
    }
    else{
      setOpen(false)

      setMessage(resp.detail)
    }
  }
const subscribeClicked=(val,plan,count,price_id)=>{

  if(auth.drivewide&&auth.drivewide.business_id)

  {
    if((!auth.drivewide.session_id)||(auth.drivewide.plan_type==='year'&&plan!=='year')||(auth.drivewide.plan_type!=='year'&&plan==='year')||(auth.drivewide.plan_type!=='year'&&plan!=='year'&&auth.drivewide.payment_intervals!=count)){
      

    
      setOpen(true)
setProcessing(true)
  const formData=new FormData()
  formData.append('product',val)
  formData.append('business_id',auth.drivewide.business_id)
  formData.append('price_id',price_id)


  fetch(live+'create-checkout-session',{
    method:'POST',
    body:formData
  }).then((res)=>res.json()).then(resp=>subscribeRes(resp))
    }
    else{
      setMsg('Plan already subscribed and is active')
      setOpenS(true)    }


}
else{
  setMsg('Please login as business to subscribe a plan')
  setOpenS(true)
}

}

const subscribeRes=(resp)=>{
  if(resp&&resp.url)
  {window.open(resp.url,"_self")
}
else if(resp&&resp.status){
  setAuth('drivewide',resp.data,{
    maxAge: 3600 ,
    expires:0,
    path:'/'
 })
  setOpen(false)
    setSuccess(false)
    setMessage(resp.detail)


}
  else{
    setOpen(false)
    setSuccess(false)
    setMessage('Payment failed, please try again later')
  }

}
const createPortalSession=(val)=>{
  setProcessing(true)
    const formData=new FormData()
    formData.append('session_id',val)
    formData.append('business_id',auth.drivewide.business_id)

    fetch(live+'create-checkout-session',{
      method:'PUT',
      body:formData
    }).then((res)=>res.json()).then(resp=>sessionRes(resp))
  }


  const sessionRes=(val)=>{
    if(val.status){
      setProcessing(false)
      setOpen(false)
      setSuccess(true)
    }
    else{
      setOpen(false)
      setSuccess(false)
      setMessage('Something went wrong, Please contact our support team')
    }
  }
  const subscribeClicked1=(active)=>{
    if(active){
      setActive_plan(active)
      setOpenP(true)

    }
    else{
      if(active_plan==='monthly'){
        subscribeClicked('prod_Nx7BqenYKiTHJ5','monthly',1,'price_1NBCxWH416pJc0EnLYvBwUem',1)
        setOpenP(false)
      }
      else if(active_plan==='6 months'){
        setOpenP(false)

        subscribeClicked('prod_Nx7DtLQFWh2iCZ','monthly',6,'price_1NBCymH416pJc0En913yyma0',2)
      }
      else if(active_plan==='yearly'){
        setOpenP(false)

        subscribeClicked('prod_Nx7DoZqY0dGbGo','year',1,'price_1NBCyvH416pJc0En6Dixx9cG',3)
      }
    }
   
  }
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const ProductDisplay = () => (

  
  
    <Grid container justifyContent={'center'}>
      <Snackbar open={openS} autoHideDuration={6000} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
      <Snackbar open={openP}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} style={{background:'white',color:'var(--green)',marginRight:'1vw'}}  size="small">
            No
          </Button>
            <Button onClick={()=>subscribeClicked1()} style={{color:'white',background:'var(--green)'}} size="small">
            Subscribe 
          </Button>
          </div>} onClose={handleClose} severity="info">
        <AlertTitle>Warning!! <br />Are you sure to subscribe {active_plan} plan
</AlertTitle>

      </Alert>
     
     
      </Snackbar>
        <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
      <Grid item sm={12} xs={12}>
      <Box sx={{ display: 'flex',justifyContent:'center' }} >
      <CircularProgress style={{marginTop:'25vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box>
        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>Payment processing, please wait</Typography>
      </Grid>
    </Grid>
   
  </Dialog>
      <Grid item sm={12} xs={12}>
        <Typography className='pricing_head'>
         Plans & Pricing
        </Typography>
    
      </Grid>
      <Grid item style={{background:'var(--green)',margin:'3vh 0px',padding:'2px 0px'}} sm={12} xs={12}>
        <Typography style={{color:'white'}} className='pricing_plan_head'>
          One plan for all <br/>Starts at £49.90 per month
        </Typography>
    
      </Grid>
      <Grid container style={{margin:'3vh 0px'}}>


      <Grid container style={{textAlign:'-webkit-center',justifyContent:'center',margin:'1vh 0px'}} sm={4} xs={12}>
        <Paper elevation={5} className='pricing_ppr'>
   <Grid style={{padding:'15px',borderRadius:'20px',    height: '100%',
    display: 'grid',
    alignContent: 'center'}}>
      <Typography className='headP'>
Monthly
      </Typography>
      
     
      <Typography className='sub_headP'>
per Month Plan
      </Typography>
      
     
      <Typography className='price_p'>
      £49.90
      </Typography>
     
      <Typography className='sub_price_p'>
per month      </Typography>
    
      <Grid item  sm={12} xs={12}>
        
        {/* prod_NZZzCTq2xwrDBh */}
      <Button onClick={()=>subscribeClicked1('monthly')} className='pricing_plan_btn_new'>
      {processing?'Subscribing':'Subscribe '}
      </Button>
      </Grid>
     
      <Typography className='details_p'>
      ‣ Create and manage jobs
  </Typography>
  <Typography className='details_p'>
  ‣ Instantly create and post driving jobs, thereafter tract and manage

</Typography>
<Typography className='details_p'>
‣ Dashboard that provides real-time monitoring

</Typography>
<Typography className='details_p'>
‣ Manage your entire workflow on app

</Typography>
<Typography className='details_p'>
‣ Hire verified drivers instantly

</Typography>

<Typography className='details_p'>
‣ All id checks done for you

</Typography>

</Grid>



        </Paper>
        {/* <Grid item  sm={12} xs={12}>
        
        {/* prod_NZZzCTq2xwrDBh 
      <Button onClick={()=>subscribeClicked('prod_NZaMdBzgSsAzwp','day',1,'price_1MoRBaH416pJc0En3vNyzkg5')} className='pricing_plan_btn_new'>
      {processing?'Subscribing':'Subscribe'}
      </Button>
      </Grid>  */}
    
      </Grid>


      <Grid container style={{textAlign:'-webkit-center',justifyContent:'center',margin:'1vh 0px'}} sm={4} xs={12}>
        <Paper elevation={5} className='pricing_ppr'>
   <Grid style={{padding:'15px',borderRadius:'20px',    height: '100%',
    display: 'grid',
    alignContent: 'center'}}>
      <Typography className='headP'>
      6 Months (10% off)
      </Typography>
      
     
      <Typography className='sub_headP'>
6 Months Plan
      </Typography>
      
     
      <Typography className='price_p'>
      £269.90 
      </Typography>
     
      <Typography className='sub_price_p'>
per 6 months      </Typography>
    
      <Grid item  sm={12} xs={12}>
        
      {/* <Button onClick={()=>subscribeClicked('prod_NZaGZLO16xcBBJ','month',6,'price_1MoR6QH416pJc0En3jveOO8q')} className='pricing_plan_btn_new'> */}
      <Button onClick={()=>subscribeClicked1('6 months')} className='pricing_plan_btn_new'>
      {processing?'Subscribing':'Subscribe '}
      </Button>
      </Grid>
     
      <Typography className='details_p'>
      ‣ Create and manage jobs
  </Typography>
  <Typography className='details_p'>
  ‣ Instantly create and post driving jobs, thereafter tract and manage

</Typography>
<Typography className='details_p'>
‣ Dashboard that provides real-time monitoring

</Typography>
<Typography className='details_p'>
‣ Manage your entire workflow on app

</Typography>
<Typography className='details_p'>
‣ Hire verified drivers instantly

</Typography>

<Typography className='details_p'>
‣ All id checks done for you

</Typography>

</Grid>



        </Paper>
     
    
      </Grid>



      <Grid container style={{textAlign:'-webkit-center',justifyContent:'center',margin:'1vh 0px'}} sm={4} xs={12}>
        <Paper elevation={5} className='pricing_ppr'>
   <Grid style={{padding:'15px',borderRadius:'20px',    height: '100%',
    display: 'grid',
    alignContent: 'center'}}>
      <Typography className='headP'>
      Yearly (20% off)
      </Typography>
      
     
      <Typography className='sub_headP'>
Per Year Plan
      </Typography>
      
     
      <Typography className='price_p'>
      £479.90
      </Typography>
     
      <Typography className='sub_price_p'>
per year      </Typography>
    
      <Grid item  sm={12} xs={12}>
        
      {/* <Button onClick={()=>subscribeClicked('prod_NZaGZLO16xcBBJ','month',6,'price_1MoR6QH416pJc0En3jveOO8q')} className='pricing_plan_btn_new'> */}
      <Button onClick={()=>subscribeClicked1('yearly')} className='pricing_plan_btn_new'>
      {processing?'Subscribing':'Subscribe '}
      </Button>
      </Grid>
     
      <Typography className='details_p'>
      ‣ Create and manage jobs
  </Typography>
  <Typography className='details_p'>
  ‣ Instantly create and post driving jobs, thereafter tract and manage

</Typography>
<Typography className='details_p'>
‣ Dashboard that provides real-time monitoring

</Typography>
<Typography className='details_p'>
‣ Manage your entire workflow on app

</Typography>
<Typography className='details_p'>
‣ Hire verified drivers instantly

</Typography>

<Typography className='details_p'>
‣ All id checks done for you

</Typography>

</Grid>



        </Paper>
     
    
      </Grid>

     
      </Grid>
      {/* <Grid container style={{margin:'3vh 0px',maxWidth:'80%'}}>
      <Grid item sm={12} xs={12}>
        <Typography style={{textAlign:'left'}} className='pricing_plan_head'>
          What you will get :-
        </Typography>
    
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography style={{textAlign:'left'}} className='pricing_plan_detail'>
&#8227; Create and manage jobs        </Typography>
<Typography style={{textAlign:'left'}} className='pricing_plan_detail'>
&#8227; Instantly create and post driving jobs, thereafter tract and manage     
   </Typography>
   <Typography style={{textAlign:'left'}} className='pricing_plan_detail'>
&#8227; Dashboard that provides real-time monitoring    
   </Typography>
   <Typography style={{textAlign:'left'}} className='pricing_plan_detail'>
&#8227; Manage your entire workflow on app    
   </Typography>
   <Typography style={{textAlign:'left'}} className='pricing_plan_detail'>
&#8227; Hire verified drivers instantly   
   </Typography>
   <Typography style={{textAlign:'left'}} className='pricing_plan_detail'>
&#8227; All id checks done for you   
   </Typography>
    
      </Grid>
</Grid> */}
    </Grid>
    );
    
    const SuccessDisplay = ({ sessionId }) => {
      return (

        <Grid container>
        <Grid item sm={12} xs={12}>
          <Typography className='pricing_head'>
          Subscription to plan successful!        
            </Typography>
      
        </Grid>
        <Grid container sm={12} xs={12}>

        <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
        <Button onClick={()=>window.location.href='/for_business'} className='pricing_plan_btn_new'>
        Back to Pricing Page
        </Button>
        </Grid> 
      
      
        </Grid>
      </Grid>
    
      );
    };
    
    const Message = ({ message }) => (
   
      <Grid container>
      <Grid item sm={12} xs={12}>
        <Typography className='pricing_head'>
          Subscriptions Payment
        </Typography>
    
      </Grid>
      <Grid container sm={12} xs={12}>
        {/* <Paper elevation={5} className='pricing_ppr'> */}
    <Grid style={{textAlign:'center',width:'80%',paddingTop:'2vh'}} item sm={12} xs={12}>
      <Typography className='pricing_plan_head'>
  {message}
      </Typography>
      </Grid> 
      <Grid style={{marginBottom:'20vh',textAlign:'center'}}  item sm={12} xs={12}>
      <Button onClick={()=>window.location.href='/for_business'} className='pricing_plan_btn_new'>
        Back to Pricing Page
        </Button>
        </Grid>
        {/* </Paper> */}
    
      </Grid>
    </Grid>
    );


  if (!success && message === '') {
    return <ProductDisplay />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}

