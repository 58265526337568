import React from 'react'
import { Button, Grid, Paper, Typography } from '@mui/material'
import './HIW_bg.css'
export default function HIW_bg() {
  return (

<div className="HIW">

<img src={require('../../../images/home_bg.jpg')} className='HIW_bg' />

<Grid container className='HIW_bg_maincont'>
<Grid container sm={7} xs={12}>
<Grid item sm={12} xs={12}>
<Typography className='HIW_bg_head'>

    
Your Drivers will Thank You.

</Typography>

</Grid>
<Grid item sm={12} xs={12}>
<Typography className='HIW_bg_text'>
Get back more of your day by streamlining your driver's experience
<br /><br />
DriveWide is the most convenient way to earn for driving. 
Our user-friendly solution takes all of your hassles away by managing your driving needs. 
Bringing you a new age modern digital experience. 
{/* DRIVE WIDE is the most convenient way to earn for driving. It’s easy use our app. Get helpful tips on using each method below. */}
</Typography>

</Grid>
<Grid container sm={12} xs={12}>
<Grid item >
<img src={require('../../../images/app-store.png')} className='HIW_bg_app' onClick={()=>window.open("https://apps.apple.com/app/drivewide/id6447601344")} />

</Grid>
<Grid item style={{paddingLeft:'2vw'}} >
<img src={require('../../../images/play-store.png')} className='HIW_bg_app' onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.drivewide.drivewide")}/>

</Grid>

</Grid>

</Grid>
<Grid item style={{textAlign:'center'}} sm={5} xs={12}>
<img src={require('../../../images/works.svg').default} className='HIW_bg_img' />


</Grid>
</Grid>



    </div>    )
}
