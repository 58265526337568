import { Container, Grid, Typography,Accordion,AccordionDetails,AccordionSummary, TextField,Chip, FormControlLabel, FormGroup, Switch, Tooltip, IconButton, Snackbar, Alert, Button, Checkbox, Card, CardActionArea, CardMedia, CardContent, AlertTitle, Dialog, DialogContent} from '@mui/material'
import  ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useState } from 'react'
import {useCookies} from 'react-cookie'
import ReactPhoneInput from "react-phone-input-2";
import CloseIcon from '@mui/icons-material/Close';

import './job_post.css'
import { Driving_category, Job_type, Language_P, Pay_type } from './data'
import { imgLink, live } from '../../App';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { CheckBox } from '@mui/icons-material';
export default function Driver_details(props) {
    const driver=props.edit
const store=props.store
  const[auth,setAuth]=useCookies(['drivewide'])
  const [activeA,setActiveA]=useState('')
  const[openA,setOpenA]=useState(false)
  const[suggestions,setSuggestions]=useState()
    const [active1,setActive1]=useState(false)
    const [data,setData]=useState({
      driver_id:driver.driver_id,
      fname:driver.fname?driver.fname:'',
        lname:driver.lname?driver.lname:'',
        mobile:driver.mobile?driver.mobile:'',
        country_code:driver.country_code?driver.country_code:'',
        email:driver.email?driver.email:'',
        address_line1:driver.address_line1?driver.address_line1:'',
        address_line2:driver.address_line2?driver.address_line2:'',
        city:driver.city?driver.city:'',
        stat:driver.stat?driver.stat:'',
        dob:driver.dob?driver.dob:'',

        country:driver.country,
        zip_code:driver.zip_code?driver.zip_code:'',
        language:driver.language?driver.language:'',
        utr_number:driver.utr_number?driver.utr_number:'',
        dl_number:driver.dl_number?driver.dl_number:'',
        driver_card_number:driver.driver_card_number?driver.driver_card_number:'',
        cpc_card_number:driver.cpc_card_number?driver.cpc_card_number:'',
        passport_number:driver.passport_number?driver.passport_number:'',
        visa_number:driver.visa_number?driver.visa_number:'',
        sharecode:driver.sharecode?driver.sharecode:'',
        experience_years:driver.experience_years,
        experience_months:driver.experience_months,
skills:driver.skills,
        is_verified:driver.is_verified,
        is_active:driver.is_active,

        citizen:driver.citizen,

        dl_number_image:driver.dl_number_image,
        dl_number_image_back:driver.dl_number_image_back,
        driver_card_image:driver.driver_card_image,
        driver_card_image_back:driver.driver_card_image_back,
        cpc_card_image:driver.cpc_card_image,
        cpc_card_image_back:driver.cpc_card_image_back,
        passport_number_image:driver.passport_number_image,
        visa_number_image:driver.visa_number_image,
        cv_upload:driver.cv_upload,
        licence_category:driver.licence_category?driver.licence_category:'',
        dl_expiry:driver.dl_expiry?driver.dl_expiry:'',
        tacho_expiry:driver.tacho_expiry?driver.tacho_expiry:'',
        cpc_expiry:driver.cpc_expiry?driver.cpc_expiry:'',
        passport_expiry:driver.passport_expiry?driver.passport_expiry:'',
        visa_expiry:driver.visa_expiry?driver.visa_expiry:'',
        profile:driver.profile?driver.profile:'',



    })

    const Phone=(val,val1)=>{
        setData({...data,code:'+'+val.dialCode,mobile:val1.replace('+'+val.dialCode+' ','')})
        }
    const[errMsg,setError]=useState(false)
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)
const [open3,setOpen3]=useState(false)
const [imageV,setImageView]=useState('')
const [edit,setEdit]=useState(false)


const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setOpen3(false)


}
let auth_new={
  driver_id:data.driver_id,
  fname:data.fname,
  lname:data.lname,
  email:data.email,
  type:'Driver',
  is_active:data.is_active,
  is_verified:data.is_verified
}
  


const addSkills=()=>{
  const item={
    'id':data.skills&&data.skills.length?(data.skills.length+1):1,
    "skill":data.skill
  }

  if(data&&data.skills&&data.skills[0]&&data.skills[0].skill){

    if(data.skills.find(x=>x.skill!==data.skill))
    {
   
    }
    else{
      setData({
        ...data,skills:[...data.skills,item]
      })
    }
   

  }
  else{
    setData({
      ...data,skills:[item]
    })
  }
 
}

    const Cancel=()=>{
      setData({
        driver_id:driver.driver_id,

        ...data,fname:driver.fname?driver.fname:'',
        lname:driver.lname?driver.lname:'',
        mobile:driver.mobile?driver.mobile:'',
        country_code:driver.country_code?driver.country_code:'',
        email:driver.email?driver.email:'',
        address_line1:driver.address_line1?driver.address_line1:'',
        address_line2:driver.address_line2?driver.address_line2:'',
        city:driver.city?driver.city:'',
        skills:driver.skills,

        stat:driver.stat?driver.stat:'',
        country:driver.country,
        zip_code:driver.zip_code?driver.zip_code:'',
        language:driver.language?driver.language:'',
        utr_number:driver.utr_number?driver.utr_number:'',
        dl_number:driver.dl_number?driver.dl_number:'',
        driver_card_number:driver.driver_card_number?driver.driver_card_number:'',
        cpc_card_number:driver.cpc_card_number?driver.cpc_card_number:'',
        passport_number:driver.passport_number?driver.passport_number:'',
        visa_number:driver.visa_number?driver.visa_number:'',
        sharecode:driver.sharecode?driver.sharecode:'',
        experience_years:driver.experience_years,
        experience_months:driver.experience_months,

        is_verified:driver.is_verified,
        citizen:driver.citizen,
        dob:driver.dob,


        dl_number_image:driver.dl_number_image,
        dl_number_image_back:driver.dl_number_image_back,
        driver_card_image:driver.driver_card_image,
        driver_card_image_back:driver.driver_card_image_back,
        cpc_card_image:driver.cpc_card_image,
        cpc_card_image_back:driver.cpc_card_image_back,
        passport_number_image:driver.passport_number_image,
        visa_number_image:driver.visa_number_image,
        cv_upload:driver.cv_upload,
        skills:driver.skills,
        dl_expiry:driver.dl_expiry?driver.dl_expiry:'',
    tacho_expiry:driver.tacho_expiry?driver.tacho_expiry:'',
    cpc_expiry:driver.cpc_expiry?driver.cpc_expiry:'',
    passport_expiry:driver.passport_expiry?driver.passport_expiry:'',
    visa_expiry:driver.visa_expiry?driver.visa_expiry:'',
    profile:driver.profile?driver.profile:'',
            is_active:driver.is_active,


      })

      setEdit(false)
    }
 
const submitJob=()=>{

if (edit){
  setData({
    ...data,processing:true
  })
  // setEdit(true)
  
  getLatLong()
}
else{
  setEdit(true)
}

}
const editRes=(val)=>{
 if(val.status&&!store) {
  setData({
    ...data,processing:false
  })
  setOpen1(true)
  setError(val.detail)
  props.backPressed()
 }
 if(val.status&&store){
  setData({
    ...data,
    driver_id:val.data.driver_id?val.data.driver_id:'',
  
    fname:val.data.fname,
    lname:val.data.lname,
    mobile:val.data.mobile,
    country_code:val.data.country_code,
    email:val.data.email,
    dob:val.data.dob?val.data.dob:'',
  
    address_line1:val.data.address_line1,
    address_line2:val.data.address_line2,
    city:val.data.city,
    stat:val.data.stat,
    country:val.data.country,
    zip_code:val.data.zip_code,
    language:val.data.language,
    utr_number:val.data.utr_number?val.data.utr_number:'',
    dl_number:val.data.dl_number?val.data.dl_number:'',
    driver_card_number:val.data.driver_card_number?val.data.driver_card_number:'',
    cpc_card_number:val.data.cpc_card_number?val.data.cpc_card_number:'',
    passport_number:val.data.passport_number?val.data.passport_number:'',
    visa_number:val.data.visa_number?val.data.visa_number:'',
    sharecode:val.data.sharecode?val.data.sharecode:'',
    experience_years:val.data.experience_years?val.data.experience_years.toString():'0',
    experience_months:val.data.experience_months?val.data.experience_months.toString():'0',
  
    is_verified:val.data.is_verified,
    citizen:val.data.citizen,
    classC:val.data.classC,
  profile:val.data.profile,
  
  
    dl_number_image:val.data.dl_number_image,
    dl_number_image_back:val.data.dl_number_image_back,
    driver_card_image:val.data.driver_card_image,
    driver_card_image_back:val.data.driver_card_image_back,
    cpc_card_image:val.data.cpc_card_image,
    cpc_card_image_back:val.data.cpc_card_image_back,
    passport_number_image:val.data.passport_number_image,
    visa_number_image:val.data.visa_number_image,
    cv_upload:val.data.cv_upload,
    skills:val.data.skills,
    criminal_record:val.data.criminal_record,
    criminal_record_text:val.data.criminal_record_text,
    account_name:val.data.account_name,
    account_number:val.data.account_number,
    sort_code:val.data.sort_code,
    bank_name:val.data.bank_name,
    applied:val.data.applied,
    accepted:val.data.accepted,
    licence_category:val.data.licence_category,
    dl_expiry:val.data.dl_expiry?val.data.dl_expiry:'',
    tacho_expiry:val.data.tacho_expiry?val.data.tacho_expiry:'',
    cpc_expiry:val.data.cpc_expiry?val.data.cpc_expiry:'',
    passport_expiry:val.data.passport_expiry?val.data.passport_expiry:'',
    visa_expiry:val.data.visa_expiry?val.data.visa_expiry:'',
    lat:val.data.lat?val.data.lat:'',
    lng:val.data.lng?val.data.lng:'',
    is_active:val.data.is_active,

    processing:false
    // criminal_record:res.data.criminal_record,
  
  
  })
  setOpen1(true)
  setError(val.detail)
  setAuth('drivewide',auth_new,{
    maxAge: 3600 ,
    expires:0,
    path:'/'
 })
  

 setEdit(false)

 }
 else {
  setData({
    ...data,processing:false
  })
  setOpen(true)
  setError(val.detail)
 }
}
// console.log(auth.drivewide)

// console.log(data.skills.filter((v, i, a) => console.log(v,i,a)))
const dropDownVal=(val)=>{

      setData({
          ...data,language:val
      })
      setActive1(false)
  
 
   
 

}

const Verify=()=>{

if(open2&&!data.is_verified){

  setData({
    ...data,is_verified:!data.is_verified
  })
  handleClose()
}
else if(data.is_verified){

  setData({
    ...data,is_verified:!data.is_verified
  })
}

else{
  setOpen2(true)
}
}

const imageView=(val)=>{
  setImageView(val)
  setOpen3(true)

}
  

const drivingCheckbox=(cat)=>{
  const item={
    'id':data.licence_category&&data.licence_category.length?(data.licence_category.length+1):1,
    "category":cat.value,
    'value': cat.name

  }

if(data&&data.licence_category&&data.licence_category[0]&&data.licence_category[0].category){

  if(data.licence_category.find(val=>val.category===cat.value)){
    setData({
      ...data,licence_category:data.licence_category.filter(val=>val.category!==cat.value)
    })
      }

      else{
        setData({
          ...data,licence_category:[...data.licence_category,item]
        })
      }
}

 
      else{
        setData({
          ...data,licence_category:[item]
        })

  
      }

}



const Address=(val,val2)=>{
  if(val){

   if(val2==='town'){
     setData({
       ...data,city:val
     })
     setOpenA(true)
 
     setActiveA(val2)
     // setAddress(val)
     if(val.length>3)
  {  
    const autocomplete = new window.google.maps.places.AutocompleteService();
    autocomplete.getPlacePredictions({
      input:val
    }).then((res)=>setSuggestions(res.predictions)).catch((err)=>errorApi(err))  
    }
     
   }
   else{
     setData({
       ...data,zip_code:val
     })
     setOpenA(true)
     setActiveA(val2)
     // setAddress(val)
     if(val.length>3)
     { 
      const autocomplete = new window.google.maps.places.AutocompleteService();
      autocomplete.getPlacePredictions({
        input:val
      }).then((res)=>setSuggestions(res.predictions)).catch((err)=>errorApi(err))  



       }
  }
 
 }
 else{
 
   if(val2==='town'){
     setData({
       ...data,city:''
     })
   }
   else{
     setData({
       ...data,zip_code:''
     })
   }
   setOpenA(false)
   setActiveA(val2)
 
 }
 }
 const Address1=(val)=>{

   setOpenA(false)
   if(activeA==='town'){
     setData({
       ...data,city:val
     })
   }
   else{
     setData({
       ...data,zip_code:val
     })
   }
 
 
 
 }

 const getLatLong=()=>{


 
  const autocomplete1 = new window.google.maps.Geocoder();
  // console.log(autocomplete1)
  autocomplete1.geocode({address:data.city+','+data.zip_code}).then((res)=>latLongRes(res)).catch((err)=>errorApi(err))
  
  // console.log(autocomplete1)
  

    // console.log(map)
}
const latLongRes=(res)=>{
  if(res.results&&res.results[0].geometry)
  {
    // setData({...data,lat:res.results[0].geometry.location.lat(),lng:res.results[0].geometry.location.lng()})
    editFinal(res.results[0].geometry.location.lat(),res.results[0].geometry.location.lng())

  }
  else{
    Alert.alert('Please enter valid Town Name and Postal code')
    setData({
      ...data,processing:false
    })
  }


}
 const errorApi=()=>{
  setData({
    ...data,processing:false
  })
  
 setError('Please enter valid Town Name and Postal code')
setOpen(true)
}

const editFinal=(lat,lng)=>{
  const formdata=new FormData()
  formdata.append('edited_by',auth.drivewide.business_id&&auth.drivewide.is_admin?auth.drivewide.business_id:'')
  formdata.append('driver_id',driver.driver_id)
  formdata.append('skills',JSON.stringify(data.skills))
  formdata.append('licence_category',JSON.stringify(data.licence_category))

  formdata.append('fname',data.fname)
  formdata.append('dob',data.dob)

  formdata.append('lname',data.lname)
  formdata.append('mobile',data.mobile)
  formdata.append('country_code',data.country_code)
  
  formdata.append('addLine1',data.address_line1)
  formdata.append('addLine2',data.address_line2)
  formdata.append('city',data.city)
  formdata.append('state',data.stat)
  formdata.append('postcode',data.zip_code)
  formdata.append('language',data.language)
  formdata.append('utr_number',data.utr_number)
  formdata.append('dl_number',data.dl_number)
  formdata.append('driver_card_number',data.driver_card_number)
  formdata.append('cpc_card_number',data.cpc_card_number)
  formdata.append('passport_number',data.passport_number)
  formdata.append('visa_number',data.visa_number)
  formdata.append('sharecode',data.sharecode)
  formdata.append('experience_years',data.experience_years?data.experience_years:0)
  formdata.append('experience_months',data.experience_months?data.experience_months:0)
  formdata.append('is_verified',data.is_verified)
  formdata.append('citizen',data.citizen)

  formdata.append('dl_number_image',data.dl_number_image)
  formdata.append('dl_number_image_back',data.dl_number_image_back)
  formdata.append('driver_card_image',data.driver_card_image)
  formdata.append('driver_card_image_back',data.driver_card_image_back)
  formdata.append('cpc_card_image',data.cpc_card_image)
  formdata.append('cpc_card_image_back',data.cpc_card_image_back)
  formdata.append('passport_number_image',data.passport_number_image)
  formdata.append('visa_number_image',data.visa_number_image)
  formdata.append('cv_upload',data.cv_upload)
  formdata.append('dl_expiry',data.dl_expiry?data.dl_expiry:'')
  formdata.append('tacho_expiry',data.tacho_expiry?data.tacho_expiry:'')
  formdata.append('cpc_expiry',data.cpc_expiry?data.cpc_expiry:'')
  formdata.append('passport_expiry',data.passport_expiry?data.passport_expiry:'')
  formdata.append('visa_expiry',data.visa_expiry?data.visa_expiry:'')
  formdata.append('profile',data.profile?data.profile:'')
  formdata.append('lat',lat?lat:'')
  formdata.append('lng',lng?lng:'')
  formdata.append('store',store?store:'')



  
  
  
  
  
  
  fetch(live+'driver-major',
  {
    method:'PUT',
    body:formdata
  }).then(resp=>resp.json()).then(res=>editRes(res))
}
const UploadImage=(imageVal,activeImage)=>{
   const formData=new FormData()
formData.append('image',imageVal)
formData.append('driver_id',driver.driver_id)
  fetch(live+'driver-upload',{
    method:'POST',
    body:formData

  })


    if(activeImage==='profile'){
      
      setData({...data,profile:imageVal});

    }
    else if(activeImage==='DL Photo Front'){
      // console.log(result.assets[0])

      setData({...data,dl_number_image:imageVal});

    }
    else if(activeImage==='DL Photo Back'){
      
      setData({...data,dl_number_image_back:imageVal});

    }
    else if(activeImage==='Tacho Card Front'){
      
      setData({...data,driver_card_image:imageVal});

    }
    else  if(activeImage==='Tacho Card Back'){
      
      setData({...data,driver_card_image_back:imageVal});

    }
    else if(activeImage==='CPC Card Front'){
      
      setData({...data,cpc_card_image:imageVal});

    }
    else if(activeImage==='CPC Card Back'){
      
      setData({...data,cpc_card_image_back:imageVal});

    }
    else if(activeImage==='Passport Photo'){
      
      setData({...data,passport_number_image:imageVal});

    }
    else if(activeImage==='Visa Photo'){
      
      setData({...data,visa_number_image:imageVal});

    }
    else{
      setData({...data,cv_upload:imageVal});

    }

}
    return (
    <Container>
      <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>

  <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Verify()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Verifying user will enable driver to apply jobs
</AlertTitle>

      </Alert>
     
     
      </Snackbar>


      <Dialog
        open={open3}
        disableScrollLock={ true }
        onClose={handleClose}
        className="Dialog_CButton"
        disableBackdropClick
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent style={{display:'flex',flexDirection:'column'}}>
          <IconButton
          style={{alignSelf:'end'}}
            aria-label="close"
            onClick={handleClose}
            className="icon-close-btn">
            <CloseIcon />
          </IconButton>
          
          <img style={{height:'100%',width:'100%'}} src={
            imageV==='driving_licence_front'?(data&&data.dl_number_image&&!data.dl_number_image.name?imgLink+data.dl_number_image:edit&&data&&data.dl_number_image&&data.dl_number_image.name?URL.createObjectURL(data.dl_number_image):require('../../images/No_image1.jpg')):
            imageV==='driving_licence_back'?(data&&data.dl_number_image_back&&!data.dl_number_image_back.name?imgLink+data.dl_number_image_back:edit&&data&&data.dl_number_image_back&&data.dl_number_image_back.name?URL.createObjectURL(data.dl_number_image_back):require('../../images/No_image1.jpg')):
            imageV==='taco_front'?(data&&data.driver_card_image&&!data.driver_card_image.name?imgLink+data.driver_card_image:edit&&data&&data.driver_card_image&&data.driver_card_image.name?URL.createObjectURL(data.driver_card_image):require('../../images/No_image1.jpg')):
            imageV==='taco_back'?(data&&data.driver_card_image_back&&!data.driver_card_image_back.name?imgLink+data.driver_card_image_back:edit&&data&&data.driver_card_image_back&&data.driver_card_image_back.name?URL.createObjectURL(data.driver_card_image_back):require('../../images/No_image1.jpg')):
            imageV==='cpc_front'?(data&&data.cpc_card_image&&!data.cpc_card_image.name?imgLink+data.cpc_card_image:edit&&data&&data.cpc_card_image&&data.cpc_card_image.name?URL.createObjectURL(data.cpc_card_image):require('../../images/No_image1.jpg')):
            imageV==='cpc_back'?(data&&data.cpc_card_image_back&&!data.cpc_card_image_back.name?imgLink+data.cpc_card_image_back:edit&&data&&data.cpc_card_image_back&&data.cpc_card_image_back.name?URL.createObjectURL(data.cpc_card_image_back):require('../../images/No_image1.jpg')):
            imageV==='passport'?(data&&data.passport_number_image&&!data.passport_number_image.name?imgLink+data.passport_number_image:edit&&data&&data.passport_number_image&&data.passport_number_image.name?URL.createObjectURL(data.passport_number_image):require('../../images/No_image1.jpg')):
            imageV==='visa'?(data&&data.visa_number_image&&!data.visa_number_image.name?imgLink+data.visa_number_image:edit&&data&&data.visa_number_image&&data.visa_number_image.name?URL.createObjectURL(data.visa_number_image):require('../../images/No_image1.jpg')):
                        imageV==='profile'?(data&&data.profile&&!data.profile.name?imgLink+data.profile:edit&&data&&data.profile&&data.profile.name?URL.createObjectURL(data.profile):require('../../images/No_image1.jpg')):

            data&&data.cv_upload&&!data.cv_upload.name?imgLink+data.cv_upload:edit&&data&&data.cv_upload&&data.cv_upload.name?URL.createObjectURL(data.cv_upload):require('../../images/No_image1.jpg')
            
            
            
            } /> 
                   {/* <AForm/> */}
        </DialogContent>
      </Dialog>

        <Grid container style={{padding:'2vh 0px'}} >
            <Grid item sm={9} xs={9}>

<Typography className='job_post_head'>
Editing {data.fname}'s profile
</Typography>


            </Grid>
            <Grid item sm={3} xs={3}>
            <Button  onClick={()=>props.backPressed1()} style={{marginTop:'15vh'}} className='btn_evd2'>Back</Button>

            </Grid>
            <Grid item sm={12} xs={12}>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography  className='job_post_head_section' >Personal Information ({driver.driver_id})</Typography>

          {/* {data.job_title&&data.job_type&&data.town_name&&data.post_code&&data.company_name&&data.company_description&&data.job_description?<div style={{width:'80%',textAlign:'-webkit-right',paddingRight:'5vw'}}>
          <Typography className='completed' >Completed</Typography>
          </div>:null} */}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container textAlign={'start'} sm={12} xs={12}>
            <Grid item sm={6} xs={12}>
{!edit?<Typography className='txt_info'>
    First Name : {data.fname}
</Typography>:
                <TextField value={data.fname}  label='First Name' placeholder='Enter first name'  className='job_post_textfield' onChange={(e)=>setData({...data,fname:e.target.value})} />}
            </Grid>
      

          
            <Grid item sm={6} xs={12}>

           {edit? <TextField value={data.lname}  label='Last Name' placeholder='Enter last name'  className='job_post_textfield' onChange={(e)=>setData({...data,lname:e.target.value})} />:

            <Typography className='txt_info'>
    Last Name : {data.lname}
</Typography>    }        </Grid>
            <Grid  className='job_post_textfield'  item sm={6} xs={12}>
            {edit? 
            
            <ReactPhoneInput className="PN_Input2"

            
            // style={{color: 'white' }}
            inputProps={{
              name: 'phonenumber',
              required: true
            }}
           buttonStyle={{
              //  backgroundColor:'var(--blueColor)',
            //    color:'black'
           }}
        containerStyle={{          
                  //  backgroundColor:'var(--blueColor)',
      }}
            dropdownStyle={{
              // backgroundColor:'var(--blueColor)',

            }}
            country={'gb'}
            
            value={(data.mobile?data.country_code:'')+data.mobile}  
           
            
            onChange={(code,phonenumber,num,val) =>Phone(phonenumber,val)}  

            // onChange={phonenumber =>setData({...data,...data,phone: phonenumber })}  
          /> 
            :

            <Typography className='txt_info'>
    Phone : {driver.country_code+' '+driver.mobile}
</Typography>    }        </Grid>


<Grid style={{padding:'3vh 0px'}} item sm={6} xs={12}>
               
               <Card sx={{ maxWidth: 325 }}>
     <CardActionArea>
       <CardMedia
         component="img"
         height="140"
         image={data&&data.profile&&!data.profile.name?imgLink+data.profile:edit&&data&&data.profile&&data.profile.name?URL.createObjectURL(data.profile):require('../../images/No_image1.jpg')}
                   alt="green iguana"
       />
       <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
         <Typography gutterBottom  >
           Profile Picture
         </Typography>


      <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
  {edit?    <Tooltip title='Edit/Upload Image'>

      <div>
                 <label htmlFor='file' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                 <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                 {/* ABCD */}
                 </label>

                            

                 </div>
                 </Tooltip>:null}

                 <input type={'file'} id={`file`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'profile')}/><br />
        

                 <Tooltip title='View Image'>
                   <div>
       <i onClick={()=>imageView('profile')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
       </div>

       </Tooltip>
      </div>
       </CardContent>
     </CardActionArea>
   </Card>
               </Grid>
            <Grid item sm={6} xs={12}>
            {edit&&!store? 
            
            <TextField value={data.email}  label='Email' placeholder='Enter email'  className='job_post_textfield' onChange={(e)=>setData({...data,email:e.target.value})} />:

            <Typography className='txt_info'>
    Email : {driver.email}
</Typography>        }    </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.dob}  label='Date Of Birth (DD-MM-YYYY)' placeholder='Enter date of birth'  className='job_post_textfield' onChange={(e)=>setData({...data,dob:e.target.value})} />:

            <Typography className='txt_info'>
    Date Of Birth : {driver.dob}
</Typography>        }    </Grid>
            <Grid item sm={6} xs={12}>
            {edit?
            <>
            <TextField value={data.address_line1}  label='Address Line1' placeholder='Enter addresss line1'  className='job_post_textfield' onChange={(e)=>setData({...data,address_line1:e.target.value})} />
            <TextField value={data.address_line2}  label='Address Line1' placeholder='Enter addresss line1'  className='job_post_textfield' onChange={(e)=>setData({...data,address_line2:e.target.value})} />
            <TextField value={data.city}  label='Town' placeholder='Enter town'  className='job_post_textfield' onChange={(e)=>Address(e.target.value,'town')} />
            {suggestions&&suggestions[0]&&openA&&activeA==='town'?<div  style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:'100%',alignSelf:'start',alignContent:'start',borderRadius:5,maxHeight:400,overflowY:'scroll'}}>
{suggestions.map((suggestion,idx)=>(

  <Grid onClick={()=>Address1(suggestion.structured_formatting.main_text)} key={idx}  className='cont_search' container>

<Grid item  sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='main_txt'>{suggestion.structured_formatting.main_text}</Typography>
  </Grid>
 <Grid item sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='sub_txt'>{suggestion.description}</Typography></Grid>
  
 
  
  </Grid>)
)}  
</div>:null} 
            <TextField value={data.zip_code} onChange={(e)=>Address(e.target.value,'code')}  label='Postcode' placeholder='Enter postcode'  className='job_post_textfield'  />
            {suggestions&&suggestions[0]&&openA&&activeA==='code'?<div  style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:'100%',alignSelf:'start',alignContent:'start',borderRadius:5,maxHeight:400,overflowY:'scroll'}}>
{suggestions.map((suggestion,idx)=>(

  <Grid onClick={()=>Address1(suggestion.structured_formatting.main_text)} key={idx}  className='cont_search' container>

<Grid item  sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='main_txt'>{suggestion.structured_formatting.main_text}</Typography>
  </Grid>
 <Grid item sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='sub_txt'>{suggestion.description}</Typography></Grid>
  
 
  
  </Grid>)
)}  
</div>:null}
            <TextField value={data.stat}  label='County' placeholder='Enter county'  className='job_post_textfield' onChange={(e)=>setData({...data,stat:e.target.value})} />

            </>:
            <Typography className='txt_info'>
    Address :  {(driver.address_line1?(driver.address_line1+', '):'')+(driver.address_line2?(driver.address_line2+', '):'')+(driver.city?(driver.city+', '):'')+(driver.stat?(driver.stat+', '):'')+(driver.zip_code?(driver.zip_code):'')}
</Typography>     }       </Grid>
<Grid item sm={6} xs={12}>
        {edit?<>
            <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography  style={{paddingRight:'20px',fontSize:'17px'}}>
    Language Proficiency
</Typography>
  <Chip

label={data.language?data.language:''}
onClick={()=>setActive1(!active1)}
onDelete={()=>setActive1(!active1)}
deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  {active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Language_P.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}


 
 
</ul>
  </div>

</div>:null}
        
        
        </>:    <Typography className='txt_info'>
    English Proficiency :  {driver.language}
</Typography> }           </Grid>



          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography  className='job_post_head_section' >Driver Information</Typography>

      
        </AccordionSummary>
        <AccordionDetails>
          <Grid container textAlign={'start'} sm={12} xs={12}>
            <Grid item sm={6} xs={12}>

            {edit? <TextField value={data.utr_number}  label='UTR Number' placeholder='Enter utr number'  className='job_post_textfield' onChange={(e)=>setData({...data,utr_number:e.target.value})} />:

<Typography className='txt_info'>
    UTR Number : {data.utr_number}
</Typography>}
            </Grid>
        

          
            <Grid item sm={6} xs={12}>
            {edit? <TextField value={data.dl_number}  label='Driving Licence' placeholder='Enter driving license'  className='job_post_textfield' onChange={(e)=>setData({...data,dl_number:e.target.value})} />:

            <Typography className='txt_info'>
            DL Number : {data.dl_number}
</Typography>         }   </Grid>
 
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.dl_expiry}  label='Driving Licence Expiry (DD-MM-YYYY)' placeholder='Enter driving license expiry'  className='job_post_textfield' onChange={(e)=>setData({...data,dl_expiry:e.target.value})} />:

            <Typography className='txt_info'>
            DL Expiry : {data.dl_expiry}
</Typography>         }   </Grid>
<Grid item sm={6} xs={12}>
</Grid>
            <Grid style={{padding:'3vh 0px'}} item sm={6} xs={12}>
               
                <Card sx={{ maxWidth: 325 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.dl_number_image&&!data.dl_number_image.name?imgLink+data.dl_number_image:edit&&data&&data.dl_number_image&&data.dl_number_image.name?URL.createObjectURL(data.dl_number_image):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            Driving Licence Front Side
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='filed' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`filed`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'DL Photo Front')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('driving_licence_front')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
    </Card>
                </Grid>
    
                   <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
               
               <Grid item sm={12} xs={12}>
               <Card sx={{ maxWidth: 325 }}>
               <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.dl_number_image_back&&!data.dl_number_image_back.name?imgLink+data.dl_number_image_back:edit&&data&&data.dl_number_image_back&&data.dl_number_image_back.name?URL.createObjectURL(data.dl_number_image_back):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            Driving Licence Back Side
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file1' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file1`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'DL Photo Back')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('driving_licence_back')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
   </Card>
               </Grid>
       
                  </Grid>
 
                  <Grid item sm={6} xs={12}>
                  {edit? <TextField value={data.driver_card_number}  label='Tacho Number' placeholder='Enter tacho number'  className='job_post_textfield' onChange={(e)=>setData({...data,driver_card_number:e.target.value})} />:

<Typography className='txt_info'>
    Taco Card Number : {data.driver_card_number} 
</Typography>}
            </Grid>
            <Grid item sm={6} xs={12}>
            {edit? <TextField value={data.tacho_expiry}  label='Tacho Card Expiry (DD-MM-YYYY)' placeholder='Enter tacho card expiry'  className='job_post_textfield' onChange={(e)=>setData({...data,tacho_expiry:e.target.value})} />:

            <Typography className='txt_info'>
            Tacho Card Expiry : {data.tacho_expiry}
</Typography>         }   </Grid>

          
            <Grid item sm={6} xs={12}>
            {edit? <TextField value={data.cpc_card_number}  label='CPC Card' placeholder='Enter cpc card'  className='job_post_textfield' onChange={(e)=>setData({...data,cpc_card_number:e.target.value})} />:

            <Typography className='txt_info'>
            CPC Number : {data.cpc_card_number} 
</Typography>       }     </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.cpc_expiry}  label='CPC Card Expiry (DD-MM-YYYY)' placeholder='Enter cpc card expiry'  className='job_post_textfield' onChange={(e)=>setData({...data,cpc_expiry:e.target.value})} />:

            <Typography className='txt_info'>
            CPC Card Expiry : {data.cpc_expiry}
</Typography>         }   </Grid>




                  <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
              
              <Grid item sm={12} xs={12}>
              <Card sx={{ maxWidth: 325 }}>
              <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.driver_card_image&&!data.driver_card_image.name?imgLink+data.driver_card_image:edit&&data&&data.driver_card_image&&data.driver_card_image.name?URL.createObjectURL(data.driver_card_image):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            Tacho Card Front Side
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file2' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file2`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'Tacho Card Front')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('taco_front')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
  </Card>
              </Grid>
        
                 </Grid>

                  <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
              
              <Grid item sm={12} xs={12}>
              <Card sx={{ maxWidth: 325 }}>
              <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.driver_card_image_back&&!data.driver_card_image_back.name?imgLink+data.driver_card_image_back:edit&&data&&data.driver_card_image_back&&data.driver_card_image_back.name?URL.createObjectURL(data.driver_card_image_back):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            Tacho Card Back Side
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file3' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file3`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'Tacho Card Back')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('taco_back')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
  </Card>
              </Grid>
       
                 </Grid>

                 <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
               
               <Grid item sm={12} xs={12}>
               <Card sx={{ maxWidth: 325 }}>
               <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.cpc_card_image&&!data.cpc_card_image.name?imgLink+data.cpc_card_image:edit&&data&&data.cpc_card_image&&data.cpc_card_image.name?URL.createObjectURL(data.cpc_card_image):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            CPC Card Front Side
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file4' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file4`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'CPC Card Front')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('cpc_front')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
   </Card>
               </Grid>
        
                  </Grid>


                  <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
               
               <Grid item sm={12} xs={12}>
               <Card sx={{ maxWidth: 325 }}>
               <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.cpc_card_image_back&&!data.cpc_card_image_back.name?imgLink+data.cpc_card_image_back:edit&&data&&data.cpc_card_image_back&&data.cpc_card_image_back.name?URL.createObjectURL(data.cpc_card_image_back):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            CPC Card Back Side
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file5' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file5`} style={{visibility:'hidden',width:'0px'}} onChange={(e)=>UploadImage(e.target.files[0],'CPC Card Back')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('cpc_back')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
   </Card>
               </Grid>
               
                  </Grid>

          </Grid>
          <Grid item sm={12} xs={12}>

<Typography style={{fontSize:'17px',padding:'1vh 0px'}}>Licence Category</Typography>
</Grid>
<Grid item sm={12} xs={12}>
<FormGroup row onChange={(e)=>drivingCheckbox(e.target)}>
{Driving_category.map((val1,idx)=>
<FormControlLabel key={idx} control={<Checkbox disabled={!edit} checked={data.licence_category&&data.licence_category[0]&&data.licence_category.find(val=>val.category==val1.name)?true:false} style={{color:'var(--green)'}} />} label={val1.name} value={val1.name} name={val1.value}  />

)      
}    </FormGroup>
</Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography  className='job_post_head_section' >Work Authorization</Typography>

      
        </AccordionSummary>
        <AccordionDetails>
          <Grid container  textAlign={'start'}sm={12} xs={12}>
          
        

          
            <Grid item sm={6} xs={12}>
                          {edit? <TextField value={data.passport_number}  label='Passport Number' placeholder='Enter passport number'  className='job_post_textfield' onChange={(e)=>setData({...data,passport_number:e.target.value})} />:

            <Typography className='txt_info'>
            Passport Number : {data.passport_number}
</Typography>      }      </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.passport_expiry}  label='Passport Expiry (DD-MM-YYYY)' placeholder='Enter passport expiry'  className='job_post_textfield' onChange={(e)=>setData({...data,passport_expiry:e.target.value})} />:

            <Typography className='txt_info'>
            Passport Expiry : {data.passport_expiry}
</Typography>         }   </Grid>
            <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
               
                <Grid item sm={12} xs={12}>
                <Card sx={{ maxWidth: 325 }}>
                <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.passport_number_image&&!data.passport_number_image.name?imgLink+data.passport_number_image:edit&&data&&data.passport_number_image&&data.passport_number_image.name?URL.createObjectURL(data.passport_number_image):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography  gutterBottom  >
            Passport Photo
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file6' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file6`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'Passport Photo')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('passport')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
    </Card>
                </Grid>
              
                   </Grid>



                   <Grid item sm={6} xs={12}>

{edit? <TextField value={data.sharecode}  label='Sharecode' placeholder='Enter sharecode'  className='job_post_textfield' onChange={(e)=>setData({...data,sharecode:e.target.value})} />:

<Typography className='txt_info'>
Sharecode : {data.sharecode}
</Typography>     }       </Grid>              
                   <Grid item style={{display:'flex'}} sm={12} xs={12}>
<Typography className='txt_info'>
   Are you a UK/EU citizen? 
</Typography>
<FormGroup style={{alignContent:'center',paddingLeft:'5vw'}} >
      <FormControlLabel  checked={data.citizen} disabled={!edit}  control={<Switch   onChange={(e)=>setData({...data,citizen:e.target.checked})}  style={{color:'var(--green)'}} />} style={{fontSize:17}}  />
      
    </FormGroup>

            </Grid>

                {data.citizen?null:<> 
                  <Grid item sm={6} xs={12}>
                      {edit? <TextField value={data.visa_number}  label='Visa Number' placeholder='Enter visa number'  className='job_post_textfield' onChange={(e)=>setData({...data,visa_number:e.target.value})} />:

            <Typography className='txt_info'>
            Visa Number : {data.visa_number}
</Typography>   }         </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.visa_expiry}  label='Visa Expiry (DD-MM-YYYY)' placeholder='Enter visa expiry'  className='job_post_textfield' onChange={(e)=>setData({...data,visa_expiry:e.target.value})} />:

            <Typography className='txt_info'>
            Visa Expiry : {data.visa_expiry}
</Typography>         }   </Grid>
                   <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
               
               <Grid item sm={12} xs={12}>
               <Card sx={{ maxWidth: 325 }}>
               <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.visa_number_image&&!data.visa_number_image.name?imgLink+data.visa_number_image:edit&&data&&data.visa_number_image&&data.visa_number_image.name?URL.createObjectURL(data.visa_number_image):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            Visa Photo
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file7' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'} id={`file7`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'Visa Photo')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('visa')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
   </Card>
               </Grid>
       
                  </Grid></>}
 
   


          </Grid>
        </AccordionDetails>
        
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography  className='job_post_head_section' >Work Experience</Typography>

      
        </AccordionSummary>
        <AccordionDetails>
          <Grid container textAlign={'start'} sm={12} xs={12}>
          <Grid container sm={6} xs={12}>
          {edit?
          <>     
          
               <Grid item sm={8} xs={9}>
          <TextField   onChange={(e)=>setData({...data,skill:e.target.value})}  label='Skills' placeholder='Enter skill required' className='job_post_textfield' />

          </Grid>
          <Grid item style={{alignSelf:'center'}} sm={4} xs={3}>
<Chip label={'Add Skill'} className='job_post_chipp_add' onClick={()=>addSkills()} />

          </Grid>
          <Grid item sm={12} xs={12}>
{data.skills?data.skills.map((val,idx)=>
<Chip style={{margin:'1vh 16px 1vh 0px'}} key={idx} label={val.skill} className='job_post_chipp_add' onDelete={()=>setData({...data,skills:data.skills.filter(x=>x.skill!==val.skill)})}
deleteIcon={<HighlightOffIcon style={{color:'var(--blueColor)'}}/>}  />)
:null}
      </Grid>
      </>

          
          :  <Typography className='txt_info'>
            Skills : {data.skills?data.skills.map((val,idx)=>
<Chip style={{margin:'1vh 16px 1vh 0px'}} key={idx} label={val.skill} className='job_post_chipp_add' 
  />)
:null}
</Typography>  }          </Grid>
<Grid item sm={6} xs={12}>
        {edit?  
        
        <Grid container>
          <Grid item sm={6} xs={6}>
          <TextField  value={data.experience_years} onChange={(e)=>setData({...data,experience_years:e.target.value})}  label='Experience(In Years)' placeholder='Enter year' type={'number'} className='job_post_textfield' />

            </Grid>
            <Grid item sm={6} xs={6}>
            <TextField  value={data.experience_months} onChange={(e)=>setData({...data,experience_months:e.target.value})}  label='Experience(In Months)' placeholder='Enter months' type={'number'} className='job_post_textfield' />

            </Grid>
          </Grid>:
        <Typography className='txt_info'>
            Experience : {(data.experience_years>0?(data.experience_years+' Years'):'')+(data.experience_months>0?(data.experience_months+' Months'):'')}
</Typography> }           </Grid>
        


            <Grid style={{padding:'3vh 0px'}} container sm={6} xs={12}>
               
                <Grid item sm={12} xs={12}>
                <Card sx={{ maxWidth: 325 }}>
                <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data&&data.cv_upload&&!data.cv_upload.name?imgLink+data.cv_upload:edit&&data&&data.cv_upload&&data.cv_upload.name?URL.createObjectURL(data.cv_upload):require('../../images/No_image1.jpg')}
                    alt="green iguana"
        />
        <CardContent style={{maxHeight:'50px',display:'flex',alignItems:'center'}}>
          <Typography gutterBottom  >
            CV Upload
          </Typography>


       <div style={{paddingLeft:'3vw',alignItems:'center',display:'flex'}}>
   {edit?    <Tooltip title='Edit/Upload Image'>

       <div>
                  <label htmlFor='file8' style={{fontSize:'small',fontWeight:'bold' ,width:'100%',cursor:'pointer',alignSelf:'center'}} >
                  <i   class="fas fa-cloud-upload-alt icon_img_dash"></i>
                  {/* ABCD */}
                  </label>

                             

                  </div>
                  </Tooltip>:null}

                  <input type={'file'}  id={`file8`} style={{visibility:'hidden',width:'0px'}}  onChange={(e)=>UploadImage(e.target.files[0],'DL Photo Front')}/><br />
         

                  <Tooltip title='View Image'>
                    <div>
        <i onClick={()=>imageView('cv')} class="fas fa-eye icon_img_dash" style={{paddingLeft:'1vw'}}></i>
        </div>

        </Tooltip>
       </div>
        </CardContent>
      </CardActionArea>
    </Card>
                </Grid>
 </Grid>


          </Grid>
        </AccordionDetails>
        
      </Accordion>


      <Accordion expanded={false}>
        <AccordionSummary
        
        // style={{background:'var(--green)'}}
    
        //   expandIcon={<FormGroup style={{alignContent:'center',paddingLeft:'5vw'}} >
        //   <FormControlLabel  checked={data.is_verified}  control={<Switch disabled={!edit}  onChange={(e)=>setData({...data,is_verified:e.target.checked})}  style={{color:'white'}} />} style={{fontSize:17}}  />
          
        // </FormGroup>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography          style={{color:'var(--green)'}}
 className='job_post_head_section' >Verified </Typography>

          <FormGroup  style={{alignContent:'end',paddingLeft:'5vw',width:'100%'}} >
          <FormControlLabel  checked={data.is_verified}  control={<Switch disabled={!edit||store}  onChange={(e)=>Verify()}  style={{color:'white'}} />} style={{fontSize:17}}  />
          
        </FormGroup>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
      



          </Grid>
        </AccordionDetails>
        
      </Accordion>
            </Grid>
            <Grid item style={{textAlign:'center',padding:'2vh 0px'}} sm={12} xs={12}>
                <Chip 
                // disabled={data.processing} 
                 onClick={()=>submitJob()} className='chip_edit' label={data.processing?'Editing':edit&&!data.processing?'Save':'Edit Driver'}/>
                {edit?<Chip 
                // disabled={data.processing} 
                 onClick={()=>Cancel()} style={{marginLeft:'2vw'}} className='chip_cancel' label={'Cancel'}/>
        :null}
            </Grid>

        </Grid>
    </Container>
  )
}
