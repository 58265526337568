import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import './below_bg.css'
export default function Below_bg() {
  return (

    <div>
    <Container style={{paddingBottom:'5vh'}}>

        <Grid container>
            <Grid container style={{textAlign:'-webkit-center',padding:5}} sm={4} xs={12}>
<Grid item sm={12} xs={12}>
    <img src={require('../../../images/card.png')} className='below_home_icon' />
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_head'>
    Secure Payments
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_text'>
   {/* Concern about security? No problem. Using our product you’ve got it covered. */}
   Concerned about security? No problem. Using our product we've got you covered.
    </Typography>
</Grid>
            </Grid>
            <Grid container style={{textAlign:'-webkit-center',padding:5}} sm={4} xs={12}>
<Grid item sm={12} xs={12}>
    <img src={require('../../../images/alert.png')} className='below_home_icon' />
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_head'>
    Clever alerts
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_text'>
    No more clock-watching – get a discrete nudge when it’s time to go.    
    </Typography>
</Grid>
            </Grid>
            <Grid container style={{textAlign:'-webkit-center',padding:5}} sm={4} xs={12}>
<Grid item sm={12} xs={12}>
    <img src={require('../../../images/clock.png')} className='below_home_icon' />
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_head'>
    Record Your Work
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_text'>
   {/* Get proper sync with your worked hours or business */}
   Get a proper log with your worked hours for a business 
    </Typography>
</Grid>
            </Grid>
        </Grid>
        </Container>


<div className='below_home_map'>
<Container style={{height:'100%'}}>
<Grid style={{height:'100%',alignContent:'center'}} container>

<Grid item sm={5} xs={12}>
<img   src={require('../../../images/Post.png')} className='below_home_map_phone' />
</Grid>
<Grid container style={{alignContent:'center'}} sm={7} xs={12}>
<Grid item sm={12} xs={12}>
<Typography className='below_home_map_head'>
{/* We Make Driving Effortless. */}
Finding a driver has never been easier.

</Typography>

</Grid>
<Grid item sm={12} xs={12}>
<Typography className='below_home_map_text'>
{/* Our driver's business solutions serve over  million users, across various businesses and jobs.  */}
Get verified drivers with the right skills in your area or anywhere across the UK.

</Typography>

</Grid>
<Grid container sm={12} xs={12}>
<Grid item >
<img src={require('../../../images/app-store.png')} className='home_bg_app' />

</Grid>
<Grid item style={{paddingLeft:'2vw'}} >
<img src={require('../../../images/play-store.png')} className='home_bg_app' onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.drivewide.drivewide")}/>

</Grid>

</Grid>
</Grid>

    </Grid>
</Container>
    

</div>

        </div>
  )
}
