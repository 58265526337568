import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography ,Grid,Paper, Dialog, Button, Snackbar, Alert, AlertTitle} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useCookies} from 'react-cookie'

import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import './dashboard.css'
import Jobs from './jobs';
import Profile from './profile';
import Jobs_history from './jobs_history';
import Payments from './payments';
import { useEffect } from 'react';
import { live } from '../../App';
import Request_overtime from './request_overtime';



export default function Dashboard_Driver(props) {

  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
const[openA,setOpenA]=React.useState(auth&&auth.drivewide&&auth.drivewide.is_active?false:true)
  const [history,setHistory]=React.useState(false)
  const [driver,setDriver]=React.useState('')
  const[open,setOpen]=React.useState(false)

const [submitted,setSubmitted]=React.useState(false)
const [processing,setProcessing]=React.useState(false)


  useEffect(() => {
    if(auth.drivewide.driver_id){
    //  setProcessing(true)
     fetch(live+`driver-major?q=${auth.drivewide.driver_id}`).then((res)=>res.json()).then(resp=>setDriver(resp.data))
    }
   }, [auth])
  const [state, setState] = React.useState({
 
    left: false,
   
  });
  const [screen,setScreen]=React.useState('Dashboard')
  const [anchor]=React.useState('left')

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <Divider />
      


      <List>
        {((["Profile","Jobs History","Credits"])).map((text, index) => (
          <ListItem style={{width:'100%'}} key={index} onClick={()=>setScreen(text==='Credits'?'Account Info':text)} disablePadding>
            <ListItemButton>
             
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> 
        ))}
      </List>
    </Box>
  );


  const signOut=()=>{
  
deleteAuth('drivewide')
// window.location.href='/'
  }
  const backPressed1=()=>{
    setScreen('')
  }
  const handleClose=()=>{
    setOpen(false)
  }


  const handleSubmit=()=>{
    if(auth&&auth.drivewide.credits>0)
    {setProcessing(true)
      const formData=new FormData
      formData.append('email',auth&&auth.drivewide.email)
      formData.append('driver_id',auth&&auth.drivewide.driver_id)
      formData.append('credits',auth&&auth.drivewide.credits)
  
  
  
      fetch(live+'contact'
      // fetch('http://127.0.0.1:8000/mail-TBI'
      ,
      {
      
        method:'PUT',
      
        body:formData
        })
      
        .then(res=>res.json())
        .then((resp)=>MailRes(resp))
      
      
        .catch(error=>console.log(error))}
        else{
          alert('No available credits to redeem')
        }
    
    }
  
  
      const MailRes=(val)=>{
        if(val.status){
        setProcessing(false)
  setSubmitted(true)
  
  setSubmitted(()=>setTimeout(() => Submitted(false), 10000))   
  }
  else{
    alert('Unable to send email, please try again later')
    setProcessing(false)
  }}

  const Submitted=()=>{
    setSubmitted(false)
    setOpen(false)
  }
if(!auth['drivewide'])(
  window.location.href='/signin'

)
else{


  return (
    <div>
        <Snackbar open={open}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert  style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
         {processing||submitted?null:<Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>}
            <Button onClick={()=>handleSubmit()}  className='btn_yes' size="small">
            {submitted&&!processing?'REQUEST SENT':processing?'REQUESTING':'REDEEM CREDITS'}
          </Button>
          </div>} onClose={handleClose} severity="info">
        <AlertTitle>{processing||submitted?'':'Send' } Redeem {'request' }
</AlertTitle>

      </Alert>
     
     
      </Snackbar>
      <Dialog
    fullScreen
    open={openA}
    onClose={()=>setOpenA(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
 
    <Grid item sm={12} xs={12}>
      
      <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'5vh'}}>{'Your account has been freezed '}<br/>Please contact our <a style={{fontSize:'2.2rem',fontWeight:'700',color:'var(--green)',textDecoration:'none'}}href="mailto:customerservice@drivewide.co.uk">support team </a>to continue</Typography>
    </Grid>
      <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>signOut()} className='pricing_plan_btn'>
      signout
      </Button>

      </Grid>

    </Grid>
   
  </Dialog>
 <React.Fragment >
          <CssBaseline />
      <AppBar
        position="fixed" 
        className="appbarMain"
       style={{top:120}}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
              <div >
            <IconButton
             onClick={()=>setState({...state,left:!state.left})}
                color="inherit"
                aria-label="open drawer"
                
                edge="start"
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{alignSelf:'center'}} >
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              <div style={{display:'flex',justifyContent:'end',width:'100%'}}>
              {/* <div onClick={()=>window.location.href='/'} style={{paddingRight:'20px',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                HOME
              </div> */}
              <div onClick={()=>signOut()} style={{paddingRight:'20px',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                SIGN OUT
              </div>
              </div>
              </div>
        </Toolbar>
      </AppBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}

          >

<Grid container  >
            <Grid style={{textAlign:'center'}} item xs='12'>
            <img src={require('../../images/DRIVEWIDE.svg').default} style={{maxWidth:'100%',height:80}} />
              </Grid>
              <Grid  style={{textAlign:'center'}} item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto',fontSize:'16px',paddingLeft:18,textAlign:'initial'}}>Hey <b style={{color:'var(--green)'}}>{auth['drivewide'].fname}</b></h1>
              </Grid>

            
          <Grid item style={{alignSelf:'center',textAlign:'center'}} xs='4'>
          <IconButton               onClick={()=>setState({...state,left:!state.left})}
>
            {state.left ? <ChevronLeftIcon style={{fontSize: 40}} /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
                 <Grid> 
            {list(anchor)}
            </Grid> 
          </Drawer>
          <main
        style={{minheight:'50vh'}}
      >


        
    <div style={{height:'100%',minHeight:'90vh'}}>
      
    { <Grid className='grid_contPPr' container>
    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Profile')}  className={screen==='Profile'?'dash_subA':'dash_sub'}>
        Profile
        </Paper>
        </Grid>

    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Jobs History')}  className={screen==='Jobs History'?'dash_subA':'dash_sub'}>
        Jobs History
        </Paper>
        </Grid> 
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Request Overtime')}  className={screen==='Request Overtime'?'dash_subA':'dash_sub'}>
        Request Overtime
        </Paper>
        </Grid> 
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Account Info')}  className={screen==='Account Info'?'dash_subA':'dash_sub'}>
        Credits
        </Paper>
        </Grid>
       
      

   
  
        </Grid>  }

        {screen==='Profile'?<Profile  backPressed1={backPressed1}/>:screen==='Request Overtime'?<Request_overtime />:screen==='Jobs History'?<Jobs_history /> :screen==='Account Info'?
        <Grid style={{padding:'8vh 0px',maxWidth:'1200px',justifyContent:'center',textAlign:'-webkit-center'}} container>
    <Grid item   sm={4} xs={6}>
    <Paper elevation={6}  className={'dash_subA'}>
        Credits Available <br /> 
        £{auth.drivewide.credits?auth.drivewide.credits:0}
        </Paper>
        </Grid>
        <Grid item  sm={4} xs={6}>
    <Paper elevation={6}onClick={()=>setOpen(!open)}  className={'dash_subA'}>
        Redeem Credits <br /> 
        </Paper>
        </Grid>
        <Grid item  sm={4} xs={6}>
    <Paper elevation={6} onClick={()=>setHistory(!history)}  className={'dash_subA'}>
        Credits History <br /> 
        </Paper>
        </Grid>
     
 

   
  
        </Grid> :null}
        {screen==='Account Info'&&history&&auth.drivewide?
        // <Grid container style={{width:'100%'}}>
        <Payments  transactions={driver.credits_history}/>
        // </Grid>
        :null}

    </div>
   
   

      </main>
        </React.Fragment>
     
      
      
    </div>
  );}
}
