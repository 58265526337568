import { Button, Container, Grid,TextField, Paper, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import './signin.css';
import ReactPhoneInput from "react-phone-input-2";
import { sha256 } from 'js-sha256';

 import 'react-phone-input-2/lib/style.css' 

 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
 import Snackbar from '@mui/material/Snackbar';
 import MuiAlert from '@mui/material/Alert';
import {useCookies} from 'react-cookie'
import { useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { live } from "../../App";

import './Form.css'
 export default function SignIN(){
  const ref1=React.useRef()
  const ref2=React.useRef()
  const ref3=React.useRef()

  var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;


  useEffect(() => {
    fetch('https://ipapi.co/json/').then((resp)=>resp.json()).then((res)=>setCountry(res))
  }, [])
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [country,setCountry]=useState('')

    const [data,setData]=useState({
      category:'Driver',
      criminal_record:'No'
    })

    const [active,setActive]=useState('signIn')
    const [activeOtp,setActiveOtp]=useState('')
    const [errMsg,setMsg]=useState('')

    const [login,setLogin]=useState('')
    const [otp1,setOTP1]=useState('')
    const [otp2,setOTP2]=useState('')
    const [processing,setProcessing]=useState(false)
    const [submitted2,setSubmitted2]=useState(true)
    const [verify1,setVerify1]=useState(false)
    const [verify2,setVerify2]=useState(false)



const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [user,setUser]=useState('')
const[auth,setAuth]=useCookies(['drivewide'])


useEffect(() => {
  if(auth["drivewide"])(
    window.location.href='/dashboard'
  )
  
 
}, [])
const Phone=(val,val1)=>{
  setData({...data,code:'+'+val.dialCode,mobile:val1.replace('+'+val.dialCode+' ','')})
  }
const handleClose=()=>{
  setOpen(false)
  setOpen1(false)

}
    const signINClicked=()=>{
      if(login&&login.length>4&&login.includes('@')&&data.password){
        setProcessing(true)
        const formData=new FormData
  formData.append('login',login)

  formData.append('password',sha256(data.password))

  fetch(live+(data.category==='Driver'?'driver-auth':'business-auth'),
  {
    method:'POST',
    body:formData
  }
  ).then((res)=>res.json())
  .then(res=>signInResp(res))
  .catch((err)=>alert('Feature disabled'))
       
      }
    
     


    
    else{
      setMsg('Enter valid credentials !')

      setOpen(true)
    }
    }





    const signInResp=(resp,val)=>{
      if(resp.status){
        // setUser(resp.data)
        // setProcessing(false)
        // console.log(resp.data)
        setAuth('drivewide',resp.data,{
          maxAge: 3600 ,
          expires:0,
          path:'/'
       })
       

          setSubmitted2(true)
          
    
          window.location.href='/dashboard'
        // setActiveOtp('signIn')

      }
      else{
        setMsg(resp.detail)
        setProcessing(false)
      
        setOpen(true)
      }
    }
    const ref=React.useRef()
const signUpClicked=()=>{

if(data.mobile){
  if(data.password===data.password2){
    if(regularExpression.test(data.password)){
      setProcessing(true)
      const formData=new FormData
      formData.append('fname',data.fname)
      formData.append('lname',data.lname)
    
      formData.append('email',data.email)
      formData.append('password',sha256(data.password))
      formData.append('mobile',data.mobile.includes('-')?data.mobile.replace('-',''):data.mobile)
      formData.append('code',data.code)
      formData.append('criminal_record',data.criminal_record)
      formData.append('criminal_record_text',data.criminal_record_text)
    
    
      formData.append('country',country.country_name)
    
    
    
    
      fetch(live+(data.category==='Driver'?'driver':'business'),
    {
      method:'POST',
      body:formData
    }
      ).then((res)=>res.json())
      .then((resp)=>signupResp(resp))
      .catch((err)=>alert('Feature disabled'))
    
    }
    else{
      setMsg('Password should contain minimum 8 characters with atleast a number, a capital letter, a small letter and a special character !@#$%^&*')
      setOpen(true)
    }

   
  }
  else{
    setMsg('Password and Confirm Password should be same')
    setOpen(true)
  }

}
else{
  setMsg('Enter valid credentials !')
  setOpen(true)
}
}
const signupResp=(resp)=>{
  if(resp.status){
    setUser(resp.data)

    setProcessing(false)
  setActiveOtp('signUp')
}else{
  setMsg(resp.detail)
  setProcessing(false)

  setOpen(true)
}

}


const verifyClicked=(val)=>{
    setVerify2(true)

  const formData=new FormData
  formData.append('email',data.email)
  formData.append('otp',otp2)

fetch(live+'verify',
{
  method:'POST',
  body:formData
}
).then((resp)=>resp.json())
.then((res)=>verifyRes(res,val))
.catch((err)=>alert('Feature disabled'))


}
const verifyClicked1=()=>{
  setVerify1(true)
  const formData=new FormData
  formData.append('email',login)
  formData.append('otp',otp1)

fetch(live+'verify',
{
  method:'POST',
  body:formData
}
).then((resp)=>resp.json())
.then((res)=>verifyRes1(res))
.catch((err)=>console.log(err))


}

const verifyRes1=(val)=>{
if(active==='signIn'||active==='SignUp'){
  if(val.status){
    setAuth('drivewide',user,{
      maxAge: 3600 ,
      expires:0,
      path:'/'
   })
      setSubmitted2(true)
      

      window.location.href='/dashboard'
    


  }
  else{
    setOTP1('')
    setMsg(val.detail)
    setVerify1(false)

    setOpen(true)
  }
}
else{
  if(val.status){
    setData({
      ...data,forgot:true
    })
    setVerify1(false)
  }
  else{
    setMsg(val.detail)
    setVerify1(false)

    setOpen(true)
  }
}



}

const verifyRes=(val)=>{
  if(val.status){
  
      setSubmitted2(true)
  

  }
  else{
    setMsg(val.detail)
  setVerify2(false)
    setOpen(true)
  }
}


const proceedClicked=()=>{
  setProcessing(true)
  const formData=new FormData
  formData.append('email',data.email)
  fetch(live+(data.category==='Driver'?'driver':'business'),
  {
    method:'PUT',
    body:formData
  }
  ).then((res)=>res.json())
  .then(resp=>proceedRes(resp))
}
const proceedRes=(res)=>{
  if(res.status){
    setAuth('drivewide',res.data,{
      maxAge: 3600 ,
      expires:0,
      path:'/'
   })
    window.location.href='/dashboard'
  }
  else{
    setMsg(res.detail)
  setProcessing(false)
    setOpen(true)
  }
}

const Active=(val)=>{

  setOTP1('')
  setOTP2('')
  setProcessing(false)
  setSubmitted2(false)
  setActiveOtp('')
  setActive(val)


}

const resendOtp1=()=>{
  if(login.includes('@')){
    const formData=new FormData
    formData.append('val',login)
    formData.append('otpType','email')
    fetch(live+'verify',
    {
      method:'PUT',
      body:formData
    }
    ).then((res)=>res.json())
    .then((resp)=>resendResp(resp))
    
  }
  else{
    {
      const formData=new FormData
      formData.append('val',login)
      formData.append('otpType','phone')
      fetch(live+'verify',
      {
        method:'PUT',
        body:formData
      }
      ).then((res)=>res.json())
      .then((resp)=>resendResp(resp))
      
    }
  }

}


const resendOtp=(val)=>{
  if(val==='email'){
    const formData=new FormData
    formData.append('val',data.email)
    formData.append('otpType',val)
    fetch(live+'verify',
    {
      method:'PUT',
      body:formData
    }
    ).then((res)=>res.json())
    .then((resp)=>resendResp(resp))
    
  }


}

const resendResp=(resp)=>{
  if(resp.status){
    setOpen1(true)
    setMsg(resp.detail)

  }
  else{
    setOpen(true)
    setMsg(resp.detail)
  }

}

const Active_forgot=(val)=>{
  if(login){
    setProcessing(true)

    const formData=new FormData
      formData.append('email',login)
      fetch(live+(data.category==='Driver'?'forgot-driver':'forgot-business'),
      {
        method:'POST',
        body:formData
      }
      ).then((res)=>res.json())
      .then((resp)=>forgotResp_OTP(resp))
  }
  else{
    setOpen(true)
    setMsg('Please enter email to reset password')
  }
}


const forgotResp_OTP=(resp)=>{
if(resp.status){
  setActive('forgot')
  setProcessing(false)
  setData({
    ...data,password:'',password1:''
  })

}
else{
  setOpen(true)
  setMsg(resp.detail)
  setProcessing(false)
}
}
const submitForgot=()=>{
  if(data.password&&data.password1)
  {
if(data.password===data.password1){
  if(regularExpression.test(data.password)){

  
  setProcessing(true)

  const formData=new FormData
  formData.append('email',login)

    formData.append('password',sha256(data.password))

    fetch(live+(data.category==='Driver'?'forgot-driver':'forgot-business'),
    {
      method:'PUT',
      body:formData
    }
    ).then((res)=>res.json())
    .then((resp)=>res_submitForgot(resp))
  }
  else{
    setMsg('Password should contain minimum 8 characters with atleast a number, a capital letter, a small letter and a special character !@#$%^&*')
    setOpen(true)
  }
}
else{
  setOpen(true)
  setMsg("Password and Confirm Password are not same")
  setProcessing(false)
}



  }
  else{
    setOpen(true)
    setMsg("Password and Confirm Password are required")
    setProcessing(false)
  }

}

const res_submitForgot=(val)=>{
  if(val.status){
    setData({password:''})
    setOTP1('')
        setOTP2('')
        setLogin('')

setActive('signIn')
setProcessing(false)
  }
  else{
    setMsg(val.detail)
    setOpen(true)
    setProcessing(false)
  }

}
// if(auth['drivewide'])(
//   window.location.href='/dashboard'

// )
// else{
  return(
    <div className="signIN_bg">
<Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>
  {active==='signIn'||active==='signUp'?
  <div>
       { active==='signIn'&&!activeOtp?
       <Container  style={{paddingBottom:30}}>
            <Grid item sm={12} xs={12}>
                <Typography className="signIN_head"><span style={{color:'var(--green)'}}>{data.category?data.category.toUpperCase():'DRIVER'}</span> SIGN IN</Typography>
            </Grid>

           <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
        <Paper  elevation={6} className="signIN_ppr">
        <ValidatorForm
                ref={ref1}
                onSubmit={signINClicked}
            >
            <Grid sm={12} xs={12}>
            <Typography className="phone_head">
                Sign in to DriveWide to continue
            </Typography>
            </Grid>
            <Grid item sm={12}  xs={12}>
          <TextField value={login} onChange={(e)=>setLogin(e.target.value)} style={{width:'90%'}} label={'Email'} placeholder="Enter email"  name="email" type={'email'}/>
            </Grid>
            <Grid  item style={{paddingTop:'2vh'}} sm={12}  xs={12}>
          <TextField value={data.password} onChange={(e)=>setData({...data,password:e.target.value})} style={{width:'90%'}} label={'Password'} placeholder="Enter password"  type={'password'}/>
            </Grid>
            <Grid  item style={{padding:'1vh 0px'}} sm={12}  xs={12}>
              <Typography onClick={()=>Active_forgot('forgot')} style={{fontSize:16,fontWeight:'700',color:'var(--green)',cursor:'pointer'}}>
                Forgot Password?
              </Typography>
            </Grid>
            <Grid  style={{textAlign:'left',paddingTop:'1vh',width:'90%'}}item sm={12} xs={12}>
            <FormControl>
      <RadioGroup

      onChange={(e)=>setData({
        ...data,category:e.target.value
      })}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        value={data.category?data.category:'Driver'}
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Driver" control={<Radio style={{color:'var(--green)'}} />} label="Driver" />
        <FormControlLabel value="Business" control={<Radio style={{color:'var(--green)'}} />} label="Business" />
    
      </RadioGroup>
    </FormControl>
            </Grid>
<Grid item sm={12} xs={12}>
<Button type="submit"  className="btn_signin">
    {!processing?'SIGN IN':'Processing'}
</Button>
</Grid>
<Grid item sm={12} xs={12}>
<Typography onClick={()=>Active('signUp')} className="new_customer">
New to DriveWide ?
<span style={{color:'var(--green)',cursor:'pointer'}} >
<a className="link_register">Create an account</a></span>
</Typography>
</Grid>
        </ValidatorForm>
        </Paper>
        </Grid>
        </Container>:
         active==='signIn'&&activeOtp==='signIn'?
         <Container  style={{paddingBottom:30}}>
              <Grid item sm={12} xs={12}>
                  <Typography className="signIN_head"><span style={{color:'var(--green)'}}>CUSTOMER</span> SIGN IN</Typography>
              </Grid>

             <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
          <Paper elevation={6} className="signIN_ppr">
              
          <ValidatorForm
                ref={ref2}
                onSubmit={verifyClicked1}
            >
              <Grid sm={12} xs={12}>
              <Typography className="phone_head">
                  Enter Your OTP
              </Typography>
              </Grid>
              <Grid sm={12}  xs={12}>
            <TextField  value={otp1} onChange={(e)=>setOTP1(e.target.value)} style={{width:'90%'}} label={'OTP'} placeholder={`Enter otp sent on your email`}/>
              </Grid>
<Grid item sm={12} xs={12}>
  <Button type="submit" className="btn_signin">
    {verify1?'Verifying':'Verify'}
  </Button>
</Grid>
<Grid item sm={12} xs={12}>
  <Typography onClick={()=>resendOtp1()} className="new_customer">
  Resend <span style={{cursor:'pointer',color:'var(--green)'}}>OTP</span>

  </Typography>
  <Typography style={{fontSize:'13px'}} >
 Sometimes OTP may appear in spam folder

  </Typography>
</Grid>
</ValidatorForm>
          </Paper>
          </Grid>
          </Container>

      : 
      
      
      active==='signUp'&&activeOtp==='signUp'?
      <Container  style={{paddingBottom:30}}>
           <Grid item sm={12} xs={12}>
               <Typography className="signIN_head"><span style={{color:'var(--green)'}}>CUSTOMER</span> SIGN UP</Typography>
           </Grid>

          <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
       <Paper elevation={6} className="signIN_ppr">
       <ValidatorForm
                ref={ref3}
                onSubmit={proceedClicked}
            >



{   !submitted2? <>    
<Grid sm={12} xs={12}>
           <Typography className="phone_head">
               Enter Your Email OTP
           </Typography>
           </Grid>
           <Grid sm={12}  xs={12}>
         <TextField value={otp2} onChange={(e)=>setOTP2(e.target.value)} style={{width:'90%'}} label={'OTP'} placeholder={`Enter otp sent on email`}/>
           </Grid>
<Grid item sm={12} xs={12}>
<Button onClick={()=>verifyClicked('email')} className="btn_signin">
{verify2?'Verifying':'Verify'}
  </Button>
</Grid>
<Grid item sm={12} xs={12}>
<Typography onClick={()=>resendOtp('email')} className="new_customer">
Resend <span style={{cursor:'pointer',color:'var(--green)'}}>Email OTP</span>

</Typography>
<Typography style={{fontSize:'13px'}} >
 Sometimes OTP may appear in spam folder

  </Typography>
</Grid>
</>:
<Grid item sm={12} xs={12}>
<Typography className="phone_head">
Email Verified<span><i style={{color:'green',paddingLeft:5}} class="fas fa-check-circle"></i></span>
</Typography>
</Grid> }
{submitted2?<Grid item sm={12} xs={12} style={{paddingBottom:20}}>
<Button type="submit" className="btn_signin">
{!processing?'Proceed':'Processing'}
</Button>
</Grid>:null}
</ValidatorForm>
       </Paper>
       
       </Grid>
       </Container>

   : 
      <Container style={{textAlign:'-webkit-center',paddingBottom:'20px'}}>
            <Grid item sm={12} xs={12}>
            <Typography style={{paddingTop:'15vh'}} className="signIN_head"><span style={{color:'var(--green)'}}>{data.category?data.category.toUpperCase():'DRIVER'}</span> SIGN UP</Typography>

            </Grid>
            <Paper className="ppr2">

            <Container className="A_Form1_Container">
  <Paper elevation={0} className="A_Form1_Cont_Paper">
      <div className="A_Form1_Div">

      <div className="Floating_Form">
        <ValidatorForm onSubmit={signUpClicked}
            ref={ref}
        >
          
       
          <div className="TF_div">
            <label className="TF_Label">First Name*</label>
            <TextValidator
                label=""
                onChange={(e)=>setData({...data,
                  fname:e.target.value
              })}
              name="name"
              value={data.fname}
                validators={['required', 'matchRegexp:[A-Za-z]+']}
                errorMessages={['First name required', 'Invalid name']}  
            />  
        </div>
        <div className="TF_div">
            <label className="TF_Label">Last Name*</label>
            <TextValidator
                label=""
                onChange={(e)=>setData({...data,
                  lname:e.target.value
              })}
              name="last name"
              value={data.lname}
                validators={['required', 'matchRegexp:[A-Za-z]+']}
                errorMessages={['Last name required', 'Invalid name']}  
            />  
        </div>
        <div className="TF_div">
          <label className="TF_Label">Email*</label>
          <TextValidator
                  label=""
                  onChange={(e)=>setData({...data,
                    email:e.target.value
                })}  name="email"
                  value={data.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Email required', 'Invalid email']}
              />
        </div>
        <div className="PNDiv_SA">
          <label className="PN_Label2">Phone Number*</label>
          <ReactPhoneInput className="PN_Input2"
                // style={{color: 'white' }}
                inputProps={{
                  name: 'phonenumber',
                  required: true
                }}
               buttonStyle={{
                  //  backgroundColor:'var(--blueColor)',
                //    color:'black'
               }}
            containerStyle={{          
                      //  backgroundColor:'var(--blueColor)',
          }}
                dropdownStyle={{
                  // backgroundColor:'var(--blueColor)',

                }}
                country={'gb'}
                value={data.phonenumber}  
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '+value+', '+country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  }
                  else if (value.match(/1234/)) {
                    return 'Phone number is invalid';
                  }
                   else {
                    return true;
                  }
                }}
                
                onChange={(code,phonenumber,num,val) =>Phone(phonenumber,val)}  

                // onChange={phonenumber =>setData({...data,phone: phonenumber })}  
              />
     
        </div>      
        <div className="TF_div">
          <label className="TF_Label">Password*</label>
          <TextValidator
                  label=""
                  onChange={(e)=>setData({...data,
                    password:e.target.value
                })}  name="password"
                type="password"
                  value={data.password}
                  validators={['required']}
                  errorMessages={['Password required']}
              />
        </div>
        <div className="TF_div">
          <label className="TF_Label">Confirm Password*</label>
          <TextValidator
                  label=""
                  onChange={(e)=>setData({...data,
                    password2:e.target.value
                })}  name="password"
                type="password"
                  value={data.password2}
                  validators={['required']}
                  errorMessages={['Confirm Password required']}
              />
        </div>

{/* <Grid container style={{padding:'2vh 0px'}} sm={12} xs={12}>

  <Grid item sm={8} xs={12}>
    <Typography>
 Do you have any criminal convictions other than any treated as spent under the provisions of the Rehabilitation of Offenders Act 1974?
  </Typography></Grid>

  <Grid item sm={4} xs={12}>
  <FormControl>
      <RadioGroup

      onChange={(e)=>setData({
        ...data,criminal_record:e.target.value
      })}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        value={data.criminal_record?data.criminal_record:'No'}
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="No" control={<Radio style={{color:'var(--green)'}} />} label="No" />
        <FormControlLabel value="Yes" control={<Radio style={{color:'var(--green)'}} />} label="Yes" />
    
      </RadioGroup>

      
    </FormControl>



</Grid>
{data.criminal_record==='Yes'?<div style={{width:'100%'}} className="TF_div">
          <label className="TF_Label">Criminal Convictions*</label>
          <TextValidator 
// rows={2}
multiline
maxRows={2}                 label=""
                  onChange={(e)=>setData({...data,
                    criminal_record_text:e.target.value
                })}  
                type='text'
                  value={data.criminal_record_text}
                  validators={['required']}
                  errorMessages={['Criminal Convictions required']}
              />
        </div>:null}
</Grid> */}


        <Grid item sm={12} xs={12}>
            <FormControl>
      <RadioGroup

      onChange={(e)=>setData({
        ...data,category:e.target.value
      })}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        value={data.category?data.category:'Driver'}
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Driver" control={<Radio style={{color:'var(--green)'}} />} label="Driver" />
        <FormControlLabel value="Business" control={<Radio style={{color:'var(--green)'}} />} label="Business" />
    
      </RadioGroup>

      
    </FormControl>
            </Grid>
            <Grid item style={{textAlign:'center'}} sm={12} xs={12}>          
           <Typography>
            By registering, you agree to our <span onClick={()=>window.open('/terms_conditions')} style={{color:'var(--green)',fontWeight:'600',cursor:'pointer'}}>Terms &amp; Conditions </span>  and <span onClick={()=>window.open('/privacy_policy')}  style={{color:'var(--green)',fontWeight:'600',cursor:'pointer'}}>Privacy Policy </span>
           </Typography>
            </Grid>

<Grid item style={{textAlign:'center'}} sm={12} xs={12}>                <Button
                color="primary"
                disableElevation
                variant="contained"
                className="primary_btn"
                type="submit"
            >
                {
               !processing? 'SIGN UP':'processing'
                }
            </Button>  
            </Grid>
 
    </ValidatorForm>
    <Grid item sm={12} xs={12}>
<Typography className="new_customer" onClick={()=>setActive('signIn')}>
Already a member?

<span style={{color:'var(--green)',fontWeight:'600'}}><a className="link_register">Sign In</a></span>
</Typography>
</Grid>
    </div>
  </div>
  </Paper>
  </Container>        </Paper>
        </Container> }
        </div>:
        
        
        
        <div>
        
        
        
       {data.forgot? 
       <Container  style={{paddingBottom:30}}>
            <Grid item sm={12} xs={12}>
            <Typography className="signIN_head"><span style={{color:'var(--green)'}}>FORGOT</span> PASSWORD</Typography>
            </Grid>

           <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
        <Paper elevation={6} className="signIN_ppr">
           
            <Grid item sm={12}  xs={12}>
          <TextField value={data.password} onChange={(e)=>setData({...data,password:e.target.value})} style={{width:'90%'}} label={'New Password'}  placeholder="Enter password"  name="password" type={'password'}/>
            </Grid>
            <Grid  item style={{paddingTop:'2vh'}} sm={12}  xs={12}>
          <TextField value={data.password1}  onChange={(e)=>setData({...data,password1:e.target.value})} style={{width:'90%'}} label={'Confirm Password'} placeholder="Enter confirm password"  name='password1' type={'password'}/>
            </Grid>
           
      
<Grid item sm={12} xs={12}>
<Button onClick={()=>submitForgot()} className="btn_signin">
    {!processing?'Submit':'Processing'}
</Button>
</Grid>

        </Paper>
        </Grid>
        </Container>:
         <Container  style={{paddingBottom:30}}>
        <Grid item sm={12} xs={12}>
            <Typography className="signIN_head"><span style={{color:'var(--green)'}}>FORGOT</span> PASSWORD</Typography>
        </Grid>

       <Grid item sm={12} style={{textAlign:'-webkit-center'}} xs={12}> 
    <Paper elevation={6} className="signIN_ppr">
        <Grid sm={12} xs={12}>
        <Typography className="phone_head">
            Enter Your OTP
        </Typography>
        </Grid>
        <Grid sm={12}  xs={12}>
      <TextField  value={otp1} onChange={(e)=>setOTP1(e.target.value)} style={{width:'90%'}} label={'OTP'} name="otp" placeholder={`Enter otp sent on your email`}/>
        </Grid>
<Grid item sm={12} xs={12}>
<Button onClick={()=>verifyClicked1()} className="btn_signin">
{verify1?'Verifying':'Verify'}
</Button>
</Grid>
<Grid item sm={12} xs={12}>
<Typography onClick={()=>resendOtp1()} className="new_customer">
Resend <span style={{cursor:'pointer',color:'var(--green)'}}>OTP</span>

</Typography>
<Typography style={{fontSize:'13px'}} >
<i style={{color:'var(--green)'}} class="fas fa-info-circle"></i> Sometimes OTP may appear in spam folder

  </Typography>
</Grid>
    </Paper>
    </Grid>
    </Container>}
          </div>}
    </div>
)
// }

  
 }