import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { live } from '../../App';
import {useCookies} from 'react-cookie'

export default function Invoices_Live(props) {
  const [drivers,setDrivers]=useState([])
  const [reload,setReload]=useState(0)
  const [processing,setProcessing]=useState(true)

  const [search,setSearch]=useState('')


  useEffect(() => {
    setProcessing(true)
      fetch(live+`invoices`,{
        method:'PUT'
      }).then((res)=>res.json()).then((resp)=>driversResp(resp))
      }, [reload])

      const driversResp=(val)=>{


  setProcessing(false)
  setDrivers(val.data)


           

          
          
          

      }

  const rows=
  drivers&&drivers[0]&&!search?drivers.map((item,k) => {
    return  {

     
    id:k+1,
    invoice:item.invoice,
    business_id:item.business_id,


    fname:item.fname,
    lname:item.lname,
    email:item.email,
    tax:'£'+item.tax_amount,

    reference_id:item.reference_id,
    amount:'£'+item.amount,
    total_amount:'£'+item.total_amount,
    created_on:item.created_on
   
      




      



     
    }
}
  ):drivers&&drivers[0]&&search&&search.length>2&&drivers.filter(val=>(val.invoice.toLowerCase().includes(search.toLowerCase()))||(val.business_id.toLowerCase().includes(search.toLowerCase())))&&drivers.filter(val=>(val.invoice.toLowerCase().includes(search.toLowerCase()))||(val.business_id.toLowerCase().includes(search.toLowerCase())))[0]?drivers.filter(val=>(val.invoice.toLowerCase().includes(search.toLowerCase()))||(val.business_id.toLowerCase().includes(search.toLowerCase()))).map((item,k) => {return  {

    id:k+1,
    invoice:item.invoice,
    business_id:item.business_id,


    fname:item.fname,
    lname:item.lname,
    email:item.email,
    tax:'£'+item.tax_amount,

    reference_id:item.reference_id,
    amount:'£'+item.amount,
    total_amount:'£'+item.total_amount,
    created_on:item.created_on
 
    




    



   
  }}):''
  const renderDownload = (val) => {
                    
                
    return (
        <strong>
                   <Button onClick={()=>window.open(live+`invoices?invoice=${val.row.reference_id}`)}  className='btn_evd2'>{'Download'}


                   </Button>
        </strong>
    )
  }
  const columns = [
    { field: 'id', headerName: 'S No.', width: 60 },
    { field: 'invoice', headerName: 'Invoice ID'},
    { field: 'business_id', headerName: 'Business ID'},

  
    {
      field: 'fname',
      headerName: 'First Name',
     
    },
    {
      field: 'lname',
      headerName: 'Last Name',
    sortable:false,
    filterable:false
    
    },
    // {
    //   field: 'company_name',
    //   headerName: 'Company Name',
    //   // type: 'number',
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,  

      // type: 'number',
    }
    , {
      field: 'amount',
      headerName: 'Amount',
      // type: 'number',


    }, {
      field: 'tax',
      headerName: 'Tax',
      // type: 'number',


    }
    
    , {
        field: 'total_amount',
        headerName: 'Total',
        // type: 'number',
  
  
      },
      {
        field: 'created_on',
        headerName: 'Issued On',
        // type: 'number',
        width: 180,  
  
  
      },

   
    
 
  

  
   
       {
                      field: 'Download',
                      headerName: ' ',
                      sortable:false,
                      width:170,
                      filterable:false,      
                            renderCell: renderDownload,
                       },
//              {
//               field: 'Redeem',
//               headerName: ' ',
//               sortable:false,
//               width:170,
//               filterable:false,      
//                     renderCell: renderRedeem,
//                },
//                {
//                 field: 'Freeze',
//                 headerName: ' ',
// sortable:false,
//             filterable:false,              
//               renderCell: renderFreeze,
//                 width: 160, 
//                  },
//                  {
//                     field: 'Jobs Completed',
//                     headerName: ' ',
//     sortable:false,
//                 filterable:false,              
//                   renderCell: renderCompleted,
//                     width: 190, 
//                      },
  ];
  
  return (
    <>
 
  
        <Grid container style={{justifyContent:'center'}}>
        <Grid item style={{maxWidth:1200}} sm={12} xs={12}>
<TextField placeholder='Search invoices here ..' className='field_search' onChange={(e)=>setSearch(e.target.value)} />
</Grid>
<Grid container>
<Grid  style={{marginTop:'2vh',marginBottom:'2vh',textAlign:'end',paddingRight:'2vw'}}item sm={12} xs={12}>
        <Chip onClick={()=>props.closeLive()} label='Back to dashboard' style={{color:'white',background:'var(--green)'}}  />
      </Grid>
      </Grid>
{/* 
<Grid container  style={{margin:'2vh 0px',maxWidth:1200}}>
    <Grid item style={{margin:'1vh 0px'}} sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'

        label="From"
        
     
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
   
             
    </Grid>
    <Grid item style={{margin:'1vh 0px'}} sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'

        label="To"
        
     
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
   
             
    </Grid>
   </Grid> */}
   <Grid container>
{/* {(drivers&&drivers[0]&&!processing&&!edit&&!search)||(drivers&&drivers[0]&&!processing&&!edit&&search&&search.length>2&&rows)? */}
{(drivers&&drivers[0]&&!processing&&!search)||(drivers&&drivers[0]&&!processing&&search&&search.length>2&&rows)?

<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!drivers[0]&&!processing)||(!rows&&!processing)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Invoices Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>
        </Grid>
    </>
  )
}
