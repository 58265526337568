import { Box, Button, CircularProgress, Grid,Chip, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, Slide, Paper, Container, IconButton ,DialogContent} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { live } from '../../App'
import { DataGrid } from '@mui/x-data-grid';

import {useCookies} from 'react-cookie'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CloseIcon from '@mui/icons-material/Close';

export default function Request_overtime() {
  const ref=useRef()

  const[auth,setAuth]=useCookies(['drivewide'])
const [data,setData]=useState({})
const [active,setActive]=useState(1)
const [reject,setReject]=useState(false)
const [reload,setReload]=useState(0)

const [overtime,setOvertime]=useState('')
const [processing,setProcessing]=useState(true)
    useEffect(() => {
      setProcessing(true)
        fetch(live +`overtime?driver_id=${auth.drivewide.driver_id}`).then(res => res.json()).then(resp => Overtimes(resp))

    }, [reload])
    const Overtimes=(val)=>{
        setProcessing(false)
        setData({
          ...data,overtimes1:val.data,overtimes2:val.data2
        })}
      
        const rows= data.overtimes1&&data.overtimes1.map((item,k) => {
          return  {
            job_id:item.job_id,
        
            id:k+1,
            driver_id:item.driver_id,
            business_id:item.business_id,
            // created_on:(new Date(item.created_on).getDate()).padStart(2, '0')+(new Date(item.created_on).getMonth()+1).padStart(2, '0')+(new Date(item.created_on).getFullYear()),
            requested_on:(new Date(item.requested_on).getDate()).toString().padStart(2,'0')+'/'+(new Date(item.requested_on).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(item.requested_on).getFullYear()).toString(),

            // requested_on:item.requested_on,
            status:item.status,
            pay_rate:item.pay_rate,
            overtime_rate:item.overtime_rate,
            pay_rate_hour:item.pay_rate_hour,
            completed_on:item.completed_on?((new Date(item.completed_on).getDate()).toString().padStart(2,'0')+'/'+(new Date(item.completed_on).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(item.completed_on).getFullYear()).toString()):' ',

            created_on:(new Date(item.created_on).getDate()).toString().padStart(2,'0')+'/'+(new Date(item.created_on).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(item.created_on).getFullYear()).toString(),
           
            address:item.address,
            company_name:item.company_name,
            job_title:item.job_title,


          }
        }
          )
        const rows1= data.overtimes2&&data.overtimes2.map((item,k) => {
          return  {
            job_id:item.job_id,
        
            id:k+1,
            driver_id:item.driver_id,
            business_id:item.business_id,
            // created_on:(new Date(item.created_on).getDate()).padStart(2, '0')+(new Date(item.created_on).getMonth()+1).padStart(2, '0')+(new Date(item.created_on).getFullYear()),
            requested_on:(new Date(item.requested_on).getDate()).toString().padStart(2,'0')+'/'+(new Date(item.requested_on).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(item.requested_on).getFullYear()).toString(),

            // requested_on:item.requested_on,
            status:item.status,
            pay_rate:item.pay_rate,
            overtime_rate:item.overtime_rate,
            pay_rate_hour:item.pay_rate_hour,
            completed_on:item.completed_on?((new Date(item.completed_on).getDate()).toString().padStart(2,'0')+'/'+(new Date(item.completed_on).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(item.completed_on).getFullYear()).toString()):' ',

            created_on:(new Date(item.created_on).getDate()).toString().padStart(2,'0')+'/'+(new Date(item.created_on).getMonth()+1).toString().padStart(2,'0')+'/'+(new Date(item.created_on).getFullYear()).toString(),
           
            address:item.address,
            company_name:item.company_name,
            job_title:item.job_title,


          }
        }
          )
          const renderID = (val) => {
            return (
                <strong>
                  <a style={{color:'var(--green)'}} href={`/jobs/${val.row.job_id}`} target='_blank' >{val.row.job_id}</a>
                </strong>
            )
          } 
              

   const renderRequest = (val) => {
          

    
            return (



                <strong>
{val.row.status==='In Progress'?<span>      <Button      style={{backgroundColor:'grey',color:'white',borderRadius:20}}>{'Requested'}</Button>
</span>:
<span>          
<Button onClick={()=>setOvertime(val.row)}    disabled={data.processing}   className='btn_evd2'>{data.processing&&!reject&&overtime.job_id===val.row.job_id?'Requesting':'Request Overtime'}</Button>
       
</span>}

                </strong>
            )
          }

//           const renderRejected= (val) => {
          


            
    
//             return (



//                 <strong>
// {val.row.status==='Rejected'?
// <span>                   <Button      style={{backgroundColor:'grey',color:'white',borderRadius:20}}>{'Rejected'}</Button>
// </span>:null}

//                 </strong>
//             )
//           }

          const columns = [
            { field: 'id', headerName: 'S No.', width: 60 },
            { field: 'job_id', headerName: 'Job ID', renderCell: renderID,},
          
            {
              field: 'job_title',
              headerName: 'Job Title',
             
            },
            {
              field: 'driver_id',
              headerName: 'Driver ID',
            sortable:false,
            filterable:false
            
            },
            // {
            //   field: 'company_name',
            //   headerName: 'Company Name',
            //   // type: 'number',
            // },
            {
              field: 'business_id',
              headerName: 'Business ID',
              width: 180,  
  
              // type: 'number',
            }, 
            {
              field: 'overtime_rate',
              headerName: 'Overtime Rate',
              width: 180,  
  
              // type: 'number',
            },  {
              field: 'pay_rate_hour',
              headerName: 'Overtime Hrs',
              width: 180,  
  
              // type: 'number',
            }, {
              field: 'pay_rate',
              headerName: 'Total Overtime Amount',
              // type: 'number',
              width: 180,  
  
            },
          
            
     
            {
              field: 'created_on',
              headerName: 'Requested On',
              // type: 'number',
              width: 180,  

            },
            {
              field: 'status',
              headerName: 'Status',
              // type: 'number',
              width: 180,  

            },
         
          
           
           
            // {
            //   field: 'Requested',
            //   headerName: ' ',
            //   sortable:false,
            //   filterable:false,          
            //     renderCell: renderRejected,
            //     width: 160, 


            //    },
          
            {
              field: 'Approve',
              headerName: ' ',
              sortable:false,
              filterable:false,          
                renderCell: renderRequest,
                width: 180, 


               },
              //  {
              //   field: 'Not Approve',
              //   headerName: ' ',
              //   sortable:false,
              //   filterable:false,              renderCell: renderNotApprove,
              //   width: 160, 
  
              //    },
                
          ];
          const columns1 = [
            { field: 'id', headerName: 'S No.', width: 60 },
            { field: 'job_id', headerName: 'Job ID', renderCell: renderID,},
          
            {
              field: 'job_title',
              headerName: 'Job Title',
             
            },
            {
              field: 'driver_id',
              headerName: 'Driver ID',
            sortable:false,
            filterable:false
            
            },
            // {
            //   field: 'company_name',
            //   headerName: 'Company Name',
            //   // type: 'number',
            // },
            {
              field: 'business_id',
              headerName: 'Business ID',
              width: 180,  
  
              // type: 'number',
            }, 
            {
              field: 'overtime_rate',
              headerName: 'Overtime Rate',
              width: 180,  
  
              // type: 'number',
            },  {
              field: 'pay_rate_hour',
              headerName: 'Overtime Hrs',
              width: 180,  
  
              // type: 'number',
            }, {
              field: 'pay_rate',
              headerName: 'Total Overtime Amount',
              // type: 'number',
              width: 180,  
  
            },
          
            
     
            {
              field: 'created_on',
              headerName: 'Requested On',
              // type: 'number',
              width: 180,  

            },
            {
              field: 'completed_on',
              headerName: 'Completed On',
              // type: 'number',
              width: 180,  

            },
            {
              field: 'status',
              headerName: 'Status',
              // type: 'number',
              width: 180,  

            },
         
          
           
          
          
          
                
          ];
    



          const manageOvertime=(val)=>{
            if(data.hours){
              setData({...data,processing:true})

              const formData=new FormData()
              formData.append('job_id',overtime.job_id)
              formData.append('job_title',overtime.job_title)
        
              formData.append('hours',parseFloat(data.hours))
              formData.append('pay_rate',(parseFloat(data.hours)*parseFloat(overtime.overtime_rate)).toFixed(2))
              fetch(live+'overtime',{
                method:'POST',
                body:formData
                
              }).then((resp)=>resp.json()).then(res=>requestRes(res))
        
        
        
            }
            else{
              Alert.alert('Please provide overtime hours')
            }  
           }
        
           const requestRes=(val)=>{
            if(val.status){
              alert(val.detail)
              setData({})
              setOvertime('')
              setReload(reload+1)
        
            }
            else{
              alert('Something went wrong, please try again later')
        
            }
        
           }




  return (




<Grid container style={{padding:'5vh 0px'}}>


<Dialog
        open={overtime?true:false}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={()=>setOvertime('')}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={()=>setOvertime('')}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{`Request Overtime (${overtime.job_id})`}       
          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
          <div className="Floating_Form2">
            <ValidatorForm
                ref={ref}
                onSubmit={manageOvertime}
            >
              <div className="TF_div">
              <label className="TF_Label" style={{fontWeight:'500'}}>  Overtime Rate : £{overtime.overtime_rate}/hr</label>
              </div>
           
         
          {  <div className="TF_div">
              <label className="TF_Label">Overtime Hours*</label>
              <TextValidator
            
              
                      label=""
                      
                      onChange={(e)=>setData({...data,
                        hours:e.target.value
                    })}  name="hours"
                      value={data.hours}
                      type='number'
                      validators={['required']}
                      errorMessages={['Overtime hours required']}
                  />
            </div>
      }
         {data.hours?<div className="TF_div">
              <label className="TF_Label" style={{fontWeight:'500'}}>   Total Rate : {(parseFloat(data.hours)*parseFloat(overtime.overtime_rate)).toFixed(2)}</label>
              </div>:null}
        
           
              
              <div className="Button_UL"></div>

              { <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={data.processing}
                >
                   {data.processing?
                    'Requesting':'Request Overtime'
                    }
                </Button> }      
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>
    
    
    {processing?<Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>:

            <>
            <Grid item sm={12}  style={{padding:'4vh 2vw'}}xs={12}>

<Chip label='Overtime Requests' onClick={()=>setActive(1)} style={{backgroundColor:active===1?'var(--green)':'grey',color:active===1?'white':'white',marginRight:'3vw'}}  /> 
<Chip label='Overtime History'  onClick={()=>setActive(2)} style={{backgroundColor:active===2?'var(--green)':'grey',color:active===2?'white':'white',marginRight:'3vw'}} /> 

</Grid>

{

active===1?(
data&&data.overtimes1&&data.overtimes1[0]?
  <Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:<Grid sm={12} style={{textAlign:'center'}} xs={12} item>

<Typography className='txt_evd'>
  No Overtime Requests Found
</Typography>
</Grid>):(
data&&data.overtimes2&&data.overtimes2[0]?
  <Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows1}
        columns={columns1}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:<Grid sm={12} style={{textAlign:'center'}} xs={12} item>

<Typography className='txt_evd'>
  No Overtime History Found
</Typography>
</Grid>)}
    </>}

</Grid>  )
}
