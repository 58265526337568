import { Container, Grid, Typography,Accordion,AccordionDetails,AccordionSummary, TextField,Chip, FormControlLabel, FormGroup, Switch, Tooltip, IconButton, Snackbar, Alert, Button, Checkbox, Card, CardActionArea, CardMedia, CardContent, AlertTitle} from '@mui/material'
import  ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useState } from 'react'
import {useCookies} from 'react-cookie'
import ReactPhoneInput from "react-phone-input-2";

import './job_post.css'
import { Company_type, Job_type, Language_P, Pay_type } from './data'
import { imgLink, live } from '../../App';
export default function Business_details(props) {
    const business=props.edit
    const store=props.store

  const[auth,setAuth]=useCookies(['drivewide'])

    const [active1,setActive1]=useState(false)
    const [data,setData]=useState({
        business_id:business.business_id,
        fname:business.fname?business.fname:'',
        lname:business.lname?business.lname:'',
        mobile:business.mobile?business.mobile:'',
        country_code:business.country_code?business.country_code:'',
        email:business.email?business.email:'',
        address_line1:business.address_line1?business.address_line1:'',
        address_line2:business.address_line2?business.address_line2:'',
        city:business.city?business.city:'',
        stat:business.stat?business.stat:'',
        country:business.country,
        zip_code:business.zip_code?business.zip_code:'',
        company_type:business.company_type?business.company_type:'',
        company_name:business.company_name?business.company_name:'',
        company_number:business.company_number?business.company_number:'',
        vat_registration_number:business.vat_registration_number?business.vat_registration_number:'',
        is_verified:business.is_verified,




    })


      
    const Phone=(val,val1)=>{
        setData({...data,code:'+'+val.dialCode,mobile:val1.replace('+'+val.dialCode+' ','')})
        }
    const[errMsg,setError]=useState(false)
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)

const [edit,setEdit]=useState(false)


const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)


}
  




    const Cancel=()=>{
      setData({
        ...data
        ,
        business_id:business.business_id?business.business_id:'',

        fname:business.fname?business.fname:'',
        lname:business.lname?business.lname:'',
        mobile:business.mobile?business.mobile:'',
        country_code:business.country_code?business.country_code:'',
        email:business.email?business.email:'',
        address_line1:business.address_line1?business.address_line1:'',
        address_line2:business.address_line2?business.address_line2:'',
        city:business.city?business.city:'',
        stat:business.stat?business.stat:'',
        country:business.country,
        zip_code:business.zip_code?business.zip_code:'',
        company_type:business.company_type?business.company_type:'',
        company_name:business.company_name?business.company_name:'',
        company_number:business.company_number?business.company_number:'',
        vat_registration_number:business.vat_registration_number?business.vat_registration_number:'',
        is_verified:business.is_verified,
      })

      setEdit(false)
    }
 
const submitJob=()=>{

if (edit){
  setData({
    ...data,processing:true
  })
  // setEdit(true)
  
  const formdata=new FormData()
  formdata.append('edited_by',auth.drivewide.business_id&&auth.drivewide.is_admin?auth.drivewide.business_id:'')
  formdata.append('business_id',business.business_id)
  formdata.append('skills',JSON.stringify(data.skills))
  formdata.append('fname',data.fname)
  formdata.append('lname',data.lname)
  formdata.append('mobile',data.mobile)
  formdata.append('country_code',data.country_code)
  
  formdata.append('addLine1',data.address_line1)
  formdata.append('addLine2',data.address_line2)
  formdata.append('city',data.city)
  formdata.append('state',data.stat)
  formdata.append('postcode',data.zip_code)
  formdata.append('company_type',data.company_type)
  formdata.append('company_name',data.company_name)
  formdata.append('company_number',data.company_number)
  formdata.append('vat_registration_number',data.vat_registration_number)
  formdata.append('is_verified',data.is_verified)

  
  
  
  
  fetch(live+'business-major',
  {
    method:'PUT',
    body:formdata
  }).then(resp=>resp.json()).then(res=>editRes(res))
}
else{
  setEdit(true)
}

}
const editRes=(val)=>{
 if(val.status&&!store) {
  setData({
    ...data,processing:false
  })
  setOpen1(true)
  setError(val.detail)
  props.backPressed()
 }
 if(val.status&&store){
  setData({
    ...data
    ,
    business_id:val.data.business_id?val.data.business_id:'',

    fname:val.data.fname?val.data.fname:'',
    lname:val.data.lname?val.data.lname:'',
    mobile:val.data.mobile?val.data.mobile:'',
    country_code:val.data.country_code?val.data.country_code:'',
    email:val.data.email?val.data.email:'',
    address_line1:val.data.address_line1?val.data.address_line1:'',
    address_line2:val.data.address_line2?val.data.address_line2:'',
    city:val.data.city?val.data.city:'',
    stat:val.data.stat?val.data.stat:'',
    country:val.data.country,
    zip_code:val.data.zip_code?val.data.zip_code:'',
    company_type:val.data.company_type?val.data.company_type:'',
    company_name:val.data.company_name?val.data.company_name:'',
    company_number:val.data.company_number?val.data.company_number:'',
    vat_registration_number:val.data.vat_registration_number?val.data.vat_registration_number:'',
    is_verified:val.data.is_verified,
    processing:false
  })
  setError(val.detail)

  setOpen1(true)
  setAuth('drivewide',val.data,{
    maxAge: 3600 ,
    expires:0,
    path:'/'
 })
  

 setEdit(false)
 }
 else {
  setData({
    ...data,processing:false
  })
  setOpen(true)
  setError(val.detail)
 }
}

// console.log(data.skills.filter((v, i, a) => console.log(v,i,a)))
const dropDownVal=(val)=>{

      setData({
          ...data,company_type:val
      })
      setActive1(false)
  
 
   
 

}

const Verify=()=>{

if(open2&&!data.is_verified){

  setData({
    ...data,is_verified:!data.is_verified
  })
  handleClose()
}
else if(data.is_verified){

  setData({
    ...data,is_verified:!data.is_verified
  })
}

else{
  setOpen2(true)
}
}

  
    return (
    <Container>
      <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>

  <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Verify()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Verifying business will enable business to publish jobs
</AlertTitle>

      </Alert>
     
     
      </Snackbar>
        <Grid container style={{padding:'2vh 0px'}} >
            <Grid item sm={9} xs={9}>

<Typography className='job_post_head'>
Editing {data.fname}'s profile
</Typography>


            </Grid>
            <Grid item sm={3} xs={3}>
            <Button  onClick={()=>props.backPressed1()} style={{marginTop:'15vh'}} className='btn_evd2'>Back</Button>

            </Grid>
            <Grid item sm={12} xs={12}>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography  className='job_post_head_section' >Personal Information ({data.business_id})</Typography>

          {/* {data.job_title&&data.job_type&&data.town_name&&data.post_code&&data.company_name&&data.company_description&&data.job_description?<div style={{width:'80%',textAlign:'-webkit-right',paddingRight:'5vw'}}>
          <Typography className='completed' >Completed</Typography>
          </div>:null} */}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
            <Grid item sm={6} xs={12}>
{!edit?<Typography className='txt_info'>
    Frist Name : {data.fname}
</Typography>:
                <TextField value={data.fname}  label='First Name' placeholder='Enter first name'  className='job_post_textfield' onChange={(e)=>setData({...data,fname:e.target.value})} />}
            </Grid>
            

          
            <Grid item sm={6} xs={12}>

           {edit? <TextField value={data.lname}  label='Last Name' placeholder='Enter last name'  className='job_post_textfield' onChange={(e)=>setData({...data,lname:e.target.value})} />:

            <Typography className='txt_info'>
    Last Name : {data.lname}
</Typography>    }        </Grid>
            <Grid  className='job_post_textfield'  item sm={6} xs={12}>
            {edit? 
            
            <ReactPhoneInput className="PN_Input2"

            
            // style={{color: 'white' }}
            inputProps={{
              name: 'phonenumber',
              required: true
            }}
           buttonStyle={{
              //  backgroundColor:'var(--blueColor)',
            //    color:'black'
           }}
        containerStyle={{          
                  //  backgroundColor:'var(--blueColor)',
      }}
            dropdownStyle={{
              // backgroundColor:'var(--blueColor)',

            }}
            country={'gb'}
            
            value={(data.mobile?data.country_code:'')+data.mobile}  
           
            
            onChange={(code,phonenumber,num,val) =>Phone(phonenumber,val)}  

            // onChange={phonenumber =>setData({...data,...data,phone: phonenumber })}  
          /> 
            :

            <Typography className='txt_info'>
    Phone : {data.country_code+' '+data.mobile}
</Typography>    }        </Grid>

            <Grid item sm={6} xs={12}>
            {edit&&!store? <TextField value={data.email}  label='Email' placeholder='Enter email'  className='job_post_textfield' onChange={(e)=>setData({...data,email:e.target.value})} />:

            <Typography className='txt_info'>
    Email : {data.email}
</Typography>        }    </Grid>
            <Grid item sm={6} xs={12}>
            {edit?
            <>
            <TextField value={data.address_line1}  label='Address Line1' placeholder='Enter addresss line1'  className='job_post_textfield' onChange={(e)=>setData({...data,address_line1:e.target.value})} />
            <TextField value={data.address_line2}  label='Address Line1' placeholder='Enter addresss line1'  className='job_post_textfield' onChange={(e)=>setData({...data,address_line2:e.target.value})} />
            <TextField value={data.city}  label='City' placeholder='Enter city'  className='job_post_textfield' onChange={(e)=>setData({...data,city:e.target.value})} />
            <TextField value={data.stat}  label='State' placeholder='Enter state'  className='job_post_textfield' onChange={(e)=>setData({...data,stat:e.target.value})} />
            <TextField value={data.zip_code}  label='Postcode' placeholder='Enter postcode'  className='job_post_textfield' onChange={(e)=>setData({...data,lname:e.target.value})} />

            </>:
            <Typography className='txt_info'>
    Address :  {(data.address_line1?(data.address_line1+', '):'')+(data.address_line2?(data.address_line2+', '):'')+(data.city?(data.city+', '):'')+(data.stat?(data.stat+', '):'')+(data.zip_code?(data.zip_code):'')}
</Typography>     }       </Grid>




          </Grid>
        </AccordionDetails>
      </Accordion>
     

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography  className='job_post_head_section' >Business Details</Typography>

      
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>

        

          <Grid item sm={6} xs={12}>
        {edit?<>
            <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={12} xs={12}>
<Typography  style={{paddingRight:'20px',fontSize:'17px'}}>
   Company Type
</Typography>
  <Chip

label={data.company_type?data.company_type:''}
onClick={()=>setActive1(!active1)}
onDelete={()=>setActive1(!active1)}
deleteIcon={active1?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  {active1?<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
  <div className="filter_values">
<ul>
 {Company_type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}


 
 
</ul>
  </div>

</div>:null}
        
        
        </>:    <Typography className='txt_info'>
    Company Type :  {data.company_type}
</Typography> }           </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.company_name}  label='Company Name' placeholder='Enter company type'  className='job_post_textfield' onChange={(e)=>setData({...data,company_name:e.target.value})} />:

            <Typography className='txt_info'>
    Company Name : {data.company_name}
</Typography>        }    </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.company_number}  label='Company Number' placeholder='Enter company number'  className='job_post_textfield' onChange={(e)=>setData({...data,company_number:e.target.value})} />:

            <Typography className='txt_info'>
    Company Number : {data.company_number}
</Typography>        }    </Grid>
<Grid item sm={6} xs={12}>
            {edit? <TextField value={data.vat_registration_number}  label='VAT Registration number' placeholder='Enter VAT number'  className='job_post_textfield' onChange={(e)=>setData({...data,vat_registration_number:e.target.value})} />:

            <Typography className='txt_info'>
    VAT Registration number : {data.vat_registration_number}
</Typography>        }    </Grid>


          </Grid>
        </AccordionDetails>
        
      </Accordion>


      <Accordion expanded={false}>
        <AccordionSummary
        
        // style={{background:'var(--green)'}}
    
            aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography   style={{color:'var(--green)'}}
 className='job_post_head_section' >Verified </Typography>

          <FormGroup  style={{alignContent:'end',paddingLeft:'5vw',width:'100%'}} >
          <FormControlLabel  checked={data.is_verified}  control={<Switch disabled={!edit||store}  onChange={(e)=>Verify()}  style={{color:'white'}} />} style={{fontSize:17}}  />
          
        </FormGroup>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
      



          </Grid>
        </AccordionDetails>
        
      </Accordion>
            </Grid>
            <Grid item style={{textAlign:'center',padding:'2vh 0px'}} sm={12} xs={12}>
                <Chip 
                // disabled={data.processing} 
                 onClick={()=>submitJob()} className='chip_edit' label={data.processing?'Editing':edit&&!data.processing?'Save':'Edit Business'}/>
                {edit?<Chip 
                // disabled={data.processing} 
                 onClick={()=>Cancel()} style={{marginLeft:'2vw'}} className='chip_cancel' label={'Cancel'}/>
        :null}
            </Grid>

        </Grid>
    </Container>
  )
}
