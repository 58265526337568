import { Container, Grid, Typography,Accordion,AccordionDetails,AccordionSummary, TextField,Chip, FormControlLabel, FormGroup, Switch, Tooltip, IconButton, Snackbar, Alert, Button, Checkbox, AlertTitle} from '@mui/material'
import  ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState } from 'react'
import {useCookies} from 'react-cookie'
import dayjs from 'dayjs';

import './job_post.css'
import { Driving_category, Job_type, Language_P, Pay_type } from './data'
import { MyEditorS, MyEditorS2 } from './editor';
import { TimePicker } from '@mui/x-date-pickers';
import { live } from '../../App';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
export default function Job_post_Edit(props) {
  const[auth,setAuth]=useCookies(['drivewide'])
const job=props.edit
const duplicate=props.duplicate

// console.log(job)

    const [active1,setActive1]=useState('')
    const [data,setData]=useState({
        job_title:job.job_title?job.job_title:'',
        job_type:job.job_type?job.job_type:'',
        urgent_hiring:job.urgent_job?job.urgent_job:'',
        town_name:job.town_name?job.town_name:'',
        post_code:job.post_code?job.post_code:'',
        company_name:job.company_name?job.company_name:'',
        company_website:job.company_website?job.company_website:'',
        company_description:job.company_description?job.company_description:'',
        job_description:job.job_description?job.job_description:'',
        skills:job.skills?job.skills:'',
        experience_years:job.experience_years?job.experience_years:0,
        experience_months:job.experience_months?job.experience_months:0,
        pay_type:job.pay_rate_type?job.pay_rate_type:'',
        pay_rate:job.pay_rate?parseFloat(job.pay_rate):'',
        licence_category:job.licence_category?job.licence_category:'',

        language:job.language?job.language:'',

        overtime:job.overtime_availability?job.overtime_availability:'',
        start_date:job.job_start_date?dayjs(job.job_start_date):'',
        start_time:job.job_start_time?dayjs(job.job_start_date+'T'+job.job_start_time):'',
        end_date:job.job_end_date?dayjs(job.job_end_date):'',
        end_time:job.job_end_time?dayjs(job.job_start_date+'T'+job.job_end_time):'',
        reporting_date:job.job_reporting_date?(job.job_reporting_date):'',
        reporting_time:job.job_reporting_time?dayjs(job.job_start_date+'T'+job.job_reporting_time):'',
        acceptance_time:job.last_acceptance_time?dayjs(job.job_start_date+'T'+job.last_acceptance_time):'',
        acceptance_date:job.last_acceptance_date?dayjs(job.last_acceptance_date):'',
is_active:job.is_active?job.is_active:'',
hours:job.hours?job.hours:0,
pay_rate_c:job&&job.pay_rate?job.pay_rate:''

    })
    // console.log(dayjs(job.job_reporting_date))

    const[errMsg,setError]=useState(false)
const [open,setOpen]=useState(false)
const [open1,setOpen1]=useState(false)
const [open2,setOpen2]=useState(false)
const [openA,setOpenA]=useState(false)
const [activeA,setActiveA]=useState('')
const [openS,setOpenS]=useState(true)
const [open_submit,setOpen_submit]=useState(false)

const [editor,setEditor]=useState(job.company_description?job.company_description:'')
const [editor1,setEditor1]=useState(job.job_description?job.job_description:'')
const[suggestions,setSuggestions]=useState()


const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
setOpen2(false)
setOpenS(false)
setOpen_submit(false)

}







    const quillChange=(val)=>{
        setData(data => ({ ...data, company_description:val}))
        
       }
       const quillChange1=(val)=>{
        setData(data => ({ ...data, job_description:val}))
        
       }
    const Active1=(val)=>{

        if(val===active1){
            setActive1('')
        }
        else{
          setActive1(val)

        }
    
    }


    const dropDownVal=(val)=>{

        if(active1==='job_type'){
            setData({
                ...data,job_type:val
            })
            setActive1(false)
        }
        if(active1==='pay_type'){
          setData({
            ...data,pay_type:val,
            pay_rate:10.42,hours:hours,

                    })

setActive1(false)

         


           
        }
        if(active1==='language'){
          setData({
              ...data,language:val
          })
          setActive1(false)
      }
     
       
     
    
    }

    const addSkills=()=>{
      const item={
        'id':data.skills&&data.skills.length?(data.skills.length+1):1,
        "skill":data.skill
      }

      if(data&&data.skills&&data.skills[0]&&data.skills[0].skill){

        if(data.skills.find(x=>x.skill===data.skill))
        {
       
        }
        else{
          setData({
            ...data,skills:[...data.skills,item]
          })
        }
       

      }
      else{
        setData({
          ...data,skills:[item]
        })
      }
     
    }
    const drivingCheckbox=(cat)=>{
      const item={
        'id':data.licence_category&&data.licence_category.length?(data.licence_category.length+1):1,
        "category":cat.value,
        'value': cat.name

      }

// if(data&&data.licence_category&&data.licence_category[0]&&data.licence_category[0].category){

//   if(data.licence_category.find(val=>val.category===cat.value)){
//     setData({
//       ...data,licence_category:data.licence_category.filter(val=>val.category!==cat.value)
//     })
//       }

//       else{
//         setData({
//           ...data,licence_category:[...data.licence_category,item]
//         })
//       }
// }

     
//       else{
//         setData({
//           ...data,licence_category:[item]
//         })

      
//       }
setData({
  ...data,licence_category:[item],pay_rate_c:parseFloat(Driving_category.find(val=>val.value==cat.name).pay_rate)
})
    }
    let date1=new Date()
    const dateN=new Date(data.start_date)
    const dateN_end_date=new Date(data.end_date)
    const dateN_reporting_date=new Date(data.reporting_date)
    const dateN_acceptance_date=new Date(data.acceptance_date)



var dd = data&&data.start_date&&data.start_date&&data.start_date.$d?String(data.start_date.$d.getDate()).padStart(2, '0'):data&&data.start_date&&data.start_date&&!data.start_date.$d?dateN.getDate().toString().padStart(2,'0'):date1.getDate().toString().padStart(2,'0');
var mm =data&&data.start_date&&data.start_date&&data.start_date.$d?String(data.start_date.$d.getMonth() + 1).padStart(2, '0'):data&&data.start_date&&data.start_date&&!data.start_date.$d?(dateN.getMonth()+1).toString().padStart(2,'0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy =data&&data.start_date&&data.start_date&&data.start_date.$d?data.start_date.$d.getFullYear():data&&data.start_date&&data.start_date&&!data.start_date.$d?dateN.getFullYear():date1.getFullYear();



var dd1 = data&&data.end_date&&data.end_date&&data.end_date.$d?String(data.end_date.$d.getDate()).padStart(2, '0'):data&&data.end_date&&data.end_date&&!data.end_date.$d?dateN_end_date.getDate().toString().padStart(2,'0'):date1.getDate().toString().padStart(2,'0');
var mm1 =data&&data.end_date&&data.end_date&&data.end_date.$d?String(data.end_date.$d.getMonth() + 1).padStart(2, '0'):data&&data.end_date&&data.end_date&&!data.end_date.$d?(dateN_end_date.getMonth()+1).toString().padStart(2,'0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy1 =data&&data.end_date&&data.end_date&&data.end_date.$d?data.end_date.$d.getFullYear():data&&data.end_date&&data.end_date&&!data.end_date.$d?dateN_end_date.getFullYear():date1.getFullYear();



var dd3 = data&&data.reporting_date&&data.reporting_date&&data.reporting_date.$d?String(data.reporting_date.$d.getDate()).padStart(2, '0'):data&&data.reporting_date&&data.reporting_date&&!data.reporting_date.$d?dateN_reporting_date.getDate().toString().padStart(2,'0'):date1.getDate().toString().padStart(2,'0');
var mm3 =data&&data.reporting_date&&data.reporting_date&&data.reporting_date.$d?String(data.reporting_date.$d.getMonth() + 1).padStart(2, '0'):data&&data.reporting_date&&data.reporting_date&&!data.reporting_date.$d?(dateN_reporting_date.getMonth()+1).toString().padStart(2,'0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy3 =data&&data.reporting_date&&data.reporting_date&&data.reporting_date.$d?data.reporting_date.$d.getFullYear():data&&data.reporting_date&&data.reporting_date&&!data.reporting_date.$d?dateN_reporting_date.getFullYear():date1.getFullYear();




var dd4 = data&&data.acceptance_date&&data.acceptance_date&&data.acceptance_date.$d?String(data.acceptance_date.$d.getDate()).padStart(2, '0'):data&&data.acceptance_date&&data.acceptance_date&&!data.acceptance_date.$d?dateN_acceptance_date.getDate().toString().padStart(2,'0'):date1.getDate().toString().padStart(2,'0');
var mm4 =data&&data.acceptance_date&&data.acceptance_date&&data.acceptance_date.$d?String(data.acceptance_date.$d.getMonth() + 1).padStart(2, '0'):data&&data.acceptance_date&&data.acceptance_date&&!data.acceptance_date.$d?(dateN_acceptance_date.getMonth()+1).toString().padStart(2,'0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy4 =data&&data.acceptance_date&&data.acceptance_date&&data.acceptance_date.$d?data.acceptance_date.$d.getFullYear():data&&data.acceptance_date&&data.acceptance_date&&!data.acceptance_date.$d?dateN_acceptance_date.getFullYear():date1.getFullYear();


let timeN=new Date(data.start_time)
let time_end_time=new Date(data.end_time)
let time_reporting_time=new Date(data.reporting_time)
let time_acceptance_time=new Date(data.acceptance_time)




let time1=data.start_time&&data.start_time.$d?(data.start_time.$d.getHours().toString().padStart(2,'0')+':'+data.start_time.$d.getMinutes().toString().padStart(2,'0')):data.start_time&&!data.start_time.$d?timeN.getHours().toString().padStart(2,'0')+':'+timeN.getMinutes().toString().padStart(2,'0'):date1.getHours().toString().padStart(2,'0')+':'+date1.getMinutes().toString().padStart(2,'0');
let time2=data.end_time&&data.end_time.$d?(data.end_time.$d.getHours().toString().padStart(2,'0')+':'+data.end_time.$d.getMinutes().toString().padStart(2,'0')):data.end_time&&!data.end_time.$d?time_end_time.getHours().toString().padStart(2,'0')+':'+time_end_time.getMinutes().toString().padStart(2,'0'):date1.getHours().toString().padStart(2,'0')+':'+date1.getMinutes().toString().padStart(2,'0');
let time3=data.reporting_time&&data.reporting_time.$d?(data.reporting_time.$d.getHours().toString().padStart(2,'0')+':'+data.reporting_time.$d.getMinutes().toString().padStart(2,'0')):data.reporting_time&&!data.reporting_time.$d?time_reporting_time.getHours().toString().padStart(2,'0')+':'+time_reporting_time.getMinutes().toString().padStart(2,'0'):date1.getHours().toString().padStart(2,'0')+':'+date1.getMinutes().toString().padStart(2,'0');
let time4=data.acceptance_time&&data.acceptance_time.$d?(data.acceptance_time.$d.getHours().toString().padStart(2,'0')+':'+data.acceptance_time.$d.getMinutes().toString().padStart(2,'0')):data.acceptance_time&&!data.acceptance_time.$d?time_acceptance_time.getHours()+':'+time_acceptance_time.getMinutes().toString().padStart(2,'0'):date1.getHours()+':'+date1.getMinutes().toString().padStart(2,'0');
// console.log(job.job_end_time.substring(0,5),data.end_time&&data.end_time.$d?(data.end_time.$d.getHours().toString().padStart(2,'0')+':'+data.end_time.$d.getMinutes().toString().padStart(2,'0').toString().padStart(2,'0')):data.end_time&&!data.end_time.$d?time_end_time.getHours().toString().padStart(2,'0')+':'+time_end_time.getMinutes().toString().padStart(2,'0').toString().padStart(2,'0'):date1.getHours().toString().padStart(2,'0')+':'+date1.getMinutes().toString().padStart(2,'0').toString().padStart(2,'0'))

let today = yyyy+'-'+mm+'-'+dd;

let today1 = yyyy1+'-'+mm1+'-'+dd1;

let today3 = yyyy3+'-'+mm3+'-'+dd3;
let today4 = yyyy4+'-'+mm4+'-'+dd4;

var  job_start=  new Date(today)
var  job_end=  new Date(today1)
const oneDay1 = 24 * 60 * 60 *1000 ; 
let hours=parseInt((job_end-job_start) / oneDay1)+1
let days=parseInt((job_end-job_start) / oneDay1)+1

hours=hours*8
const result_total_pay=(data.hours?data.hours:0)*(data.pay_rate?data.pay_rate:0)



// console.log(data.pay_type.includes('Hour')?(result_total_pay.toString().includes('.')?result_total_pay.toFixed(2):result_total_pay):data.pay_rate)

const submitJob=(lat,lng)=>{

  if(data.job_title&&data.job_type&&data.town_name&&data.post_code&&data.company_name&&data.company_description&&data.job_description&&data.experience_years&&data.experience_months&&data.pay_type&&data.pay_rate&&data.pay_rate>10.41&&data.hours>=hours&&data.licence_category&&data.overtime>=data.pay_rate){
    // if((data.pay_type.includes('Hour')&&result_total_pay<=parseInt(auth.drivewide.credits))||(!data.pay_rate.includes('Hour')&&data.pay_rate<=parseInt(auth.drivewide.credits))){
      if((!duplicate)||(result_total_pay<=parseInt(auth.drivewide.credits))){
  
      
setData({
  ...data,processing:true
})


const formdata=new FormData()
formdata.append('job_id',duplicate?'':job.job_id)
formdata.append('created_by',auth.drivewide.business_id)


formdata.append('job_title',data.job_title)
formdata.append('job_type',data.job_type)
formdata.append('urgent_hiring',data.urgent_hiring)
formdata.append('town_name',data.town_name)
formdata.append('post_code',data.post_code)
formdata.append('company_name',data.company_name)
formdata.append('company_website',data.company_website)
formdata.append('company_description',data.company_description)
formdata.append('lat',lat)
formdata.append('lng',lng)
formdata.append('working_hours',data.hours)

formdata.append('job_description',data.job_description)
formdata.append('skills',JSON.stringify(data.skills))
formdata.append('experience_years',data.experience_years)
formdata.append('experience_months',data.experience_months)
formdata.append('start_date',today)
formdata.append('end_date',today1)
formdata.append('start_time',time1)
formdata.append('end_time',time2)
formdata.append('reporting_date',today)
formdata.append('reporting_time',time3)
formdata.append('acceptance_date',today4)
formdata.append('acceptance_time',time4)
formdata.append('total_pay',(result_total_pay.toString().includes('.')?result_total_pay.toFixed(2):result_total_pay))

formdata.append('pay_type',data.pay_type)
formdata.append('pay_rate',data.pay_rate)
formdata.append('licence_category',JSON.stringify(data.licence_category))
formdata.append('language',data.language)
formdata.append('is_active',data.is_active)

formdata.append('overtime_availability',data.overtime)


fetch(live+'jobs',
{
  method:duplicate?'POST':'PUT',
  body:formdata
}).then(resp=>resp.json()).then(res=>jobRes(res))
}
else{
  setData({
    ...data,processing:false
  })
  setOpen(true)
  setError('Low Credits, Please add credits to post job')
}

}
else{
  setData({
    ...data,processing:false
  })
  setOpen(true)
  setError('All Fields are necessary with valid data')
}
}
const jobRes=(val)=>{
//  if(val.status&&duplicate) {
//   setData({
//     ...data,processing:false
//   })
//   setOpen1(true)
//   setError(val.detail)
//   props.backPressed()
//  }
 if(val.status) {


if(duplicate)
{
  
  setAuth('drivewide',val.data,{
    maxAge: 3600 ,
    expires:0,
    path:'/'
 })
 setData({
  ...data,processing:false
})
setOpen1(true)
setError(val.detail)
props.backPressed()
}
else{
  setData({
    ...data,processing:false
  })
  setOpen1(true)
  setError(val.detail)
  props.backPressed()
}
 }
 else {
  setData({
    ...data,processing:false
  })
  setOpen(true)
  setError(val.detail)
 }
}

// console.log(data.skills.filter((v, i, a) => console.log(v,i,a)))
  const checkedActive=()=>{
    if(open2||data.is_active){
      setData({...data,is_active:!data.is_active})
      setOpen2(false)
    }
    else{
      setOpen2(true)

    }
  }

  const getLatLong=()=>{
    if(open_submit)
{
  setOpen_submit(false)

    setData({
      ...data,processing:true
    })
    
    const autocomplete1 = new window.google.maps.Geocoder();
    autocomplete1.geocode({address:data.town_name+','+data.post_code}).then((res)=>latLongRes(res)).catch((err)=>errorApi(err))
  
      // console.log('final submit')
  }
  else{
    setOpen_submit(true)

  }
}

  const errorApi=()=>{
    setData({
      ...data,processing:false
    })
    
    setError('Please enter valid Town Name and Postal code')
    setOpen(true)
  }
  const latLongRes=(res)=>{
  
    if(res.results&&res.results[0].geometry)
    {

      // setData({...data,lat:res.results[0].geometry.location.lat(),lng:res.results[0].geometry.location.lng()})
      submitJob(res.results[0].geometry.location.lat(),res.results[0].geometry.location.lng())
  
    }
    else{
      setError('Please enter valid Town Name and Postal code')
      setOpen(true)
    }
  
  
  }

  const Address=(val,val2)=>{
   if(val){
   
  
    if(val2==='town'){
      setData({
        ...data,town_name:val
      })
      setOpenA(true)
  
      setActiveA(val2)
      // setAddress(val)
      if(val.length>3)
   {   const autocomplete = new window.google.maps.places.AutocompleteService();
      autocomplete.getPlacePredictions({
        input:val
      }).then((res)=>setSuggestions(res.predictions)).catch((err)=>errorApi(err))
    }
      
    }
    else{
      setData({
        ...data,post_code:val
      })
      setOpenA(true)
      setActiveA(val2)
      // setAddress(val)
      if(val.length>3)
      { 
      const autocomplete = new window.google.maps.places.AutocompleteService();
      autocomplete.getPlacePredictions({
        input:val
      }).then((res)=>setSuggestions(res.predictions)).catch((err)=>errorApi(err))
    }}
  
  }
  else{
  
    if(val2==='town'){
      setData({
        ...data,town_name:''
      })
    }
    else{
      setData({
        ...data,post_code:''
      })
    }
    setOpenA(false)
    setActiveA(val2)
  
  }
  }
  const Address1=(val)=>{
    setOpenA(false)
    if(activeA==='town'){
      setData({
        ...data,town_name:val
      })
    }
    else{
      setData({
        ...data,post_code:val
      })
    }
  
    
  
  
  }
  
    return (
    <Container>
      <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>

  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={openS} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="info">{'Please check your available credits before submiting job'}</Alert>       
  </Snackbar>
  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>
  <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>checkedActive()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />By Publishing job editing will be disabled, Are you sure to publish
</AlertTitle>

      </Alert>
     
     
      </Snackbar>

        <Grid container style={{padding:'2vh 0px'}} >
            <Grid item sm={9} xs={9}>

{duplicate?<Typography className='job_post_head'>
Job Posting Form
</Typography>:<Typography className='job_post_head'>
Edit {job.job_title} Posting Form
</Typography>}

<Snackbar open={open_submit}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>getLatLong()}  className='btn_yes' size="small">
            Submit Job
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Submitting  will publish  job and will not be edited, confirm submission
</AlertTitle>

      </Alert>
     
     
      </Snackbar>

            </Grid>
            <Grid item sm={3} xs={3}>
            <Button  onClick={()=>props.backPressed()} style={{marginTop:'15vh'}} className='btn_evd2'>Back</Button>

            </Grid>


     {/* {!duplicate?       <Grid item alignSelf={'center'} style={{paddingLeft:'1vw'}} sm={12} xs={12}>
<FormGroup style={{alignContent:'center'}} >
      <FormControlLabel    control={<Switch  checked={data.is_active} onChange={(e)=>checkedActive(e.target.checked)}  style={{color:'var(--green)'}} />} style={{fontSize:17}} label="Publish the job" />
      
    </FormGroup>
    </Grid>:null} */}
            <Grid item sm={12} xs={12}>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <Typography style={{width:'20%'}} className='job_post_head_section' >Job Details</Typography>

          {data.job_title&&data.job_type&&data.town_name&&data.post_code&&data.company_name&&data.company_description&&data.job_description?<div style={{width:'80%',textAlign:'-webkit-right',paddingRight:'5vw'}}>
          <Typography className='completed' >Completed</Typography>
          </div>:null}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
            <Grid item sm={6} xs={12}>
                <TextField   label='Job title' placeholder='Enter job title' className='job_post_textfield' value={data.job_title} onChange={(e)=>setData({...data,job_title:e.target.value})} />
            </Grid>
            <Grid     container sm={6} xs={12}>
  
  <Grid style={{display:'flex',justifyContent:'center',alignItems:'center'}} item sm={6} xs={6}>
<Typography  style={{paddingRight:'20px',fontSize:'17px'}}>
    Job Type
</Typography>
  <Chip

label={data.job_type?data.job_type:''}
onClick={()=>Active1('job_type')}
onDelete={()=>Active1('job_type')}
deleteIcon={active1==='job_type'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  


<Grid item alignSelf={'center'} style={{paddingLeft:'1vw'}} sm={5} xs={5}>
<FormGroup style={{alignContent:'center'}} >
      <FormControlLabel    control={<Switch  checked={data.urgent_hiring} onChange={(e)=>setData({...data,urgent_hiring:e.target.checked})}  style={{color:'var(--green)'}} />} style={{fontSize:17}} label="Urgent Hiring" />
      
    </FormGroup>
    </Grid>

    <Grid item alignSelf={'center'} textAlign='start'  sm={1} xs={1}>

    <Tooltip title="Urgent Hiring includes hiring within 12 hours">
      <IconButton>
        <QuestionMarkIcon />
      </IconButton>
    </Tooltip>
    </Grid>
    {active1==='job_type'?<div style={{width:'100%',display:'flex'}}>
  <div className="filter_values">
<ul>
 {Job_type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}


 
 
</ul>
  </div>

</div>:null}
  </Grid>

          
  <Grid item sm={6} xs={12}>
                <TextField  value={data.town_name} onChange={(e)=>Address(e.target.value,'town')}  label='Town Name'  placeholder='Enter town name' className='job_post_textfield' />
                
{suggestions&&suggestions[0]&&openA&&activeA==='town'?<div  style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:'100%',alignSelf:'start',alignContent:'start',borderRadius:5,maxHeight:400,overflowY:'scroll'}}>
{suggestions.map((suggestion,idx)=>(

  <Grid onClick={()=>Address1(suggestion.structured_formatting.main_text)} key={idx}  className='cont_search' container>

<Grid item  sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='main_txt'>{suggestion.structured_formatting.main_text}</Typography>
  </Grid>
 <Grid item sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='sub_txt'>{suggestion.description}</Typography></Grid>
  
 
  
  </Grid>)
)}  
</div>:null}  
            </Grid>

            <Grid item sm={6} xs={12}>
                <TextField value={data.post_code} onChange={(e)=>Address(e.target.value,'code')}   label='Post Code' placeholder='Enter post code' className='job_post_textfield' />
            
                {suggestions&&suggestions[0]&&openA&&activeA==='code'?<div  style={{ marginTop:20,background:'whitesmoke',textAlign:'start',width:'100%',alignSelf:'start',alignContent:'start',borderRadius:5,maxHeight:400,overflowY:'scroll'}}>
{suggestions.map((suggestion,idx)=>(

  <Grid onClick={()=>Address1(suggestion.structured_formatting.main_text)} key={idx}  className='cont_search' container>

<Grid item  sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='main_txt'>{suggestion.structured_formatting.main_text}</Typography>
  </Grid>
 <Grid item sm={12} xs={12} style={{textAlign:'start',alignItems:'start',alignContent:'start',width:'100%'}}>
  <Typography className='sub_txt'>{suggestion.description}</Typography></Grid>
  
 
  
  </Grid>)
)}  
</div>:null}
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField value={data.company_name} onChange={(e)=>setData({...data,company_name:e.target.value})} label='Company Name' placeholder='Enter company name' className='job_post_textfield' />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField value={data.company_website} onChange={(e)=>setData({...data,company_website:e.target.value})}   label='Company Website' placeholder='Enter company website' className='job_post_textfield' />
            </Grid>


            <Grid container style={{paddingTop:'1vh'}} sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Typography style={{textAlign:'center',padding:'1vh 0px',fontSize:'17px'}} className='text1_evd'>
Company Description  
     </Typography>
</Grid>
     {<MyEditorS quillChange={quillChange} editor={editor}  />}

     </Grid> 
   
     </Grid> 
     <Grid container style={{paddingTop:'1vh'}} sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Grid item sm={12} xs={12}>
     <Typography style={{textAlign:'center',padding:'1vh 0px',fontSize:'17px'}} className='text1_evd'>
Job Description  
     </Typography>
</Grid>
     {<MyEditorS2 quillChange1={quillChange1} editor1={editor1}  />}

     </Grid> 
   
     </Grid> 
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{width:'20%'}} className='job_post_head_section' >Skills &amp; Experience</Typography>
          {data.skills&&data.skills[0]&&data.licence_category&&data.licence_category[0]&&data.experience_years&&data.experience_months&&data.language?<div style={{width:'80%',textAlign:'-webkit-right',paddingRight:'5vw'}}>
          <Typography className='completed' >Completed</Typography>
          </div>:null}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
            <Grid item sm={6} xs={12}>
                <TextField type={'number'}value={data.experience_years} onChange={(e)=>setData({...data,experience_years:e.target.value})}  label='Experience (Years)' placeholder='Enter years of experience' className='job_post_textfield' />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField type={'number'} value={data.experience_months} label='Experience (Months)' onChange={(e)=>setData({...data,experience_months:e.target.value})} placeholder='Enter months of experience' className='job_post_textfield' />
            </Grid>
            <Grid container sm={6} xs={12}>
                <Grid item sm={4} xs={9}>
                <TextField  onChange={(e)=>setData({...data,skill:e.target.value})}  label='Skills' placeholder='Enter skill required' className='job_post_textfield' />

                </Grid>
                <Grid item style={{alignSelf:'center'}} sm={4} xs={9}>
<Chip label={'Add Skill'} className='job_post_chipp_add' onClick={()=>addSkills()} />

                </Grid>
                <Grid item sm={12} xs={12}>
{data.skills?data.skills.map((val,idx)=>
  <Chip style={{margin:'1vh 16px 1vh 0px'}} key={idx} label={val.skill} className='job_post_chipp_add' onDelete={()=>setData({...data,skills:data.skills.filter(x=>x.skill!==val.skill)})}
 deleteIcon={<HighlightOffIcon style={{color:'var(--blueColor)'}}/>}  />)
:null}
            </Grid>
                <Grid  style={{alignSelf:'center',padding:'2vh 0px',padding:'2vh 0px'}}   item sm={12} xs={12}>
  
  <Grid style={{display:'flex',alignItems:'center'}} item sm={12} xs={12}>
<Typography  style={{marginRight:'20px',fontSize:'17px'}}>
Language proficiency</Typography>
  <Chip

label={data.language?data.language:''}
onClick={()=>Active1('language')}
onDelete={()=>Active1('language')}
deleteIcon={active1==='language'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='language'?<div style={{width:'100%',display:'flex'}}>
  <div className="filter_values">
<ul>
 {Language_P.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
                <Grid item sm={12} xs={12}>

                  <Typography style={{fontSize:'17px',padding:'1vh 0px'}}>Licence Category</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
<FormGroup row  onChange={(e)=>drivingCheckbox(e.target)}>
{Driving_category.map((val1,idx)=>
  <FormControlLabel key={idx}      disabled={!duplicate}
 control={<Checkbox  checked={data.licence_category&&data.licence_category[0]&&data.licence_category.find(val=>val.category==val1.name)?true:false} style={{color:'var(--green)'}} />} label={val1.name} value={val1.name} name={val1.value}  />

)      
}    </FormGroup>
                </Grid>
            </Grid>

       
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{width:'20%'}} className='job_post_head_section' >Timings &amp; Schedule</Typography>
          {data.start_date&&data.end_date&&data.start_time&&data.end_time&&data.reporting_time&&data.acceptance_date&&data.acceptance_time?<div style={{width:'80%',textAlign:'-webkit-right',paddingRight:'5vw'}}>
          <Typography className='completed' >Completed</Typography>
          </div>:null}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
            <Grid item style={{margin:'1vh 0px'}} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      disabled={!duplicate}
        label="Job Duration (Start Date)"
        value={data.start_date}
        inputFormat='DD/MM/YYYY'
        onChange={(newValue) => {
          setData({...data,start_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>            </Grid>
    <Grid item style={{margin:'1vh 0px'}} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
            disabled={!duplicate}
minDate={data.start_date}
        label="Job Duration (End Date)"
        value={data.end_date}
        inputFormat='DD/MM/YYYY'

        onChange={(newValue) => {
          setData({...data,end_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid>

       <Grid item sm={6} xs={12}style={{margin:'1vh 0px'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                  disabled={!duplicate}

        label="Job Duration (Start Time)"
        value={data.start_time}
        onChange={(newValue) => {
          setData({...data,start_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid>
       
       <Grid item sm={6} xs={12}style={{margin:'1vh 0px'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                  disabled={!duplicate}
                  minTime={(data.start_date.toISOString()==data.end_date.toISOString())?data.start_time:null}
                  label="Job Duration (End Time)"
        value={data.end_time}
        onChange={(newValue) => {
          setData({...data,end_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid>
 
       {/* <Grid item style={{margin:'1vh 0px'}} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'

        label="Job Reporting Date"
        value={data.reporting_date}
        
        onChange={(newValue) => {
          setData({...data,reporting_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid> */}
       <Grid item sm={6} xs={12}style={{margin:'1vh 0px'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                  disabled={!duplicate}
maxTime={data.start_time}
        label="Job Reporting Time"
        value={data.reporting_time}
        onChange={(newValue) => {
          setData({...data,reporting_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid>

       <Grid item style={{margin:'1vh 0px'}} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
            disabled={!duplicate}

              inputFormat='DD/MM/YYYY'
        maxDate={data.start_date}
        label="Last Acceptance Date"
        value={data.acceptance_date}
        
        onChange={(newValue) => {
          setData({...data,acceptance_date:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid>
       <Grid item sm={6} xs={12}style={{margin:'1vh 0px'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                  disabled={!duplicate}

        label="Last Acceptance Time"
        value={data.acceptance_time}
        onChange={(newValue) => {
          setData({...data,acceptance_time:newValue});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>         
       </Grid>

          </Grid>
        </AccordionDetails>
      </Accordion>

      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{width:'20%'}} className='job_post_head_section' >Pay Rate</Typography>
          {data.hours&&data.hours>7.99&&data.pay_rate&&data.pay_rate>=data.pay_rate_c&&data.overtime>=data.pay_rate?<div style={{width:'80%',textAlign:'-webkit-right',paddingRight:'5vw'}}>
          <Typography className='completed' >Completed</Typography>
          </div>:null}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
          <Grid  style={{alignSelf:'center'}}   item sm={12} xs={12}>
  
  <Grid style={{display:'flex',alignItems:'center'}} item sm={12} xs={12}>
<Typography  style={{paddingRight:'20px',fontSize:'17px'}}>
Pay Type</Typography>
  <Chip
      disabled={!duplicate}

label={data.pay_type?data.pay_type:''}
onClick={()=>Active1('pay_type')}
onDelete={()=>Active1('pay_type')}
deleteIcon={active1==='pay_type'?<HighlightOffIcon style={{color:'var(--blueColor)'}} />:<ArrowDropDownIcon style={{color:'var(--blueColor)'}}/>} 
className='chip_drop'
/>
  </Grid>
  
{active1==='pay_type'?<div style={{width:'100%',display:'flex'}}>
  <div className="filter_values">
<ul>
 {Pay_type.map((val,idx)=>
  <li onClick={()=>dropDownVal(val)} key={idx} className="list_filter">
  {val}
  </li>) 
}
 
 
</ul>
  </div>

</div>:null}


  </Grid>
{/* {data.pay_type?  <Grid item sm={6} xs={12}>
                <TextField value={data.pay_rate}  onChange={(e)=>setData({...data,pay_rate:e.target.value})}  type={'number'} label={`${data.pay_type} rate`} placeholder={`Enter ${data.pay_type} rate`} className='job_post_textfield' />
            </Grid>:null}
         
{data.pay_rate&&data.pay_type&&data.pay_type.includes('Hour')?<Grid item sm={6} xs={12}>
<Typography  style={{paddingRight:'20px',fontSize:'17px'}}>
Total Job Amount : <span style={{color:'var(--green)',fontWeight:'700'}}>£{result_total_pay.toString().includes('.')?result_total_pay.toFixed(2):result_total_pay}{data.pay_type&&data.pay_type.includes('Hour')?(` (${hours.toString().includes('.')?hours.toFixed(2):hours} Hours)`):''}</span></Typography>
            </Grid>:null} */}
            {data.pay_type?  <Grid item sm={6} xs={12}>
                <TextField       disabled={!duplicate}
 error={data.pay_rate&&data.pay_rate<data.pay_rate_c?true:false} helperText={data.pay_rate&&data.pay_rate<data.pay_rate_c?`Minimum £${data.pay_rate_c}/hr rate required`:''} value={data.pay_rate}  onChange={(e)=>setData({...data,pay_rate:e.target.value})}  type={'number'} label={`Hourly rate`} placeholder={`Enter hourly rate`} className='job_post_textfield' />
            </Grid>:null}
            {data.pay_type?  <Grid item sm={6} xs={12}>
            <TextField       disabled={!duplicate}
 error={data.hours&&data.hours<hours?true:false} helperText={data.hours&&data.hours<hours?`Minimum 8 working hrs/day required`:''} value={data.hours} onChange={(e)=>setData({...data,hours:e.target.value})}  type={'number'} label={`Working hours ${days>1?(`(for ${days} days)`):''}`} placeholder={`Enter working hours`} className='job_post_textfield' />
            </Grid>:null}

            {/* {data.pay_rate&&data.pay_type&&data.pay_type.includes('Hour')? */}
            <Grid item sm={6} xs={12}>
            {data.hours&&data.hours>=hours&&data.pay_rate&&data.pay_rate>=data.pay_rate_c?<Typography  style={{paddingRight:'20px',fontSize:'17px'}}>
{/* Total Job Amount : <span style={{color:'var(--green)',fontWeight:'700'}}>£{result_total_pay.toString().includes('.')?result_total_pay.toFixed(2):result_total_pay}{data.pay_type&&data.pay_type.includes('Hour')?(` (${hours.toString().includes('.')?hours.toFixed(2):hours} Hours)`):''}</span> */}
Total Job Amount : <span style={{color:'var(--green)',fontWeight:'700'}}>£{result_total_pay.toString().includes('.')?result_total_pay.toFixed(2):result_total_pay}{(` (${data.hours} Hours)`)}</span>

</Typography>:null}
            </Grid>

   
    
    <Grid item sm={6} xs={12}>
                <TextField  disabled={!duplicate} value={data.overtime} error={data.pay_rate&&data.overtime<data.pay_rate?true:false} helperText={data.pay_rate&&data.overtime<data.pay_rate?`Minimum £${data.pay_rate}/hr rate required`:''}   onChange={(e)=>setData({...data,overtime:e.target.value})} type='number'  label={`Overtime Availability rate`} placeholder={`Enter overtime rate`} className='job_post_textfield' />
            </Grid>
    
    
          </Grid>
        </AccordionDetails>
      </Accordion>
            </Grid>
            <Grid item style={{textAlign:'center'}} sm={12} xs={12}>
                <Chip 
                // disabled={data.processing} 
                 onClick={()=>getLatLong()} className='submit_job' label={data.processing?'Submitting':duplicate?'Submit Job':'Save'}/>
            </Grid>

        </Grid>
    </Container>
  )
}

