import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/common/header/header';
import Homepage from './components/homepage/homepage';
import Footer from './components/common/footer/footer';
import How_It_Works from './components/how_it_works/HIW';
import ContactUS from './components/contactus/contactus';
import SignIN from './components/authentication/signin';
import ScrollT from './components/common/STTP/scroll_Top';
import Dashboard from './components/dashboard/dashboard';
import Pricing from './components/pricing/pricing';
import Terms_conditions from './components/privacypolicy/Terms&Conditions';
import Privacy_policy from './components/privacypolicy/Privacy&policy';
import Job_View from './components/job_view/job_view';

// export const live='http://192.168.1.2:8000/'

// export const live='http://127.0.0.1:8000/'
export const live='https://drivewide.herokuapp.com/'

// export const live='https://d6-prelive.herokuapp.com/'
export const imgLink='https://drivewide.s3.eu-west-2.amazonaws.com/drivewide/'


function App() {
// console.log(window.location.pathname)
  return (
<BrowserRouter>
<Header />
<Routes>
<Route path='/' element={<Homepage />}/>
<Route path='/how-it-works' element={<How_It_Works />}/>
<Route path='/contact' element={<ContactUS />}/>
<Route path='/signin' element={<SignIN />}/>
<Route path='/dashboard' element={<Dashboard />}/>
<Route path='/for_business' element={<Pricing />}/>

<Route path='/terms_conditions' element={<Terms_conditions />}/>
<Route path='/privacy_policy' element={<Privacy_policy />}/>
<Route path='/jobs/:id' element={<Job_View />} />




</Routes>
{window.location.pathname.includes('/dashboard')?null:<Footer />}
<ScrollT />
</BrowserRouter>
  );
}

export default App;
