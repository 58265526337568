import { Grid, Typography ,Container} from '@mui/material'
import React from 'react'
import './HIW_below_bg.css'
export default function HIW_below_bg() {
  return (
<>    <Container>
        <Grid container>
            <Grid item sm={12} xs={12}>
                <Typography className='HIW_below_text'>
                <span style={{color:'var(--green)',fontWeight:'600'}}>DriveWide </span> 
                {/* makes managing your fleets driving simple and secure.
Save time spent on administrative activities and give your drivers the convenience of using our top rated mobile driving app, resulting in less stress and more business. */}
makes your driver engagement simple and secure. 
Save time and cost spent on administrative activities and give your drivers the convenience of using our top rated mobile driving app, 
so you can make best use of your time on other business priorities.

                </Typography>
            </Grid>
        </Grid>

        <Grid container>
            <Grid item sm={12} xs={12}>
                <Typography className='HIW_below_Works_head'>
                    HOW IT WORKS FOR DRIVERS
                </Typography>

            </Grid>

            <Grid item style={{alignSelf:'center'}} sm={5} xs={12}>
            <img   src={require('../../../images/Post.png')} className='below_home_map_phone' />

            </Grid>
            <Grid container sm={7} xs={12}>
                <Grid container style={{alignContent:'flex-start'}} sm={12} xs={12}>
                 <Grid item sm={12} xs={12}>
                 <img   src={require('../../../images/icon.png')} className='HIW_below_Works_img' />
 
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_head1'>
 
 1. Download the DriveWide app
 </Typography>
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_text'>
 
 Download the app now from the <a href='https://play.google.com/store/apps/details?id=com.drivewide.drivewide' target='_blank' style={{textDecoration:'none',color:'var(--green)'}}>Google Play</a> and <a href='https://apps.apple.com/app/drivewide/id6447601344'target='_blank' style={{textDecoration:'none',color:'var(--green)'}}>iOS App Store</a>.
 </Typography>
                    </Grid> 

                </Grid>
                <Grid container style={{alignContent:'flex-start'}} sm={12} xs={12}>
                 <Grid item sm={12} xs={12}>
                 <img   src={require('../../../images/verify.png')} className='HIW_below_Works_img' />
 
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_head1'>
 
 2. Register &amp; Verify 
 </Typography>
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_text'>
 Register and update your profile; so DriveWide can verify your details instantly and you're ready to go!
  </Typography>
                    </Grid> 

                </Grid>
                <Grid container style={{alignContent:'flex-start'}} sm={12} xs={12}>
                 <Grid item sm={12} xs={12}>
                 <img   src={require('../../../images/job.png')} className='HIW_below_Works_img' />
 
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_head1'>
 
 3. Apply For Jobs 
 </Typography>
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_text'>
 Apply for your preferred driving jobs from the wide pool of availabe jobs.
  </Typography>
                    </Grid> 

                </Grid>
                <Grid container style={{alignContent:'flex-start'}} sm={12} xs={12}>
                 <Grid item sm={12} xs={12}>
                 <img   src={require('../../../images/report.png')} className='HIW_below_Works_img' />
 
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_head1'>
 
 4.  Respond to prompts
 </Typography>
                    </Grid>   
                    <Grid  item sm={12} xs={12}>
 <Typography className='HIW_below_Works_text'>
 Drivers will receive various notifications throughout after applying for a job.
  </Typography>
                    </Grid> 

                </Grid>
            </Grid>

        </Grid>

        <Grid container >
            <Grid item sm={12} xs={12}>
                <Typography style={{textTransform:'uppercase',textAlign:'center',paddingTop:'5vh'}} className='HIW_below_Works_head'>
                How it works for businesses
                </Typography>
            </Grid>
            <Grid container style={{paddingTop:'5vh',paddingBottom:'5vh'}}>
            <Grid item sm={6} xs={12}>
            <img   src={require('../../../images/business1.png')} className='below_home_FB_img' />
            </Grid>
            <Grid container style={{alignContent:'center'}} sm={6} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_head'>
    Create, post & manage your jobs.
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_text'>
    Instantly create and post driving jobs, thereafter track and manage.
        </Typography>
</Grid>
</Grid>
            </Grid>
           
        </Grid>

        
    </Container>

    <Container maxWidth={'100%'}style={{background:'#eee',textAlign:'-webkit-center'}}>
    <Grid container style={{paddingTop:'5vh',paddingBottom:'5vh',maxWidth:'1200px'}}>
            
            <Grid container style={{alignContent:'center'}} sm={6} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_head'>
    Dashboards that provide real-time monitoring. 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_text'>
    Get meaningful and actionable insights delivered on-demand.     </Typography>
</Grid>
</Grid>
<Grid item sm={6} xs={12}>
            <img   src={require('../../../images/business2.png')} className='below_home_FB_img' />
            </Grid>
            </Grid>
    </Container>
<Container>
    <Grid container style={{paddingTop:'5vh',paddingBottom:'5vh'}}>
            <Grid item sm={6} xs={12}>
            <img   src={require('../../../images/business4.png')} className='below_home_FB_img' />
            </Grid>
            <Grid container style={{alignContent:'center'}} sm={6} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_head'>
    Personalised plans for every type of business.
        </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_text'>
    Select a suitable plan depending on your business needs.
    {/* Provide your drivers with preferred rates based on their eligibility and permissions. Easily accommodate special access for residents or city staff, and validate the status of user groups such as the disabled parkers or car shares.     */}
     </Typography>
</Grid>
</Grid>
            </Grid>
            </Container>


            {/* <Container maxWidth={'100%'}style={{background:'#eee',textAlign:'-webkit-center'}}>
    <Grid container style={{paddingTop:'5vh',paddingBottom:'5vh',maxWidth:'1200px'}}>
            
            <Grid container style={{alignContent:'center'}} sm={6} xs={12}>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_head'>
    Dashboards that provide real-time monitoring. 
    </Typography>
</Grid>
<Grid item sm={12} xs={12}>
    <Typography className='below_home_FB_text'>
    Get meaningful and actionable insights delivered on-demand.     </Typography>
</Grid>
</Grid>
<Grid item sm={6} xs={12}>
            <img   src={require('../../../images/business2.png')} className='below_home_FB_img' />
            </Grid>
            </Grid>
    </Container> */}
    </>
  )
}
