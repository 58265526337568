import { Accordion, AccordionSummary, Button, Grid, Typography ,AccordionDetails, Box} from '@mui/material'
import React from 'react'
import { Payments_data } from './data'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DataGrid } from '@mui/x-data-grid'
import {useCookies} from 'react-cookie'

import { live } from '../../App'
export default function Payments(props) {
  
  
  

  
  

  const generatePDF =(val)=>{
    // setProcessing(true)
    window.open(live+`invoices?invoice=${val}`)
    // fetch(live+`subs?invoice=${val}`,{
    // }).then(resp=>downloadRes(resp))

  }
const downloadRes=(val)=>{
  console.log(val.data)
  // let url = window.URL.createObjectURL(new Blob([val.data]));
  // let link = document.createElement("a");
  // link.href = url;
  // window.open(link,'_blank')
  // console.log(link)
}
  
  
  
  
  
  
  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])

  const transactions=props.transactions
  const payments=props.payments



  const renderType = (val) => {
    // console.log(val.row.published_on)
    
      return (
          <strong>
                { val.row.type==='Credited'?<Button className='btn_evd2'>Credit</Button>:<Button className='btn_evd3'>Debit</Button>}


          </strong>
      )
    }
  const rows= transactions?
  transactions.map((item,k) => {
    return  {
     reference_no:item.reference_no,

      id:k+1,
      amount:'£'+(item.amount),
      added_on:((new Date(item.added_on).getDate()).toString().padStart(2, '0')+'/'+(new Date(item.added_on).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(item.added_on).getFullYear())+' '+(new Date(item.added_on).getHours().toString().padStart(2, '0')+':'+new Date(item.added_on).getMinutes().toString().padStart(2, '0')),
      type:item.type,
     
      




      



     
    }
}
  ):''
const columns = [
  { field: 'id', headerName: 'S No.', width: 60 },
  { field: 'reference_no', headerName: 'Reference No.',width:200},

  {
    field: 'amount',
    headerName: 'Amount',
    width:180,
   
  },
  {
    field: 'added_on',
    headerName: 'Paid On',
      width:180,


  
  },

    {
            field: 'type',
            headerName: ' ',
                  width:180,

            sortable:false,
            filterable:false,          
              renderCell: renderType,
             },



  

 
];
  return (

    <div style={{paddingBottom:'5vh',textAlign:'-webkit-center',width:'100%'}}>
      
        {transactions?
        
        (   <Grid container style={{maxWidth:'1200px',justifyContent:'center'}}>
          {transactions&&transactions[0]?
          <Box  className='box_transactions' >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={[15]}
                  rowsPerPageOptions={[15]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>:
                  <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
          
              <Typography className='txt_evd'>
                No Transactions Found
              </Typography>
              </Grid>}
               
            
          
          </Grid>  )
        :
        
        
        
   (payments&&payments.credits_history&&payments.credits_history.filter(val=>val.type==='Credited')[0]?
  ( payments.credits_history.filter(val=>(val.type==='Credited')&&(!val.reference_no.includes('JB-'))).map((val,idx)=>
    <Accordion key={idx}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography style={{fontWeight:'700',color:'var(--green)',fontSize:'17px'}} >Payment No {idx+1}</Typography>
    </AccordionSummary>
    <AccordionDetails>
    
    <Typography style={{textAlign:'left'}}>
     Payment ID: {val.reference_no}
    </Typography>
  
  </AccordionDetails>

    <AccordionDetails>
     
      <Typography style={{textAlign:'left'}}>
       Amount: £{val.amount} 
      </Typography>
   
    </AccordionDetails>
  
    <AccordionDetails>
    <Typography>
      <Grid container>
      <Grid item>
      Paid On: {(new Date(val.added_on).getDate()).toString().padStart(2, '0')+'/'+(new Date(val.added_on).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(val.added_on).getFullYear()} {new Date(val.added_on).getHours().toString().padStart(2, '0')+':'+new Date(val.added_on).getMinutes().toString().padStart(2, '0')}

      </Grid>
      <Grid style={{alignSelf:'center'}} item>
      <Button  variant="outlined" onClick={()=>generatePDF(val.reference_no)}  style={{backgroundColor:'var(--green)',color:'white',height:'25px',fontSize:'12px',alignItems:'center',marginLeft:'30px'}}>
          Download 
          </Button>

      </Grid>
       </Grid>
      </Typography>
    </AccordionDetails>
  </Accordion>
    ) ): <Grid sm={12} style={{textAlign:'center',justifyContent:'center'}} xs={12} item>
          
    <Typography className='txt_evd'>
      No Payments Found
    </Typography>
    </Grid> )

    } 
    </div>
  )
}
