import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { live } from '../../App';
import { Dummy_Data } from './data';


import './jobs.css'
import Job_post from './job_post';
import Job_post_Edit from './job_post_edit';
export default function Jobs_history(){
   const [processing,setProcessing]=useState(false)
   const [jobs,setJobs]=useState([])
   const [duplicate,setDuplicate]=useState(false)
   const [edit,setEdit]=useState(false)
   const[errMsg,setError]=useState(false)
   const [open,setOpen]=useState(false)
   const [open1,setOpen1]=useState(false)
   const [open2,setOpen2]=useState(false)
   const [del,setDel]=useState(false)
   const [job_id,setJobID]=useState('')
   const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
   const [search,setSearch]=useState('')

   const [reload,setReload]=useState(0)
    // useEffect(() => {
    //   setProcessing(true)
    //     fetch(live+'jobs').then((res)=>res.json()).then((resp)=>jobsResp(resp))
    //     }, [reload])

    useEffect(() => {
      setProcessing(true)
      const formData = new FormData()
      formData.append('driver_id', auth.drivewide.driver_id)
      fetch(live + 'jobs-app', {
        method: 'PUT',
        body: formData
      }
  
      ).then(res => res.json()).then(resp => jobsRes(resp))
    }, [reload])
  
  
    const jobsRes = (val) => {
      if (val.status) {
        setProcessing(false)
        setJobs(val.completed)
      }
  
    }
    // const jobs=Dummy_Data
        // const jobsResp=(val)=>{
        //         setProcessing(false)
        //         setJobs(val.data)
            
            
            

        // }


const backPressed=()=>{
    setProcessing(true)
setDuplicate(false)
    setReload(reload+1)
    setEdit(!edit)
}

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setJobID('')


}

 
const renderID = (val) => {
  return (
      <strong>
        <a style={{color:'var(--green)'}} href={`/jobs/${val.row.job_id}`} target='_blank' >{val.row.job_id}</a>
      </strong>
  )
} 
       
        const rows= jobs&&jobs[0]&&!search?(jobs.map((item,k) => {
            return  {
             job_id:item.job_id,
        
              id:k+1,
              job_title:item.job_title,
              credits:'£ '+item.completed[0].credits,
              company:item.company_name,
              completed_on:((new Date(item.completed[0].completed_on).getDate()).toString().padStart(2, '0')+'/'+(new Date(item.completed[0].completed_on).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(item.completed[0].completed_on).getFullYear())+(' '+new Date(item.completed[0].completed_on).getHours().toString().padStart(2, '0')+':'+new Date(item.completed[0].completed_on).getMinutes().toString().padStart(2, '0'))
              

        
        
        
              
        
        
        
             
            }
        }
          )):jobs&&jobs[0]&&search&&search.length>2&&jobs.filter(val=>(val.job_id.toLowerCase().includes(search.toLowerCase())||val.company_name.toLowerCase().includes(search.toLowerCase())||val.job_title.toLowerCase().includes(search.toLowerCase())))&&jobs.filter(val=>(val.job_id.toLowerCase().includes(search.toLowerCase())||val.company_name.toLowerCase().includes(search.toLowerCase())||val.job_title.toLowerCase().includes(search.toLowerCase())))[0]?jobs.filter(val=>(val.job_id.toLowerCase().includes(search.toLowerCase())||val.company_name.toLowerCase().includes(search.toLowerCase())||val.job_title.toLowerCase().includes(search.toLowerCase()))).map((item,k) => {
            return  {
             job_id:item.job_id,
        
              id:k+1,
              job_title:item.job_title,
              credits:'£ '+item.completed[0].credits,
              company:item.company_name,
              completed_on:((new Date(item.completed[0].completed_on).getDate()).toString().padStart(2, '0')+'/'+(new Date(item.completed[0].completed_on).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(item.completed[0].completed_on).getFullYear())+(' '+new Date(item.completed[0].completed_on).getHours().toString().padStart(2, '0')+':'+new Date(item.completed[0].completed_on).getMinutes().toString().padStart(2, '0'))
              

        
        
        
              
        
        
        
             
            }
        }
          ):''
        const columns = [
          { field: 'id', headerName: 'S No.', width: 60 },
          { field: 'job_id', headerName: 'Job ID', renderCell: renderID,
        },        
          {
            field: 'job_title',
            headerName: 'Job Title',
            width:180,
           
          },
          {
            field: 'company',
            headerName: 'Company',
          sortable:false,
          filterable:false
          
          },
        
          {
            field: 'credits',
            headerName: 'Credits',
            width: 180,  

            // type: 'number',
          }, {
            field: 'completed_on',
            headerName: 'Completed On',
            // type: 'number',
            width: 180,  

          },
        
        
          
        
         
        ];



    return(


    
        <Grid container style={{textAlign:'start'}}>
 <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>


      {!edit? <Grid container style={{alignItems:'center',marginBottom:'5vh',textAlign:'center',justifyContent:'center'}} >
<Grid item sm={9} xs={8}>
<TextField placeholder='Search jobs here ..' className='field_search'onChange={(e)=>setSearch(e.target.value)} />
</Grid>
{/* <Grid item sm={3} xs={4}>
<Button onClick={()=>setEdit(true)}  variant='contained' className='btn_new'>Create New Job</Button>
</Grid> */}
<Grid container>
{(jobs&&jobs[0]&&!processing&&!edit&&!search&&rows[0])||(jobs&&jobs[0]&&!processing&&!edit&&search&&search.length>2&&rows[0])?
<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:!jobs[0]&&!processing||!rows&&!processing?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Jobs Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>

       </Grid>:
       jobs&&jobs[0]&&!processing&&edit&&edit.job_id? <Job_post_Edit duplicate={duplicate} edit={edit} backPressed={backPressed} />:
       
       <Job_post backPressed={backPressed} />}
       </Grid>
    


)
}