export  const Job_type=[
    'Permanent','Temporary'
]
export  const Pay_type=[
    'Hourly',
]

export  const Language_P=[
    'Basic','Fluent','Native'
]

export const Dummy_Data=[
    {
        'job_id':'JB-00001',
        'job_title':'Inter City Driver',
        'credits':'£70',
        'company':'Drive Wide',
        'completed_on':'18-02-2023'

    }
]

export const Payments_data=[
    {
        'payment_id':'PM-00124',
        'email':'Abcd@gmail.com',
        'created_on':'20-02-2023',
        'amount':300,


    },  {
        'payment_id':'PM-00125',
        'email':'Abcd@yahoo.com',
        'created_on':'19-02-2023',
        'amount':800,
        

    }
]

export const Company_type=['Sole trader', 'Partnership', 'Limited Liability Partnership', 'Private Limited Company',' Limited Company', 'Public Limited Company']

export const Driving_category=[
  {
    name:'AM',
    value:1,
    pay_rate:'10.42'
},
{
    name:'A1',
    value:2,
    pay_rate:'10.42'

},{
    name:'A2',
    value:3,
    pay_rate:'10.42'

},{
    name:'A',
    value:4,
    pay_rate:'10.42'

},{
    name:'B1',
    value:5,
    pay_rate:'12'

},{
    name:'B',
    value:6,
    pay_rate:'12'

},{
    name:'C1',
    value:7,
    pay_rate:'16'

},{
    name:'C',
    value:8,
    pay_rate:'16'

},{
    name:'D1',
    value:9,
    pay_rate:'16'

},
{
    name:'D',
    value:10,
    pay_rate:'16'

},{
    name:'BE',
    value:11,
    pay_rate:'12'

},{
    name:'C1E',
    value:12,
    pay_rate:'19'

},{
    name:'CE',
    value:13,
    pay_rate:'19'

},{
    name:'D1E',
    value:14,
    pay_rate:'16'

},{
    name:'DE',
    value:15,
    pay_rate:'16'

},

]

export const Dummy_business=[{
    "business_id": "BM-00002",
    "fname": "Abhishek",
    "lname": "Sharma",
    "mobile": "7897897777",
    "work": null,
    "country_code": "+44",
    "email": "a.sharma@dec6.in",
    "password": "Drive123",
    "address_line1": "123, Kensington,",
    "address_line2": "London",
    "city": "London",
    "stat": "London",
    "country": "India",
    "zip_code": "E1W 23P",
    "company_type": "Private Limited Company",
    "company_name": "Dec6 Consulting Private Limited",
    "company_number": "U83123088877",
    "vat_registration_number": "AAHCD91234dC",
    "is_active": false,
    "is_verified": false,
    "type": "Business",
    "created_on": {
      "$date": "2023-02-17T00:00:00.000Z"
    },
    "modified_on": {
      "$date": "2023-02-25T00:00:00.000Z"
    },
    "is_admin": false,
    "criminal_record": "No",
    "criminal_record_text": null,
    "amount": "5.0",
    "customer_id": "cus_NbhqNgVTKDGKnZ",
    "payment_intervals": "1",
    "plan_type": "day",
    "renewal_on": {
      "$date": "2023-03-29T04:46:53.000Z"
    },
    "session_id": "cs_test_a1MLKd0qDmr6XXpO6IgeQJUMIY73eOTEp4blpuRteZKq9BFICStV9nox3d",
    "subscription_active": true,
    "subscription_id": "sub_1MqUQrH416pJc0En2xVCbzYN",
    "subscription_start": {
      "$date": "2023-03-28T04:46:53.000Z"
    },
    "credits": "140.0",
    "credits_history": [
      
      {
        "reference_no": "JB-00019",
        "amount": "13",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-30T06:16:17.845Z"
        }
      },
      {
        "reference_no": "JB-00020",
        "amount": "12",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-30T06:36:37.575Z"
        }
      },
      {
        "reference_no": "JB-00021",
        "amount": "5",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-31T07:48:35.313Z"
        }
      },
      {
        "reference_no": "JB-00022",
        "amount": "4",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-31T07:49:51.364Z"
        }
      },
      {
        "reference_no": "JB-00023",
        "amount": "6",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-31T07:50:52.492Z"
        }
      },
      {
        "reference_no": "JB-00024",
        "amount": "2",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-31T07:54:00.775Z"
        }
      },
      {
        "reference_no": "JB-00025",
        "amount": "1",
        "type": "Debited",
        "added_on": {
          "$date": "2023-03-31T07:54:59.219Z"
        }
      },
      {
        "reference_no": "pi_3MrdLkH416pJc0En1dhclBEI",
        "amount": "50.0",
        "type": "Credited",
        "added_on": {
          "$date": "2023-03-31T08:30:26.592Z"
        }
      },
      {
        "reference_no": "pi_3MrgG4H416pJc0En1ten2oW3",
        "amount": "50.0",
        "type": "Credited",
        "added_on": {
          "$date": "2023-03-31T11:36:46.382Z"
        }
      },
      {
        "reference_no": "pi_3MrgH0H416pJc0En1WfHhzRJ",
        "amount": "40.0",
        "type": "Credited",
        "added_on": {
          "$date": "2023-03-31T11:37:43.656Z"
        }
      }
    ],
    "token": null
  }]