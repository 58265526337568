import React,{useState} from 'react';

import Grid from '@mui/material/Grid';
import './header.css'
// import LOGO from "../../../images/HappR_trans.svg"
import Navbar from './navbar';
import { Typography } from '@mui/material';
import TemporaryDrawer from './D6_Sl_Drawer';
function Header() {
       
    const [Dec6_Navbar_Items1,setNavbar] = useState(false);
    const [open, setOpen] = useState(false)

    const Toggle=()=>{
        setOpen(!open)
      }
    const changeBackground = () => {
        if(window.scrollY >= 20)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll',changeBackground);

        return(
            <>
            <nav className={Dec6_Navbar_Items1?'D6_SL_Navbar_ItemsA':'D6_SL_Navbar_Items'}>
                <Grid style={{height:'100%'}} container>
           <Grid item  className='icon_gridM' sm={3} xs={6}>
        <img onClick={()=>window.location.href='/'}  style={{height:65}} src={require('../../../images/DRIVEWIDE.svg').default}></img>
        {/* <Typography style={{fontSize:22,fontWeight:'600',color:'var(--green)'}}>DriveWide</Typography> */}

    </Grid>
    <Grid item  className='icon_gridM1' sm={3} xs={6}>
    <i class="fas fa-align-justify icon_ham" onClick={()=> setOpen(!open) } ></i>    
        
    </Grid>

    <Grid container sm={9} xs={12} className='Grid_MC'>
<Grid item>

<TemporaryDrawer Toggle={Toggle} open={open}  />

    <Navbar  />
    </Grid>
    


 
 </Grid>  

                </Grid>
             
            </nav>
         
            </>
        );
    }
    
    export default Header;