import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';


const Terms_conditions = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid1">
                <h3 className="TBI_PrivacyPolicy_Block_MH TBI_PrivacyPolicy_Block_H">
                Service Terms & Conditions 
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                <h2 style={{color:'var(--green)'}}>Effective from 21st March 2023 </h2>
                Subject of these terms. These Terms of Service are in force between you and Drive Wide Ltd. They provide all terms and conditions applicable to our services, including your rights and responsibilities when using our services. Please read these Terms of Service carefully. 

You have accepted our Terms of Service at registration for an account. During registration a downloadable copy was made available to you. In addition, you can print a copy at <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/terms_conditions' target='_blank'>https://drivewide.co.uk/terms_conditions </a>. You will have been made aware that you can access and read these Terms of Service at the following URL <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/how-it-works' target='_blank'>https://drivewide.co.uk/how-it-works </a> and our office address (88 Crowland Avenue, Hayes, UB3 3JU) and that you can request a copy of these terms is sent to you. <br />

Link to our privacy statement. When signing up or using our services, you will provide us with personal data. As your privacy is very important to us, we are committed to keeping you informed about any processing of personal data. Please carefully read our  <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/privay_policy' target='_blank'>privacy</a>  statement. If you do not agree with any of the processing of your personal data as set out in our Privacy Statement, please do not use our services. 
                </p><br/>

                <h2 style={{color:'var(--green)'}}>Contents of these Terms of Service:</h2>

1.Offering and Availability of our Services <br /> <br />

2.Access <br /> <br />

3.Use of our mobile application  <br /> <br />

4.Payment and Processing <br /> <br />

5.Various (including Termination Rights and Limitation of Our Liability) <br /> <br />

6.Contact DriveWide <br />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'var(--green)'}}>1. Offering and Availability of our Services </b><br></br><br />

                

Our Services. We offer a digital solution that enables you to find active driving jobs in your area. Our mobile app allows you to search, apply and confirm jobs with a transport operator directly without the requirement of applying through various job posting websites and/or recruitment agencies. Instead, you register with us and apply directly through the app, we will periodically (weekly) release payments directly to your bank account for the amount of work you have carried out.  <br/>

 

When offering you our mobile app system we are facilitating the payment of completed driving jobs on behalf of the transport operator. For more details on the specific services we provide, please review <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/how-it-works' target='_blank'>https://drivewide.co.uk/how-it-works </a> <br/> 

Availability. You acknowledge that the use and availability of driving jobs is highly dependent on the operation of ‐ for example ‐ your mobile device, the internet, your GPS receiver and other resources you can use to access our services. If you are unable to access and use our mobile app, for any reason, you, and not we, are required to use alternative methods to complete driving jobs or there may be a risk that you are either not paid or partly compensated for having successfully completed a job. In case of any technical difficulties, please contact our helpdesk at <a href='mailto:support@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >support@drivewide.co.uk</b> </a>   <br/>

Partners. Our mobile app solution and related services may be used in combination with services offered by our partners. Our partners will have their own terms of service and you will be required to accept their terms of service before use. We are not responsible for the service offered by our partners.                    </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'var(--green)'}}>2. Access  </b><br></br><br />

                You can access our mobile app and related services via one of the tools made available to you, including smartphone and personal web page. A description of our tools, including instructions how to use them, can be reviewed here <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/how-it-works' target='_blank'>https://drivewide.co.uk/how-it-works </a>. Our tools are under continuous development and may be updated from time‐to‐time or discontinued. You must follow the tool instructions at all times and keep your tool up‐to‐date. <br />

Registration. Access to our tools is limited to registered accounts and such registration is subject to our approval. You are responsible for keeping your account information confidential and should not share your account information with anyone. A registered account is for your personal use only. You need to identify and authenticate yourself by entering your username in combination with a password, or by using touch ID, whatever is applicable, before you can start using our services and perform driving job applications. <br />

Please take note of the following: You are responsible for providing us with correct account, registration and access details, which should be kept up‐to‐date continuously by you. You (being the registrant of the account) are responsible and liable for all actions initiated with your account, also if you authorised, permitted or otherwise made your account available for someone else to use.            </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'var(--green)'}}>3. Use of our mobile application </b><br></br><br />

                Driving rule compliance. You must observe and comply with national and European regulations (when applicable), including The Highway Code, road safety and vehicle rules as well as Drivers' hours rules. These can be found on the <a style={{color:'var(--green)'}} href='https://www.gov.uk/' target='_blank'>gov.uk</a> website.<br/> 

Driving actions. You are responsible for providing all of the necessary and correct details for your driving action, such as your driver licence information, document information and the correct location. Your driving action will be automatically processed on our application on the basis of these details. The details you provide about your action are key for us to correctly process your driving payments. You alone are responsible for providing us with the correct details for your driving action and you will be responsible for paying any penalty charge notices issued as a result of incorrect driving. <br/> 

Please take note of the following: We are not responsible for verifying whether your driver details are correct. We emphasize that you must read the instructions before you start using our mobile app, as incorrect use might lead to penalty charges, incorrect payments or other consequences. <br/> 

Reminders & Confirmations. You may choose to receive confirmation messages that your driving job is still active or to confirm that your job has commenced. In addition you can elect to receive a reminder advising you when your driving job is due to end. We emphasize that your driving action still remains your responsibility. You acknowledge that receipt of the reminder depends on the availability of your network and can be interrupted/delayed. Any additional costs relating to these reminders will be charged simultaneously with all other charges. Costs for these services are detailed link to payment<br/>  
                   </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'var(--green)'}}>4. Payment and Processing </b><br></br><br />

                Reminders & Confirmations. The payment for jobs are set by the respective transport operator. We have no control over the amount of these payments, which may change from time‐to‐time. However, we will ensure that transport operators do not pay below the national living wage in the UK, up to date figures can be referred here: <a style={{color:'var(--green)'}} href='https://www.gov.uk/national-minimum-wage-rates' target='_blank'>https://www.gov.uk/national-minimum-wage-rates</a>.<br/>  

Our fees. We will charge you a small fee depending on your selection and use of our mobile application and the related services. Our fees may consist of a driving payment fee and/or a monthly subscription fee (only applicable to businesses), a fee for related services and other fees applicable to our services, which we may change from time‐to‐time. You are responsible for all costs charged by third parties for accessing or using our services (such as telephone, sms or data charges applied by your mobile operator). <br />

Payment method. You must select the payment method of your preference from the options we make available to you in our parking payment systems. When using our services, you accept that your payments may be processed by a third party payment processor. You acknowledge that certain use of our parking payment systems requires a specific payment method, such as a payment card. <br />

Payment. Depending on your selection and use of our mobile application and the related services, we will directly or periodically charge our fees, while using the payment method you have chosen. We are responsible for the correct execution of the payments and you are responsible for having adequate funds available to cover our fees. For registered accounts, your recent parking history, invoices, receipts and other transaction details can be found in your account on our services. <br />

Late payment. If you do not pay the amounts due or instigate unwarranted chargeback requests, we are entitled to immediately suspend the provision of our services to you (without prejudice to our further statutory rights). You will be charged the statutory interest rate as from the date you are in default on your payment obligation, except in the case of a genuine chargebacks. <br />

Refunds. We are under no obligation to provide a refund or cancel a job that has been completed through the app, unless there is a system error on our part. To submit a refund request please email <a href='mailto:customerservice@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >customerservice@drivewide.co.uk</b></a>  <br />

Please take note of the following: Upon registration we will immediately perform our services by providing you access to our mobile application and related services as expressly requested by you. Therefore you acknowledge that we immediately begin such performance and acknowledge that you will not have a right to withdraw from your agreement with us. <br /> 

</p>
            </Grid>
          
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'var(--green)'}}>5. Various (including Termination Rights and Limitation of Our Liability) </b><br></br><br />

                Term. Our agreement to provide the services based on these Terms of Service comes into effect upon successful registration of your account. In the event of instant access the agreement is effective as per your use of our mobile application. If you are a business and you order services based on a monthly subscription fee or other monthly recurring costs, then such services can be terminated for convenience at any time by providing 14 days written notice before the first day of the upcoming month. You can send your termination notice to the following e‐mail address <a href='mailto:customerservice@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >customerservice@drivewide.co.uk</b></a>. We may stop carrying our service as a whole or in parts by providing one month's prior written notice. <br />

Non‐compliance and abuse. We can immediately terminate or suspend this agreement in case you do not comply with your responsibilities as set out in these Terms of Service or our documents referred to in these Terms of Service or abuse or manipulate our service. We will provide you with written notice if we choose to terminate or suspend our services. Please note that the following events will in any case qualify as events that trigger our right to immediately terminate or suspend our service: <br />

1.Reports of unauthorised or unusual credit card use associated with the personal account including, but not limited to, notice by the card issuing bank. This includes notices made by you to your credit card company that a transaction was unauthorised or your account compromised, and is done in order to protect you from further unauthorised use of your card; <br /><br />

2.Reports of unauthorised or unusual driving associated with the account; <br /><br />

3.Abuse by you of the chargeback process provided by your issuing bank; <br /><br />

4.Excessive levels of disputes or chargebacks; <br /><br />

5.Where the cardholder name on the payment card associated with your account does not match the name registered for the account; <br /><br />

6.We have reasonable grounds to believe that you persistently fail to complete jobs from a transport operator; <br /><br />

7.We are unable to verify or authenticate any information that you provide to us; <br /><br />

8.We believe that your account or activities pose a significant credit or fraud risk to us;<br /><br /> 

9.We believe that your actions may cause financial loss for you, or us; or <br /><br />

10.Adverse reports from credit agencies. <br /><br />

11.Attempt to gain unauthorised access to our services, the server on which our services are run from, any server, computer or database connected to service including using any robot, spider, other automatic device, or manual process to monitor or copy our service or the content without our prior express written permission; <br /><br />

12.Take any action that imposes an unreasonable or disproportionately large load on our infrastructure; or knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. <br /><br />

13.You commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co‐operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our service and will cease immediately. <br /><br />

Force majeure. In cases of force majeure ‐ for instance disruptions in the telecommunication infrastructure (Internet), national unrest, mobilization, war, traffic closures, strikes, lockout, denial‐of‐service attacks, distributed‐denial‐of‐service attacks, disruption of operations, stagnation of supply, fire, flood or any other circumstances beyond our reasonable control, in which we are prevented from providing our services so that we cannot reasonably be required to fulfil the contract ‐ our duty to perform ceases to apply. <br />

Limitation of our liability. We are not liable for any loss of income, business or profits, or for any loss or damage that was not reasonably foreseeable at the time you entered this agreement or is an indirect or consequential loss or damage. <br />

Our liability for damage arising during the performance of our agreement is limited to the value of the service that we provide to you. <br />

Nothing in these Terms of Service shall exclude our liability for gross negligence and wilful intent or death and personal injury caused by our negligence or any other type of liability which cannot be excluded or limited as a matter of applicable law. <br />

Warranty. You are entitled to the statutory warranty rights regarding our services. <br />

Changes to these Terms of Service. We may change these Terms of Service. If we decide to do so, we will inform you of such change on our website. You may terminate our agreement in case you do not agree with these changes. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.<br /> 

Applicable law and venue. The agreement and these terms and conditions are governed by the laws of England & Wales. This choice of law will not deprive a consumer of the protection afforded by provisions, from which, according to the law of the state in which the participant has its habitual residence, cannot be derogated from by agreement. You have the option to resolve any claim, action or dispute by means of a procedure before the competent courts in the aforementioned jurisdiction.<br />  
</p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'var(--green)'}}>6. Contact DriveWide   </b><br></br><br />

                If you have any questions about our services, the website, these Terms of Service or anything other related to our services, you can contact us via the following contact information: <a href='mailto:support@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >support@drivewide.co.uk</b></a>.<br /><br />   
</p>
            </Grid>
       
       </Grid> </div>

    

    )
}

export default Terms_conditions
