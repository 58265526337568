import { Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import './home_bg.css'
export default function Home_bg() {
  return (
<div className="pickgradient">

<img src={require('../../../images/home_bg.jpg')} className='home_bg' />

<Grid container className='home_bg_maincont'>
<Grid container sm={8} xs={12}>
<Grid item sm={12} xs={12}>
<Typography className='home_bg_head'>
We Make Driving Effortless.

</Typography>

</Grid>
<Grid item style={{maxWidth:'90%'}} sm={12} xs={12}>
<Typography className='home_bg_text'>
{/* Our driver's business solutions serve over  million users, across various businesses and jobs.  */}
Our driver's business app serve users across various businesses and jobs.
</Typography>

</Grid>
<Grid container sm={12} xs={12}>
<Grid item >
<img src={require('../../../images/app-store.png')} className='home_bg_app' onClick={()=>window.open("https://apps.apple.com/app/drivewide/id6447601344")} />

</Grid>
<Grid item style={{paddingLeft:'2vw'}} >
<img src={require('../../../images/play-store.png')} className='home_bg_app' onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.drivewide.drivewide")}/>

</Grid>

</Grid>

</Grid>
<Grid item sm={4} xs={12}>
<Paper className='home_bg_paper' elevation={6}>
<div className='home_bg_inner' >
    <Typography className='home_bg_ppr_head'>
Drivers
    </Typography>
    <Typography className='home_bg_ppr_text'>
    {/* Download the app to your phone or access our web app.  */}
    Download our easy to use app on your phone or use the web app
    </Typography>
    <Typography onClick={()=>window.location.href='/how-it-works'} className='home_bg_ppr_works'>
How It Works!    </Typography>
<div style={{width:'100%',borderBottom:'1px solid var(--green)',paddingBottom:'3vh'}}>
<Button onClick={()=>window.location.href='/signin'} className='home_bg_btn' >
    
  Drive  </Button>
  </div>


<div style={{paddingTop:'2vh'}}>  <Typography className='home_bg_ppr_head'>
  Business Operators
    </Typography>
    <Typography className='home_bg_ppr_text'>
    {/* We’ve provided more than 500 jobs  over the state.     */}
    Post jobs all across the UK to suit your business needs
     </Typography>
    <Typography onClick={()=>window.location.href='/how-it-works'}  className='home_bg_ppr_works'>
    Find out how   </Typography>
<div style={{width:'100%'}}>
<Button onClick={()=>window.location.href='/signin'}  className='home_bg_btn' >
    
   business  </Button>
   </div>

  </div>
</div>
</Paper>

</Grid>
</Grid>



    </div>
  )
}
