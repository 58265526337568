import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { live } from '../../App';
import {useCookies} from 'react-cookie'
import { CSVDownload, CSVLink } from 'react-csv';
import CloseIcon from '@mui/icons-material/Close';

export default function DownloadLive(props) {
  const [drivers,setDrivers]=useState([])
  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
  const [reload,setReload]=useState(0)
const [active,setActive]=useState('Payouts')
const [payments,setPayments]=useState([])
const [data,setData]=useState({})
  const [processing,setProcessing]=useState(true)
  const [processing1,setProcessing1]=useState(true)
const [search1,setSearch1]=useState('')
const [search,setSearch]=useState('')

  const [openD,setOpenD]=useState(false)
  const [openD1,setOpenD1]=useState(false)

const date1=new Date()

var dd = data&&data.start_date?String(data.start_date.$d.getDate()).padStart(2, '0'):date1.getDate().toString().padStart(2,'0');
var mm = data&&data.start_date?String(data.start_date.$d.getMonth() + 1).padStart(2, '0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy = data&&data.start_date?data.start_date.$d.getFullYear():date1.getFullYear();

let today = yyyy+'-'+mm+'-'+dd;

var dd1 = data&&data.end_date?String(data.end_date.$d.getDate()).padStart(2, '0'):date1.getDate().toString().padStart(2,'0');
var mm1 = data&&data.end_date?String(data.end_date.$d.getMonth() + 1).padStart(2, '0'):(date1.getMonth()+1).toString().padStart(2,'0'); //January is 0!
var yyyy1 = data&&data.end_date?data.end_date.$d.getFullYear():date1.getFullYear();

let today1 = yyyy1+'-'+mm1+'-'+dd1;
today=new Date(today+'T'+'00:00:00')

today1=new Date(today1+'T'+'23:55:00')

// console.log(today1,today)
  useEffect(() => {
    setProcessing(true)
      fetch(live+`driver?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>driversResp(resp))
      }, [reload])

   const Invoices=(val)=>{
          fetch(live+`invoices`,{
            method:'PUT'
          }).then((res)=>res.json()).then((resp)=>paymentsResp(resp,val))
         
        }
      const driversResp=(val,val1)=>{


  Invoices(val.data)



           

          
          
          

      }
      const paymentsResp=(val,val1)=>{

setDrivers(val1)
        setPayments(val.data)
        setProcessing(false)
      
      
                 
      
                
                
                
      
            }
      const handleCloseD=()=>{
setOpenD(false)  
setOpenD1(false)        

      
      

      }
  const rows=  drivers&&drivers[0]&&!search?drivers.map((item,k) => {

    return  {

      id:k+1,
      driver_id:item.driver_id,
      fname:item.fname,
      lname:item.lname,
      email:item.email,
      phone:item.country_code+' '+item.mobile,
      credits:'£'+item.credits,
      is_verified:item.is_verified,
      is_active:item.is_active,
      account_name:item.account_name,
      account_number:item.account_number,
      bank_name:item.bank_name,
      sort_code:item.sort_code,
      




      



     
    }
}
  ):drivers&&drivers[0]&&search&&search.length>2&&drivers.filter(val=>(val.driver_id.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))||(val.fname&&val.fname.toLowerCase().includes(search.toLowerCase()))||(val.lname&&val.lname.toLowerCase().includes(search.toLowerCase()))||(val.bank_name&&val.bank_name.toLowerCase().includes(search.toLowerCase())))&&drivers.filter(val=>(val.driver_id.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))||(val.fname&&val.fname.toLowerCase().includes(search.toLowerCase()))||(val.lname&&val.lname.toLowerCase().includes(search.toLowerCase()))||(val.bank_name&&val.bank_name.toLowerCase().includes(search.toLowerCase())))[0]?drivers.filter(val=>(val.driver_id.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))||(val.fname&&val.fname.toLowerCase().includes(search.toLowerCase()))||(val.lname&&val.lname.toLowerCase().includes(search.toLowerCase()))||(val.bank_name&&val.bank_name.toLowerCase().includes(search.toLowerCase()))).map((item,k) => {

    return  {

      id:k+1,
      driver_id:item.driver_id,
      fname:item.fname,
      lname:item.lname,
      email:item.email,
      phone:item.country_code+' '+item.mobile,
      credits:'£'+item.credits,
      is_verified:item.is_verified,
      is_active:item.is_active,
      account_name:item.account_name,
      account_number:item.account_number,
      bank_name:item.bank_name,
      sort_code:item.sort_code,
      




      



     
    }
}
  ):''

  let headers = drivers&&drivers[0]&&rows&&rows[0]?Object.keys(rows[0]):''
  
  let d_data = drivers&&drivers[0]&&rows&&rows[0]?rows.map(item=>Object.values(item)):''
   
 
  const renderVerify = (val) => {
    return (
        <strong>
          {val.row.is_verified?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
        </strong>
    )
  }
  const renderActive = (val) => {
    return (
        <strong>
          {val.row.is_active?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
        </strong>
    )
  }
  const columns = [
    { field: 'id', headerName: 'S No.', width: 60 },
    { field: 'driver_id', headerName: 'Driver ID'},
  
    {
      field: 'fname',
      headerName: 'First Name',
     
    },
    {
      field: 'lname',
      headerName: 'Last Name',
    sortable:false,
    filterable:false
    
    },
    // {
    //   field: 'company_name',
    //   headerName: 'Company Name',
    //   // type: 'number',
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,  

      // type: 'number',
    }, {
      field: 'phone',
      headerName: 'Mobile',
      // type: 'number',
      width: 180,  


    }
    , {
      field: 'credits',
      headerName: 'credits',
      // type: 'number',
      width: 180,  


    },
   

     {
      field: 'is_verified',
      headerName: 'Verified',
      // type: 'number',
      sortable:false,
      filterable:false,
                  renderCell: renderVerify,
      width: 100,  
    },
     {
      field: 'is_active',
      headerName: 'Active',
      // type: 'number',
      sortable:false,
      filterable:false,
                  renderCell: renderActive,
      width: 100,  
    },
    {
      field: 'account_name',
      headerName: 'Account Name',
      // type: 'number',
      width: 180,  


    },
    {
      field: 'bank_name',
      headerName: 'Bank Name',
      // type: 'number',
      width: 180,  


    },
    {
      field: 'sort_code',
      headerName: 'Sort Code',
      // type: 'number',
      width: 180,  


    },
    {
      field: 'account_number',
      headerName: 'Account Number',
      // type: 'number',
      width: 180,  


    },
    
  

  
   
      //  {
      //                 field: 'Download',
      //                 headerName: ' ',
      //                 sortable:false,
      //                 width:170,
      //                 filterable:false,      
      //                       renderCell: renderRedeem,
      //                  },
//              {
//               field: 'Redeem',
//               headerName: ' ',
//               sortable:false,
//               width:170,
//               filterable:false,      
//                     renderCell: renderRedeem,
//                },
//                {
//                 field: 'Freeze',
//                 headerName: ' ',
// sortable:false,
//             filterable:false,              
//               renderCell: renderFreeze,
//                 width: 160, 
//                  },
//                  {
//                     field: 'Jobs Completed',
//                     headerName: ' ',
//     sortable:false,
//                 filterable:false,              
//                   renderCell: renderCompleted,
//                     width: 190, 
//                      },
  ];



  const rows_pay=payments&&payments[0]&&!search1?payments.filter(val=>((new Date(val.created_on)>=today)&&(new Date(val.created_on)<=today1))).map((item,k) => {
    return  {

      id:k+1,
      invoice:item.invoice,
      business_id:item.business_id,


      fname:item.fname,
      lname:item.lname,
      email:item.email,
      tax:'£'+item.tax_amount,

      reference_id:item.reference_id,
      amount:'£'+item.amount,
      total_amount:'£'+item.total_amount,
      created_on:item.created_on
   
      




      



     
    }
}
  )
  
  :payments&&payments[0]&&search1&&search1.length>2&&payments.filter(val=>((new Date(val.created_on)>=today)&&(new Date(val.created_on)<=today1))).filter(val=>(val.invoice&&val.invoice.toLowerCase().includes(search1.toLowerCase()))||(val.business_id.toLowerCase().includes(search1.toLowerCase())))&&payments.filter(val=>((new Date(val.created_on)>=today)&&(new Date(val.created_on)<=today1))).filter(val=>(val.invoice&&val.invoice.toLowerCase().includes(search1.toLowerCase()))||(val.business_id.toLowerCase().includes(search1.toLowerCase())))[0]?payments.filter(val=>((new Date(val.created_on)>=today)&&(new Date(val.created_on)<=today1))).filter(val=>(val.invoice&&val.invoice.toLowerCase().includes(search1.toLowerCase()))||(val.business_id.toLowerCase().includes(search1.toLowerCase()))).map((item,k) => {return  {

    id:k+1,
    invoice:item.invoice,
    business_id:item.business_id,
    reference_id:item.reference_id,


    fname:item.fname,
    lname:item.lname,
    email:item.email,
    tax:'£'+item.tax_amount,

    amount:'£'+item.amount,
    total_amount:'£'+item.total_amount,
    created_on:item.created_on
 
    




    



   
  }}):''


  const columns_pay = [
    { field: 'id', headerName: 'S No.', width: 60 },
    { field: 'invoice', headerName: 'Invoice ID'},
    { field: 'business_id', headerName: 'Business ID'},
    { field: 'reference_id', headerName: 'Reference ID',width:230},

  
    {
      field: 'fname',
      headerName: 'First Name',
     
    },
    {
      field: 'lname',
      headerName: 'Last Name',
    sortable:false,
    filterable:false
    
    },
    // {
    //   field: 'company_name',
    //   headerName: 'Company Name',
    //   // type: 'number',
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,  

      // type: 'number',
    }
    , {
      field: 'amount',
      headerName: 'Amount',
      // type: 'number',


    }, {
      field: 'tax',
      headerName: 'Tax',
      // type: 'number',


    }
    
    , {
        field: 'total_amount',
        headerName: 'Total',
        // type: 'number',
  
  
      },
      {
        field: 'created_on',
        headerName: 'Issued On',
        // type: 'number',
        width: 180,  
  
  
      },

   
    
 
  

  
   
       

  ];
  let headers_pay = payments&&payments[0]&&rows_pay&&rows_pay[0]?Object.keys(rows_pay[0]):''
  
  let d_data_pay = payments&&payments[0]&&rows_pay&&rows_pay[0]?rows_pay.map(item=>Object.values(item)):''
   
  // console.log(rows[0]) 

  const Download_cv=()=>{
    setOpenD(true)
    setTimeout(()=>setProcessing1(false),800)
  }
  const Download_cv1=()=>{
    setOpenD1(true)
    setTimeout(()=>setProcessing1(false),800)
  }

  
  return (
    <>
    <Grid item  className='icon_gridM' sm={3} xs={6}>
        <img onClick={()=>window.location.href='/'}  style={{height:65}} src={require('../../images/DRIVEWIDE.svg').default}></img>
        {/* <Typography style={{fontSize:22,fontWeight:'600',color:'var(--green)'}}>DriveWide</Typography> */}

    </Grid>

    <Dialog
        open={openD}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={()=>handleCloseD()}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={()=>handleCloseD()}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{!processing1?'Downloaded':'Downloading '}          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
         {!processing1? <div className="Floating_Form2">
          <CSVDownload filename='credits' headers={headers} data={d_data}  />
        </div>: <Box sx={{ display: 'flex',justifyContent:'center' }} >

<CircularProgress style={{marginTop:'2vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
</Box>}
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openD1}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={()=>handleCloseD()}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={()=>handleCloseD()}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{!processing1?'Downloaded':'Downloading '}          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
         {!processing1? <div className="Floating_Form2">
          <CSVDownload filename='credits' headers={headers_pay} data={d_data_pay}  />
        </div>: <Box sx={{ display: 'flex',justifyContent:'center' }} >

<CircularProgress style={{marginTop:'2vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
</Box>}
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>
    <Grid  style={{marginBottom:'1vh',textAlign:'end',paddingRight:'3vw'}}item sm={12} xs={12}>
      <Chip  onClick={()=>setReload(reload+1)} label='Refresh' style={{color:'white',background:'grey',marginRight:'3vw'}}  />

        <Chip  onClick={()=>props.closeLive()} label='Back to dashboard' style={{color:'white',background:'var(--green)'}}  />
      </Grid>



    <Grid style={{paddingTop:'5vh',paddingBottom:'5vh',height:'auto'}} className='grid_contPPr' container>
    <Grid item   sm={4} xs={6}>
    <Paper elevation={6} onClick={()=>setActive('Payouts')}  className={active==='Payouts'?'dash_subA':'dash_sub'}>
        Payouts
        </Paper>
        </Grid>

    <Grid item   sm={4} xs={6}>
    <Paper elevation={6} onClick={()=>setActive('Received Payments')} className={active==='Received Payments'?'dash_subA':'dash_sub'}>
        Received Payments
        </Paper>
        </Grid> 
       
       
      

   
  
        </Grid> 
        {active==='Payouts'?
        <Grid container>
        <Grid item sm={9} xs={8}>
<TextField onChange={(e)=>setSearch(e.target.value)} placeholder='Search payouts here ..' className='field_search'  />
</Grid>
<Grid item sm={3} xs={4}>
<Button disabled={!rows[0]} onClick={()=>Download_cv()}  variant='contained' className='btn_new'>Download</Button>
</Grid>

{/* <Grid container style={{margin:'2vh 0px'}}>
    <Grid item style={{margin:'1vh 0px'}} sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'

        label="From"
        
     
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
   
             
    </Grid>
    <Grid item style={{margin:'1vh 0px'}} sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'

        label="To"
        
     
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
   
             
    </Grid>
   </Grid> */}
   <Grid container>
{/* {(drivers&&drivers[0]&&!processing&&!edit&&!search)||(drivers&&drivers[0]&&!processing&&!edit&&search&&search.length>2&&rows)? */}
{(drivers&&drivers[0]&&!processing&&!search)||(drivers&&drivers[0]&&!processing&&search&&search.length>2&&rows)?

<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!drivers[0]&&!processing)||(!rows&&!processing)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Drivers Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>
        </Grid>:
        <Grid container>
        <Grid item sm={9} xs={8}>
<TextField placeholder='Search payments here ..'onChange={(e)=>setSearch1(e.target.value)} className='field_search'  />
</Grid>
<Grid item sm={3} xs={4}>
<Button disabled={!rows_pay[0]} onClick={()=>Download_cv1()}  variant='contained' className='btn_new'>Download</Button>
</Grid>

<Grid container style={{margin:'2vh 0px'}}>
    <Grid item style={{margin:'1vh 0px'}} sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'
value={data.start_date}

        label="From"
        onChange={(newValue) => {
          setData({...data,start_date:newValue});
        }}
     
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
   
             
    </Grid>
    <Grid item style={{margin:'1vh 0px'}} sm={3} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
              inputFormat='DD/MM/YYYY'
value={data.end_date}
        label="To"
        onChange={(newValue) => {
          setData({...data,end_date:newValue});
        }}
     
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
   
             
    </Grid>
   </Grid>
   <Grid container>
{/* {(drivers&&drivers[0]&&!processing&&!edit&&!search)||(drivers&&drivers[0]&&!processing&&!edit&&search&&search.length>2&&rows)? */}
{(payments&&payments[0]&&!processing&&!search1&&rows_pay[0])||(payments&&payments[0]&&!processing&&search1&&search1.length>2&&rows_pay[0])?

<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows_pay}
        columns={columns_pay}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!payments[0]&&!processing)||(!rows_pay[0]&&!processing)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Payments Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>
        </Grid>}
    </>
  )
}
