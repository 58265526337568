import { Alert, Box, Chip, CircularProgress, Container, Grid, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {useCookies} from 'react-cookie'
import './profile.css'
import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
import { Company_type, Language_P } from './data';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { live } from '../../App';
import Driver_details from './driver_details';
import Business_details from './business_details';

export default function Profile(props) {
  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])

   
  const [processing,setProcessing]=useState(true)
const [data,setData]=useState({
  fname:auth.drivewide.fname,
  lname:auth.drivewide.lname,
  mobile:auth.drivewide.mobile,
  country_code:auth.drivewide.country_code,
  email:auth.drivewide.email,
  address_line1:auth.drivewide.address_line1,
  address_line2:auth.drivewide.address_line2,
  city:auth.drivewide.city,
  stat:auth.drivewide.stat,
  country:auth.drivewide.country,
  zip_code:auth.drivewide.zip_code,
  language:auth.drivewide.language,
  company_type:auth.drivewide.company_type,
  company_name:auth.drivewide.company_name,
  company_number:auth.drivewide.company_number,
  dob:auth.drivewide.dob?auth.drivewide.dob:'',

  vat_registration_number:auth.drivewide.vat_registration_number











})




  useEffect(() => {
    if(auth.drivewide.driver_id||auth.drivewide.business_id){
     setProcessing(true)
     fetch(live+`${auth.drivewide.driver_id?'driver-major':'business-major'}?q=${auth.drivewide.driver_id?auth.drivewide.driver_id:auth.drivewide.business_id}`).then((res)=>res.json()).then(resp=>driverRes(resp))
    }
   }, [])
   const driverRes=(res)=>{
    if(res.data&&res.data.driver_id)
    {
    
      setData({
        ...data,
        driver_id:res.data.driver_id?res.data.driver_id:'',

        fname:res.data.fname,
        lname:res.data.lname,
        mobile:res.data.mobile,
        country_code:res.data.country_code,
        email:res.data.email,
        dob:res.data.dob?res.data.dob:'',
    
        address_line1:res.data.address_line1,
        address_line2:res.data.address_line2,
        city:res.data.city,
        stat:res.data.stat,
        country:res.data.country,
        zip_code:res.data.zip_code,
        language:res.data.language,
        utr_number:res.data.utr_number?res.data.utr_number:'',
        dl_number:res.data.dl_number?res.data.dl_number:'',
        driver_card_number:res.data.driver_card_number?res.data.driver_card_number:'',
        cpc_card_number:res.data.cpc_card_number?res.data.cpc_card_number:'',
        passport_number:res.data.passport_number?res.data.passport_number:'',
        visa_number:res.data.visa_number?res.data.visa_number:'',
        sharecode:res.data.sharecode?res.data.sharecode:'',
        experience_years:res.data.experience_years?res.data.experience_years.toString():'0',
        experience_months:res.data.experience_months?res.data.experience_months.toString():'0',
      
        is_verified:res.data.is_verified,
        citizen:res.data.citizen,
        classC:res.data.classC,
    profile:res.data.profile,
    
      
        dl_number_image:res.data.dl_number_image,
        dl_number_image_back:res.data.dl_number_image_back,
        driver_card_image:res.data.driver_card_image,
        driver_card_image_back:res.data.driver_card_image_back,
        cpc_card_image:res.data.cpc_card_image,
        cpc_card_image_back:res.data.cpc_card_image_back,
        passport_number_image:res.data.passport_number_image,
        visa_number_image:res.data.visa_number_image,
        cv_upload:res.data.cv_upload,
        skills:res.data.skills,
        criminal_record:res.data.criminal_record,
        criminal_record_text:res.data.criminal_record_text,
        account_name:res.data.account_name,
        account_number:res.data.account_number,
        sort_code:res.data.sort_code,
        bank_name:res.data.bank_name,
        applied:res.data.applied,
        accepted:res.data.accepted,
        licence_category:res.data.licence_category,
        dl_expiry:res.data.dl_expiry?res.data.dl_expiry:'',
        tacho_expiry:res.data.tacho_expiry?res.data.tacho_expiry:'',
        cpc_expiry:res.data.cpc_expiry?res.data.cpc_expiry:'',
        passport_expiry:res.data.passport_expiry?res.data.passport_expiry:'',
        visa_expiry:res.data.visa_expiry?res.data.visa_expiry:'',
        lat:res.data.lat?res.data.lat:'',
        lng:res.data.lng?res.data.lng:'',
        is_active:res.data.is_active
        // criminal_record:res.data.criminal_record,
    
    
      })
setProcessing(false)
    
    }
    if(res.data&&res.data.business_id){
      setData({
        ...data,
        business_id:res.data.business_id?res.data.business_id:'',

      fname:res.data.fname?res.data.fname:'',
        lname:res.data.lname?res.data.lname:'',
        mobile:res.data.mobile?res.data.mobile:'',
        country_code:res.data.country_code?res.data.country_code:'',
        email:res.data.email?res.data.email:'',
        work:res.data.work?res.data.work:'',
        address_line1:res.data.address_line1?res.data.address_line1:'',
        address_line2:res.data.address_line2?res.data.address_line2:'',
        city:res.data.city?res.data.city:'',
        stat:res.data.stat?res.data.stat:'',
        country:res.data.country,
        zip_code:res.data.zip_code?res.data.zip_code:'',
        company_type:res.data.company_type?res.data.company_type:'',
        company_name:res.data.company_name?res.data.company_name:'',
        company_number:res.data.company_number?res.data.company_number:'',
        vat_registration_number:res.data.vat_registration_number?res.data.vat_registration_number:'',
        is_verified:res.data.is_verified,
        subscription_id:res.data.subscription_id?res.data.subscription_id:'',
        plan_type:res.data.plan_type?('£'+res.data.amount+'/'+res.data.payment_intervals+res.data.plan_type):'',
        subscription_start:res.data.subscription_start?res.data.subscription_start:'',
        renewal_on:res.data.renewal_on?res.data.renewal_on:'',
        subscription_active:res.data.subscription_active?res.data.subscription_active:'',
        })  
      
        setProcessing(false)

      }

    if(!res.data){
    
      alert(res.detail)
      setProcessing(false)
    
    }
    }
    const backPressed1=()=>{
      props.backPressed1()
    }
  return (
    <Container style={{padding:'10vh',maxWidth:'80%',display:'contents'}}>
   
 
      {processing? <Box sx={{ padding:'10vh',display: 'flex',justifyContent:'center' }} >

<CircularProgress style={{marginTop:'2vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
</Box>:!processing&&auth.drivewide&&auth.drivewide.driver_id?<Driver_details store={true} edit={data} backPressed1={backPressed1} />
:!processing&&auth.drivewide&&auth.drivewide.business_id?<Business_details store={true} edit={data} backPressed1={backPressed1} /> :null}
    </Container>
  )
}
