import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography ,Grid,Paper, Dialog, Slide, CircularProgress, Container, Chip} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useCookies} from 'react-cookie'

import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import './dashboard.css'
import Drivers_dash from './drivers';
import Business_dash from './business';
import { live } from '../../App';
import './dashboard_admin.css'
import Live_dash from './live_dash';
import Invoices_Live from './invoices';


export default function Dashboard_Admin(props) {

  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])

 



  const [state, setState] = React.useState({
 
    left: false,
   
  });
  const[message,setMessage]=React.useState('')
  const[notification,setNotification]=React.useState('')
const [open,setOpen]=React.useState(false)
const[driver,setDriver]=React.useState('')
  const [screen,setScreen]=React.useState('Live Dashboard')
  const [anchor]=React.useState('left')
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const closeLive=()=>{
    setScreen('')
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <Divider />
      


      <List>
        {((["Drivers",'Businesses','Live Dashboard',"Invoices"])).map((text, index) => (
          <ListItem style={{width:'100%'}} key={index} onClick={()=>setScreen(text)} disablePadding>
            <ListItemButton>
             
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> 
        ))}
      </List>
    </Box>
  );
const Notifications=(val)=>{
  setNotification(val)
  setOpen(true)
  const formData=new FormData()
  formData.append('type',val)
  fetch(live+'notifications',{
    method:'POST',
    body:formData
  }).then(res=>res.json()).then(resp=>notificationRes(resp))

}
const notificationRes=(res)=>{
  if(res.status){
    setMessage(res.detail)
    setTimeout(()=>afterNotification(),2000)
  }
  else{
    setOpen(false)
    setMessage('')
    setNotification('')
  }

}
const afterNotification=()=>{
  setOpen(false)
  setMessage('')
  setNotification('')
}
  const signOut=()=>{
  
deleteAuth('drivewide')
// window.location.href='/'
  }

// const rows=driver&&driver.credits_history&&driver.credits_history[0]?driver.credits_history.map((val,idx)=>{
// return{
// id:idx+1,

// }
// }):''

if(!auth['drivewide'])(
  window.location.href='/signin'

)


else{


  return (
    <div>

 <React.Fragment >
          <CssBaseline />
      <AppBar
        position="fixed" 
        className="appbarMain"
       style={{top:120}}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
              <div >
            <IconButton
             onClick={()=>setState({...state,left:!state.left})}
                color="inherit"
                aria-label="open drawer"
                
                edge="start"
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{alignSelf:'center'}} >
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              <div style={{display:'flex',justifyContent:'end',width:'100%'}}>
              {/* <div onClick={()=>window.location.href='/'} style={{paddingRight:'20px',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                HOME
              </div> */}
              <div onClick={()=>signOut()} style={{paddingRight:'20px',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                SIGN OUT
              </div>
              </div>
              </div>
        </Toolbar>
      </AppBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}

          >

<Grid container  >
            <Grid style={{textAlign:'center'}} item xs='12'>
            <img src={require('../../images/DRIVEWIDE.svg').default} style={{maxWidth:'100%',height:80}} />
              </Grid>
              <Grid  style={{textAlign:'center'}} item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto',fontSize:'16px',paddingLeft:18,textAlign:'initial'}}>Hey <b style={{color:'var(--green)'}}>{auth['drivewide'].fname}</b></h1>
              </Grid>

            
          <Grid item style={{alignSelf:'center',textAlign:'center'}} xs='4'>
          <IconButton               onClick={()=>setState({...state,left:!state.left})}
>
            {state.left ? <ChevronLeftIcon style={{fontSize: 40}} /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
                 <Grid> 
            {list(anchor)}
            </Grid> 
          </Drawer>
          <main
        style={{minheight:'50vh'}}
      >


        
    <div style={{height:'100%',minHeight:'90vh'}}>
    <Dialog
    fullScreen
    open={open}
    onClose={()=>setOpen(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      {!message?<Grid item sm={12} xs={12}>
      <Box sx={{ display: 'flex',justifyContent:'center' }} >
      <CircularProgress style={{marginTop:'23vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box>
        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>Sending {notification.toLowerCase()} notification, please wait</Typography>
      </Grid>:
      <>
      <Grid item sm={12} xs={12}>
      
        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'25vh'}}>{message}</Typography>
      </Grid>
        </>
      }
    </Grid>
   
  </Dialog>


  <Dialog
    fullScreen
    open={screen==='Live Dashboard'?true:false}
    onClose={()=>setOpen(false)}
    // TransitionComponent={Transition}
  >
  <Live_dash closeLive={closeLive} />
   
  </Dialog>
  {/* <Dialog
    fullScreen
    open={true}
    onClose={()=>setOpen(false)}
    TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      {<Grid item sm={12} xs={12}>
     
        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>Jobs completed by driver</Typography>
      </Grid>
      
      
      
      }
      <Box  className='box_transactions' >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={[15]}
                  rowsPerPageOptions={[15]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
    </Grid>
   
  </Dialog> */}
    { <Grid className='grid_contPPr' container>
    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Drivers')}  className={screen==='Drivers'?'dash_subA':'dash_sub'}>
        Drivers
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Businesses')}  className={screen==='Businesses'?'dash_subA':'dash_sub'}>
        Businesses
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Live Dashboard')}  className={screen==='Notifications'?'dash_subA':'dash_sub'}>
        Live Dashboard
        </Paper>
        </Grid>
        {/* <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Notifications')}  className={screen==='Notifications'?'dash_subA':'dash_sub'}>
        Notifications
        </Paper>
        </Grid> */}
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Invoices')}  className={screen==='Invoices'?'dash_subA':'dash_sub'}>
        Invoices
        </Paper>
        </Grid>

    {/* <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Profile')}  className={screen==='Profile'?'dash_subA':'dash_sub'}>
        Profile
        </Paper>
        </Grid>

    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Jobs History')}  className={screen==='Jobs History'?'dash_subA':'dash_sub'}>
        Jobs History
        </Paper>
        </Grid> 
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Credits')}  className={screen==='Credits'?'dash_subA':'dash_sub'}>
        Credits
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Payments')}  className={screen==='Payments'?'dash_subA':'dash_sub'}>
        Payments
        </Paper>
        </Grid>
       */}

   
  
        </Grid>  }


        {screen==='Drivers'?<Drivers_dash />:screen==='Businesses'?<Business_dash />:screen==='Invoices'?<Invoices_Live />:screen==='Notifications'?<Grid style={{padding:'8vh 0px',maxWidth:'1200px',justifyContent:'center'}} container>
    <Grid item   sm={4} xs={6}>
    <Paper elevation={6}  onClick={()=>Notifications('Morning')} className={'dash_subA'}>
        Morning Notification <br /> 
        
        </Paper>
        </Grid>
        <Grid item   sm={4} xs={6}>
    <Paper elevation={6} onClick={()=>Notifications('Evening')}  className={'dash_subA'}>
    Evening Notification <br /> 
        </Paper>
        </Grid>
        {/* <Grid item   sm={3} xs={6}>
    <Paper elevation={6}  className={'dash_subA'}>
   Custom Notification <br /> 
     
        </Paper>
        </Grid> */}
 

   
  
        </Grid>:null}

    </div>
   
   

      </main>
        </React.Fragment>
     
      
      
    </div>
  );}
}
