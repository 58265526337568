import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React,{  useState } from 'react';


import '../jobs.css'

export default function Complete_live(props){
    const {jobs,head,completed}=props


   
   const [openC,setOpenC]=useState(true)
   

 


     
   const renderID = (val) => {
    return (
        <strong>
          <a style={{color:'var(--green)'}} href={`/jobs/${val.row.job_id}`} target='_blank' >{val.row.job_id}</a>
        </strong>
    )
  } 







      

     
     
const rows= 

jobs?(jobs.map((item,k) => {
    return  {
     job_id:item.job_id,

      id:k+1,
      job_title:item.job_title,
      job_status:item.job_status,

      job_type:item.job_type,
      town_name:item.town_name,
      post_code:item.post_code,
      company_name:item.company_name,
      experience_years:item.experience_years+' Years '+item.experience_months+' '+'months',
      experience_months:item.experience_months,
      job_start_date:item.job_start_date+' '+item.job_start_time,
      job_start_time:item.job_start_time,
      urgent_job:item.urgent_job,
      job_end_date:item.job_end_date+' '+item.job_end_time,
      job_end_time:item.job_end_time,
      job_reporting_date:item.job_reporting_date+' '+item.job_reporting_time,
      job_report:item.job_reporting_date+' '+item.job_reporting_time,

      job_reporting_time:item.job_reporting_time,
      last_acceptance_date:item.last_acceptance_date+' '+item.last_acceptance_time,
      last_acceptance_time:item.last_acceptance_time,
      pay_rate_type:'£'+item.pay_rate+'/'+item.pay_rate_type,
      pay_rate:item.pay_rate,
      is_active:item.is_active,
      created_on:item.created_on,
      published_on:item.published_on,
      applied:item.applied,
      accepted:item.accepted




      



     
    }
}
  )):''
  
    const renderLink = (val) => {
                    
                
            return (
              <strong>
                           <Button  onClick={()=>window.open(`https://www.google.com/maps/@${val.row.lat},${val.row.lng},13z`,'_blank')}  className='btn_evd2'>{'View Location'}
    

                           </Button>
                </strong>
            )
          }

const columns = [
  { field: 'id', headerName: 'S No.', width: 60 },
  { field: 'job_id', headerName: 'Job ID', renderCell: renderID,
},

  {
    field: 'job_title',
    headerName: 'Job Title',
   
  },
  {
    field: 'job_status',
    headerName: 'Job Status',
  sortable:false,
  filterable:false,
  width:220,
  
  },
  // {
  //   field: 'company_name',
  //   headerName: 'Company Name',
  //   // type: 'number',
  // },
  {
    field: 'job_start_date',
    headerName: 'Job Start',
    width: 180,  

    // type: 'number',
  }, {
    field: 'job_end_date',
    headerName: 'Job End',
    // type: 'number',
    width: 180,  

  },
   {
    field: 'job_report',
    headerName: 'Job Report',
    // type: 'number',
    width: 180,  

  },
  
  {
    field: 'pay_rate_type',
    headerName: 'Pay Rate',
    // type: 'number',
  },
  
  {
    field: 'town_name',
    headerName: 'Town Name',
    // type: 'number',
  },
  {
    field: 'post_code',
    headerName: 'Post Code',
    // type: 'number',
  },
    
  

 
];      
         

    
     
const rows_completed= jobs&&completed&&jobs[0]?jobs.map((item,k) => {
  
  // console.log(item.completed[0])
  return  {
   job_id:item.job_id,

    id:k+1,
    job_title:item.job_title,
    job_type:item.job_type,
    town_name:item.town_name,
    post_code:item.post_code,
    company_name:item.company_name,
    
    job_start_date:item.job_start_date+' '+item.job_start_time,
    job_start_time:item.job_start_time,
    job_end_date:item.job_end_date+' '+item.job_end_time,
    job_end_time:item.job_end_time,
    driver_name:(item.completed[0].fname+' '+item.completed[0].lname),
    driver_id:(item.completed[0].driver_id),

    completed_on:(item.completed[0].completed_on).substring(0,10)+' '+(item.completed[0].completed_on).substring(11,16),
    lat:item.completed[0].lat,

    lng:item.completed[0].lng,
    
    credits:'£'+item.total_pay,
   




    



   
  }
}
):''



const columns_completed = [
  { field: 'id', headerName: 'S No.', width: 60 },
  { field: 'job_id', headerName: 'Job ID', renderCell: renderID,
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
   
  },

  {
    field: 'company_name',
    headerName: 'Company Name',
    // type: 'number',
    width:170,

  },
  {
    field: 'job_start_date',
    headerName: 'Job Start',
    width: 180,  

    // type: 'number',
  }, {
    field: 'job_end_date',
    headerName: 'Job End',
    // type: 'number',
    width: 180,  

  },
  {
    field: 'driver_id',
    headerName: 'Completed By',
    width:170,

    // type: 'number',
  },
  {
    field: 'driver_name',
    headerName: 'Driver',
    width:170,

    // type: 'number',
  },
  {
    field: 'completed_on',
    headerName: 'Completed On',
    width:170,

    // type: 'number',
  },
   

  {
    field: 'credits',
    headerName: 'Job Credits',
    width:170,

    // type: 'number',
  },

  {
    field: 'lat',
    headerName: 'Latitude',
    width: 160, 

    // type: 'number',
  },
  {
    field: 'lng',
    headerName: 'Longitude',
    width: 160, 

    // type: 'number',
  },
  //  {
  //             field:" ",
  //             headerName: ' ',
  //             sortable:false,
  //             filterable:false,  
  //             renderCell: renderLink,
  //             width: 160, 

  //              },

  

  

 
     
      
];


     



            

      
      
      const back=()=>{
        setOpenC(false)
        props.closeReport()
       }


      

    return(


    
        <Grid container style={{textAlign:'start'}}>
             <Dialog
    fullScreen
    open={openC}
    onClose={()=>setOpenC(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
      {!jobs?
      <Grid item sm={12} xs={12}>
                        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>{head}</Typography>

        <Box sx={{ display: 'flex',justifyContent:'center' }} >

      <CircularProgress style={{marginTop:'5vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box></Grid>:
    jobs&&completed?
    <Grid container style={{justifyContent:'center'}}>
    <Grid item sm={12} xs={12}>
    <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh',paddingBottom:'2vh'}}>{head} </Typography>
    
      </Grid>
    
          <Box  className='box_transactions' >
                    <DataGrid
                      rows={rows_completed}
                      columns={columns_completed}
                      pageSize={[15]}
                      rowsPerPageOptions={[15]}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </Box>
                  {/* </Grid> */}
                  <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
          <Button onClick={()=>back()} className='pricing_plan_btn'>
          Back
          </Button>
          </Grid>
                  </Grid>:

      <Grid container style={{justifyContent:'center'}}>
<Grid item sm={12} xs={12}>
<Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh',paddingBottom:'2vh'}}>{head} </Typography>

  </Grid>

  {/* <Grid > */}
      <Box  className='box_transactions' >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={[15]}
                  rowsPerPageOptions={[15]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
              {/* </Grid> */}
              <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>back()} className='pricing_plan_btn'>
      Back
      </Button>
      </Grid>
              </Grid>}

    </Grid>
   
  </Dialog>




   

     </Grid>
    


)
}