import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React,{ useEffect, useRef, useState } from 'react';
import { live } from '../../App';

import {useCookies} from 'react-cookie'

import './jobs.css'

import CloseIcon from '@mui/icons-material/Close';

import Driver_details from './driver_details';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Payments from './payments';
import { CSVDownload, CSVLink } from 'react-csv';
export default function Drivers_dash(props){
  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
  const [submitted,setSubmitted]=React.useState(false)

   const [processing,setProcessing]=useState(true)
   const [drivers,setDrivers]=useState([])
   const [duplicate,setDuplicate]=useState(false)
   const [redeem,setRedeem]=useState(false)
   const [edit,setEdit]=useState(false)
   const[errMsg,setError]=useState(false)
   const [open,setOpen]=useState(false)
   const [open1,setOpen1]=useState(false)
   const [open2,setOpen2]=useState(false)
   const [openR,setOpenR]=useState(false)
   const [openC,setOpenC]=useState(false)
   const [openF,setOpenF]=useState(false)
   const [openP,setOpenP]=useState(false)
   const [openD,setOpenD]=useState(false)
  const {verified}=props

const [managed,setManaged]=useState('')
   const [search,setSearch]=useState('')

   const [job,setJob]=useState('')

   const [del,setDel]=useState(false)
   const [job_id,setJobID]=useState('')
   const [data,setData]=useState({
    id:'',
    credits:''
   })


   const [reload,setReload]=useState(0)


   const ref=useRef()
   const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
    useEffect(() => {
      setProcessing(true)
        fetch(live+`driver?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>driversResp(resp))
        }, [reload])

        const driversResp=(val)=>{

{if(val.data&&verified){
    setProcessing(false)
    setDrivers(val.data.filter(val=>val.is_verified))
}
else if(val.data&&!verified){
  setProcessing(false)
  setDrivers(val.data.filter(val=>!val.is_verified))
}
else{
    setProcessing(false)
    setOpen(true)
    setError(val.detail)
    setDrivers([])

}
             }

            
            
            

        }




        const Edit=(id,val2)=>{
if(val2){
  setDuplicate(true)
  setEdit(drivers.filter(val=>val.driver_id===id)[0])

}
else{
  setDuplicate(false)

  setEdit(drivers.filter(val=>val.driver_id===id)[0])

}



        }


        const handleSubmit=()=>{
          setRedeem(true)
const formData=new FormData()
formData.append('fname',job_id.fname)
formData.append('email',job_id.email)


formData.append('driver_id',job_id.driver_id)
formData.append('credits',data.credits)

formData.append('reference_id',data.id)

fetch(live+'manual_credits',{
  method:'POST',
  body:formData
}).then(res=>res.json()).then(resp=>redeemRes(resp))


        }

const redeemRes=(val)=>{
  if(val.status){
    setRedeem(false)
setJobID('')
    setSubmitted(true)
    setReload(reload+1)

    setTimeout(()=>timeOut(),1500)
    
  }
  else{
    setJobID('')

    setRedeem(false)
    

  }

}

const timeOut=()=>{
setSubmitted(false)
setOpenR(false)
}
const backPressed=()=>{
    setProcessing(true)
setDuplicate(false)
    setReload(reload+1)
    setEdit(false)
    setManaged(false)
}

const handleClose=()=>{
  setOpenR(false)
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setOpenF(false)
  setJobID('')



}
const handleCloseD=()=>{
  setOpenD(false)
 
  setJob('')


  
}
const Delete=(val)=>{

  
  if(job_id){
    setOpen2(false)
    setDel(true)
    const formdata=new FormData()
      formdata.append(`job_id`,job_id)
    fetch(live+'jobs',
    {
      method:'Delete',
      body:formdata
    }).then(res=>res.json()).then((resp)=>delRes(resp))
  
  
  
  }
  else{
    setJobID(val)
  setOpen2(true)
  }
  }


  let headers = job&&job.credits_history?Object.keys(job.credits_history[0]):''
  
 let d_data = job&&job.credits_history?job.credits_history.map(item=>Object.values(item)):''
  
  const delRes=(resp)=>{
    if(resp.status){
  setDel(false)
  setError(resp.detail)
  setReload(reload+1)
  setOpen1(true)
  setJobID('')
    }
    else{
      setDel(false)
  
      setError(resp.detail)
      setOpen(true)
      setJobID('')
  
  
    }
  
  }

  const Driver=(val)=>{
    setJobID(val)
    setOpenR(true)
    // setTimeout(()=>console.log('yupp'),1100)
  }
        const renderEdit = (val) => {
          // console.log(val.row.published_on)
          
            return (
                <strong>
                                            
                                             {/* <Tooltip title={val.row.published_on?'Edit disabled as job is published':'' }> */}
{/* <span>                 */}
<Button onClick={()=>setManaged(val)} disabled={val.row.published_on?true:false} className='btn_evd2'>Manage</Button>

       {/* <Button onClick={()=>Edit(val.row.driver_id)} disabled={val.row.published_on?true:false} className='btn_evd2'>Edit</Button> */}
{/* </span> */}

                {/* </Tooltip> */}
                </strong>
            )
          }
          const renderRedeem = (val) => {
            // console.log(val.row.published_on)
          
              return (
                  <strong>
            
         {/*  */}
  

{/* 
<CSVLink  data={data} 
  filename='credits_history' headers={headers}> */}
<Button  onClick={()=>Download(val.row)}   className='btn_evd2'>Download</Button>
   
{/* </CSVLink> */}
                  </strong>
              )
            }

            const Download=(val)=>{
              setOpenD(true)

              fetch(live+`driver-major?q=${val.driver_id}`).then((res)=>res.json()).then(resp=>historyResp(resp))

            }
            const historyResp = (val)=>{
        
              setJob(val.data)

           
//               return(

// <CSVDownload data={data} 

//   filename='credits_history' headers={headers} target='_blank'></CSVDownload>              )
//            
 }

           

          const renderVerify = (val) => {
            return (
                <strong>
                  {val.row.is_verified?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
                </strong>
            )
          }
        //   const renderDelete = (val) => {
        //               // console.log(val.row.job_reporting_date.replace(' ','T'))
        //               const oneDay1 = 24 * 60 * 60 * 1000; 
        //               const firstDate1= new Date();
        //               const secondDate1 = new Date(val.row.job_reporting_date.replace(' ','T'));
                      
        //               const diffDays1 = ((( secondDate1-firstDate1) / oneDay1));
                
        //     return (
        //         <strong>
        //                    <Tooltip title={diffDays1>0?'':'Delete disabled as reporting time is passed' }>
        //                    <span>
        //                    <Button  onClick={()=>Delete(val.row.job_id)} disabled={diffDays1>0?false:true} className='btn_evd3'>{job_id===val.row.job_id&&del?'Deleting':'Delete'}
                          

        //                    </Button>
        //                    </span>
        //                    </Tooltip>
        //            {/* <Button onClick={()=>Delete(val.row.eventID)} className='btn_evd3'>Delete</Button> */}
        //         </strong>
        //     )
        //   }
          const renderFreeze = () => {
            return (
                <strong>
                           <Button  onClick={()=>Freeze(managed.row)} className='btn_evd4'>{managed.row.is_active?'Freeze':'Unfreeze'}</Button>
          
                   {/* <Button onClick={()=>Delete(val.row.eventID)} className='btn_evd3'>Delete</Button> */}
                </strong>
            )
          }

          const Freeze=(val)=>{
            // if (val.row.is_active){
              setJobID(val)
              setOpenF(true)

            // }

          }
          const renderCompleted = (val) => {
            return (
                <strong>
                           <Button  onClick={()=>completeClicked(val.row.driver_id)} className='btn_evd5'>{'Jobs Completed'}</Button>
          
                   {/* <Button onClick={()=>Delete(val.row.eventID)} className='btn_evd3'>Delete</Button> */}
                </strong>
            )
          }
         const  completeClicked=(val)=>{
setJobID(val)     
setProcessing(true) 
setOpenC(true) 
const formData=new FormData()
formData.append('driver_id',val)
fetch(live+'jobs-app',{
  method:'put',
  body:formData
}).then(res=>res.json()).then(resp=>completedRes(resp))

}

const completedRes=(res)=>{
  if (res.status){
    setJob(res.completed)
    setProcessing(false)
  }
  else{
    setProcessing(false)

  }

}
const rows_completed= job&&job[0]?job.map((item,k) => {
  return  {
   job_id:item.job_id,

    id:k+1,
    job_title:item.job_title,
    job_type:item.job_type,
    town_name:item.town_name,
    post_code:item.post_code,
    company_name:item.company_name,
    
    job_start_date:item.job_start_date+' '+item.job_start_time,
    job_start_time:item.job_start_time,
    job_end_date:item.job_end_date+' '+item.job_end_time,
    job_end_time:item.job_end_time,
    completed_on:(item.completed[0].completed_on).substring(0,10)+' '+(item.completed[0].completed_on).substring(11,16),
    
    
    credits:'£'+item.completed[0].credits,
   




    



   
  }
}
):''



const columns_completed = [
  { field: 'id', headerName: 'S No.', width: 60 },
  { field: 'job_id', headerName: 'Job ID'},

  {
    field: 'job_title',
    headerName: 'Job Title',
   
  },

  {
    field: 'company_name',
    headerName: 'Company Name',
    // type: 'number',
    width:170,

  },
  {
    field: 'job_start_date',
    headerName: 'Job Start',
    width: 180,  

    // type: 'number',
  }, {
    field: 'job_end_date',
    headerName: 'Job End',
    // type: 'number',
    width: 180,  

  },
  {
    field: 'completed_on',
    headerName: 'Completed On',
    width:170,

    // type: 'number',
  },
   

  {
    field: 'credits',
    headerName: 'Credits Recieved',
    width:170,

    // type: 'number',
  },

  // {
  //   field: 'town_name',
  //   headerName: 'Town Name',
  //   // type: 'number',
  // },
  // {
  //   field: 'post_code',
  //   headerName: 'Post Code',
  //   // type: 'number',
  // },
  

  

 
     
      
];
        const rows= drivers&&drivers[0]&&!search?
       ( drivers.map((item,k) => {
            return  {
        
              id:k+1,
              driver_id:item.driver_id,
              fname:item.fname,
              lname:item.lname,
              email:item.email,
              phone:item.country_code+' '+item.mobile,
              credits:'£'+item.credits,
              is_verified:item.is_verified,
              is_active:item.is_active,
              account_name:item.account_name,
              account_number:item.account_number,
              bank_name:item.bank_name,
              sort_code:item.sort_code,
              

        
        
        
              
        
        
        
             
            }
        }
          )):drivers&&drivers[0]&&search&&search.length>2&&drivers.filter(val=>((val.driver_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))))&&drivers.filter(val=>((val.driver_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))))[0]?drivers.filter(val=>((val.driver_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase())))).map((item,k) => {
            return  {
        
              id:k+1,
              driver_id:item.driver_id,
              fname:item.fname,
              lname:item.lname,
              email:item.email,
              phone:item.country_code+' '+item.mobile,
              credits:'£'+item.credits,
              is_verified:item.is_verified,
              is_active:item.is_active,
              account_name:item.account_name,
              account_number:item.account_number,
              bank_name:item.bank_name,
              sort_code:item.sort_code,




              

        
        
        
              
        
        
        
             
            }
        }
          ):''
        const columns = [
          { field: 'id', headerName: 'S No.', width: 60 },
          { field: 'driver_id', headerName: 'Driver ID'},
        
          {
            field: 'fname',
            headerName: 'First Name',
           
          },
          {
            field: 'lname',
            headerName: 'Last Name',
          sortable:false,
          filterable:false
          
          },
          // {
          //   field: 'company_name',
          //   headerName: 'Company Name',
          //   // type: 'number',
          // },
          {
            field: 'email',
            headerName: 'Email',
            width: 180,  

            // type: 'number',
          }, {
            field: 'phone',
            headerName: 'Mobile',
            // type: 'number',
            width: 180,  


          }
          , {
            field: 'credits',
            headerName: 'credits',
            // type: 'number',
            width: 180,  


          },
         

           {
            field: 'is_verified',
            headerName: 'Verified',
            // type: 'number',
            sortable:false,
            filterable:false,
                        renderCell: renderVerify,
            width: 100,  
          },
          
          
        
      
        
          {
            field: 'Edit',
            headerName: ' ',
            sortable:false,
            filterable:false,      
                  renderCell: renderEdit,
                  width:170,

             },
            //  {
            //                 field: 'Download',
            //                 headerName: ' ',
            //                 sortable:false,
            //                 width:170,
            //                 filterable:false,      
            //                       renderCell: renderRedeem,
            //                  },
//              {
//               field: 'Redeem',
//               headerName: ' ',
//               sortable:false,
//               width:170,
//               filterable:false,      
//                     renderCell: renderRedeem,
//                },
//                {
//                 field: 'Freeze',
//                 headerName: ' ',
// sortable:false,
//             filterable:false,              
//               renderCell: renderFreeze,
//                 width: 160, 
//                  },
//                  {
//                     field: 'Jobs Completed',
//                     headerName: ' ',
//     sortable:false,
//                 filterable:false,              
//                   renderCell: renderCompleted,
//                     width: 190, 
//                      },
        ];

const backDrivers=()=>{
  setOpenC(false)
  setJob('')
  setJobID('')
  setOpenP(false)
}
  const handleSubmit_Freeze=()=>{


    setRedeem(true)
    const formData=new FormData()
    formData.append('driver_id',job_id.driver_id)
    formData.append('message',data.freeze)
    
    
    fetch(live+'driver-major',{
      method:'POST',
      body:formData
    }).then(res=>res.json()).then(resp=>freezeRes(resp))
    
    
            }
    
    const freezeRes=(val)=>{
      if(val.status){
        setRedeem(false)
        setSubmitted(true)
        // setReload(reload+1)
    
        setTimeout(()=>timeOutFreeze(),1500)
        
      }
      else{
        setJobID('')
    
        setRedeem(false)
        
    
      }
    
    }
    
    const timeOutFreeze=()=>{

    setSubmitted(false)
    setOpenF(false)
    backPressed()

    setJobID('')

    }
    const backPressed1=()=>{
      setEdit(false)
    }






    const creditsHistory=(val)=>{
      // console.log(val)
      setProcessing(true)
      setOpenP(true)
      setJobID(val)

      fetch(live+`driver-major?q=${val.driver_id}`).then((res)=>res.json()).then(resp=>creditsHistoryResp(resp))


    }
    const creditsHistoryResp=(val)=>{
      if(val.status){

        setJob(val.data)
        setProcessing(false)

      }
      else{
        setProcessing(false)

      }
    }


    const myRef=useRef(null)
    useEffect(() => {
      if(myRef&&myRef.current&&edit){
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  
      
      }      
           
            }, [edit])



    return(


    
        <Grid container style={{textAlign:'start'}}>
 <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>

  <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Delete()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Delete job permanently
</AlertTitle>

      </Alert>
     
     
      </Snackbar>


      <Dialog
    fullScreen
    open={managed?true:false}
    onClose={()=>setOpenC(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
      {
      <Grid item style={{background:'var(--green)'}} sm={12} xs={12}>
                        <Typography style={{fontSize:'1.6rem',color:'white'}}>Manage Driver {managed&&managed.row.driver_id}</Typography>

       </Grid>


   
     }
     <Grid item style={{textAlign:'end',marginTop:'2vh',paddingRight:'1vw'}} sm={12} xs={12}>
                        <Chip style={{fontSize:'1rem',color:'white',background:'var(--green)',cursor:'pointer'}} onClick={()=>backPressed()} label="Back to Dashboard" />

       </Grid>
     <Container style={{marginTop:'5vh'}}>
      <Grid container>
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>Edit(managed.row.driver_id)} className={'dash_sub'}>
        Profile
        </Paper>
        </Grid>
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>Freeze(managed.row)} className={'dash_sub'}>
        {managed&&managed.row.is_active?"Freeze":'Unfreeze'}
        </Paper>
        </Grid>
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>Driver(managed.row)} className={'dash_sub'}>
        {'Redeem Credits'}
        </Paper>
        </Grid>
        
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>completeClicked(managed.row.driver_id)} className={'dash_sub'}>
       Jobs Completed
        </Paper>
        </Grid>

        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>creditsHistory(managed.row)} className={'dash_sub'}>
        Credits history
        </Paper>
        </Grid>
        <div ref={myRef}>
        
      <Grid item sm={12} xs={12}>
      {edit?<Driver_details edit={edit} backPressed={backPressed} backPressed1={backPressed1} />:null}
              </Grid>
              </div>
      </Grid>
     </Container>

    </Grid>
   
  </Dialog>

      <Dialog
        open={openR}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Redeem credits for driver {job_id.driver_id}         
          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
          <div className="Floating_Form2">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
                <div className="TF_div">
           <label className="TF_Label">Account Name : <b>{job_id.account_name}</b></label>
           <br/>
           <label className="TF_Label">Bank Name : <b>{job_id.bank_name}</b></label>
<br/>
<label className="TF_Label">Sort Code : <b>{job_id.sort_code}</b></label><br/>
<label className="TF_Label">Account Number : <b>{job_id.account_number}</b></label>


            </div>

            <div className="TF_div">
                <label className="TF_Label">Payment Reference Id*</label>
                <TextValidator
                    label=""
                    onChange={(e)=>setData({...data,
                      id:e.target.value
                  })}
                  name="id"
                  value={data.id}
                  
                    validators={['required']}
                    errorMessages={['Reference id required']}  
                />  
            </div>
            <div className="TF_div">
              <label className="TF_Label">Credits*</label>
              <TextValidator
                      label=""
                      
                      onChange={(e)=>setData({...data,
                        credits:e.target.value
                    })}  name="credits"
                      value={data.credits}
                      validators={['required']}
                      errorMessages={['Credits required']}
                  />
            </div>
           
            <div className="TF_div">
             
                </div>
         
      
          

   
        
          
            
           
           
              
              <div className="Button_UL"></div>

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted||redeem}
                >
                   {
                    submitted&&!redeem?'Redeemed':redeem?'Redeeming':'Redeem credits'
                    }
                </Button>       
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openD}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={()=>handleCloseD()}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={()=>handleCloseD()}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{job&&job.credits_history?'Downloaded':'Downloading credits history'}          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
         {job&&job.credits_history? <div className="Floating_Form2">
          <CSVDownload filename='credits' headers={headers} data={d_data}  />
        </div>: <Box sx={{ display: 'flex',justifyContent:'center' }} >

<CircularProgress style={{marginTop:'2vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
</Box>}
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>
      <Dialog
    fullScreen
    open={openC}
    onClose={()=>setOpenC(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
      {processing?
      <Grid item sm={12} xs={12}>
                        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>Jobs completed by driver {job_id}</Typography>

        <Box sx={{ display: 'flex',justifyContent:'center' }} >

      <CircularProgress style={{marginTop:'5vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box></Grid>:!processing&&!job[0]?<>
    <Grid item sm={12} xs={12}>
      
      <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'5vh'}}>{'No jobs completed by '}{job_id}</Typography>
    </Grid>
      <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>backDrivers()} className='pricing_plan_btn'>
      Back
      </Button>

      </Grid></>:
      <Grid container style={{justifyContent:'center'}}>
<Grid item>
<Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh',paddingBottom:'2vh'}}>Jobs completed by driver {job_id} </Typography>

  </Grid>
      <Box  className='box_transactions' >
                <DataGrid
                  rows={rows_completed}
                  columns={columns_completed}
                  pageSize={[15]}
                  rowsPerPageOptions={[15]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
              <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>backDrivers()} className='pricing_plan_btn'>
      Back
      </Button>
      </Grid>
              </Grid>}

    </Grid>
   
  </Dialog>





  <Dialog
        open={openF}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{job_id.is_active?'Freeze ':'Unfreeze ' }driver {job_id.driver_id}         
          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
          <div className="Floating_Form2">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit_Freeze}
            >
              
         
          {  job_id.is_active?<div className="TF_div">
              <label className="TF_Label">Reason for freeze*</label>
              <TextValidator
            multiline
            maxRows={2}
              
                      label=""
                      
                      onChange={(e)=>setData({...data,
                        freeze:e.target.value
                    })}  name="freeze"
                      value={data.freeze}
                      validators={['required']}
                      errorMessages={['Reason for freezing account required']}
                  />
            </div>:null
      }
        
           
              
              <div className="Button_UL"></div>

              {  job_id.is_active? <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted||redeem}
                >
                   {
                    submitted&&!redeem?'Freezeed':redeem?'Freezing':'Freeze Account'
                    }
                </Button> :<Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted||redeem}
                >
                   {
                    submitted&&!redeem?'Unfreezeed':redeem?'UnFreezing':'UnFreeze Account'
                    }
                </Button> }      
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>



      <Dialog
    fullScreen
    open={openP}
    onClose={()=>setOpenP(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center',justifyContent:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
   {<>
    <Grid item sm={12} xs={12}>
      
      <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'5vh',paddingBottom:'4vh'}}>{'Credits History of '}{job_id.driver_id}</Typography>
    </Grid>
{processing?
  <Grid item sm={12} xs={12}>
        <Box sx={{ display: 'flex',justifyContent:'center' }} >

      <CircularProgress style={{marginTop:'5vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box></Grid>
:<>
<Grid container>
    <Payments transactions={job.credits_history}/>
    </Grid>
      <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>backDrivers()} className='pricing_plan_btn'>
      Back
      </Button>

      </Grid>
      </>}
      </>
     }

    </Grid>
   
  </Dialog>
      {!edit?
       <Grid container style={{alignItems:'center',marginBottom:'5vh',textAlign:'center'}} >
<Grid item sm={12} xs={12}>
<TextField placeholder='Search drivers here ..' className='field_search' onChange={(e)=>setSearch(e.target.value)} />
</Grid>
<Grid container>
<Grid  style={{marginTop:'2vh',marginBottom:'2vh',textAlign:'end',paddingRight:'2vw'}}item sm={12} xs={12}>
        <Chip onClick={()=>props.closeLive()} label='Back to dashboard' style={{color:'white',background:'var(--green)'}}  />
      </Grid>
      </Grid>
{/* <Grid item sm={3} xs={4}>
<Button onClick={()=>setEdit(true)}  variant='contained' className='btn_new'>Create New Job</Button>
</Grid> */}
<Grid container>
{(drivers&&drivers[0]&&!processing&&!edit&&!search)||(drivers&&drivers[0]&&!processing&&!edit&&search&&search.length>2&&rows)?
<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!drivers[0]&&!processing)||(!rows&&!processing)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Drivers Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>

       </Grid>:
//        drivers&&drivers[0]&&!processing&&edit?     
//        <Driver_details edit={edit} backPressed={backPressed} />
//  :
       
       null}
       </Grid>
    


)
}