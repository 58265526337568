import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { live } from '../../App';
import { Alert, Box, Chip, CircularProgress, Container, Grid, Snackbar, Typography } from '@mui/material';
import './job_view.css'
export default function Job_View() {
    const [processing,setProcessing]=useState(true)
    const [job,setJob]=useState()
    let { id } = useParams();
const [open,setOpen]=useState(false)
const handleClose=()=>{
  setOpen(false)
}
const [msg,setMsg]=useState('')
    const copyUrl=()=>{
      const el = document.createElement('textarea');
      el.value =`https://drivewide.co.uk/jobs/${job.job_id}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    setMsg('Job url copied')
    setOpen(true)
    }
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
    
        if (id) {
          // setSuccess(true);
fetch(live+`jobs-operations?job_id=${id}`).then(res=>res.json()).then(resp=>jobRes(resp)).catch(err=>window.location.href='/')
        }
    
        else{

        }
       
      }, [id]);


      const jobRes=(resp)=>{
        if(resp.data&&resp.data.is_active){
setJob(resp.data)
setProcessing(false)
        }
        else{
            window.location.href='/'
        }
        // console.log(resp)
      }
  return (
    <Container style={{minHeight:'100vh'}}>
        <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{msg}</Alert>       
  </Snackbar>
       {processing?
       <Grid container style={{textAlign:'center'}}>
      <Grid item sm={12} xs={12}>
      <Box sx={{ display: 'flex',justifyContent:'center' }} >
      <CircularProgress style={{marginTop:'40vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box>
      </Grid>
    </Grid>: <Grid container>
            <Grid item sm={12} xs={12}>
<Typography className='job_head'>
{job.job_title} {job.urgent_job?<span><Chip style={{color:'white',background:'var(--green)',marginLeft:'4vw'}} label={'Hot Job'} /></span>:null}
</Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
<Typography style={{marginTop:'2vh'}} className='job_company'>
{job.company_name}
</Typography>
            </Grid>
            <Grid container sm={12} xs={12}>
              <Grid item sm={6} xs={6}>
<Typography className='job_location'>
{job.town_name+', '+job.post_code} 
</Typography>
</Grid>
<Grid item textAlign={'end'} sm={6} xs={6}>
  <Grid item sm={12} xs={12}>
  <Typography style={{paddingRight:'17%'}} className='job_location'>
Share Via
</Typography>
  </Grid>

<Grid item  sm={12} xs={12}>
<span  onClick={()=>copyUrl()} className="fas fa-paste circle-icon12"></span>
                        <a href="https://www.apple.com/in/app-store/"  className="fab fa-app-store circle-icon12">
                      
                      
                        </a>
                        <a href="https://play.google.com/store/apps"  className="fab fa-google-play circle-icon12">
</a> 
    </Grid>
</Grid>

            </Grid>
            <Grid item sm={12} xs={12}>
<Typography className='job_details'>
Job Details <span style={{color:'var(--green)'}}>({job.job_id})</span>
</Typography>
            </Grid>
            <Grid container style={{marginTop:'1vh'}} sm={12} xs={12}>
            <Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',textAlign:'center',height:'100%',paddingTop:5}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-money-bill-wave"></i>
            
            </Grid>
            <Grid container sm={9} xs={9}>
                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Pay Rate
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                Paying £{job.pay_rate}/{job.pay_rate_type}
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            <Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',textAlign:'center',height:'100%',paddingTop:5}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-briefcase"></i>
            
            </Grid>
            <Grid container sm={9} xs={9}>
                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Job Type
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                {job.job_type}
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            <Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',textAlign:'center',height:'100%',paddingTop:5}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-user-graduate"></i>
            
            </Grid>
            <Grid container sm={9} xs={9}>
                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Experience
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                {(job.experience_years>0?(job.experience_years+' Year '):'')+(job.experience_months>0?(job.experience_months+' Month'):'')}
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            <Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',textAlign:'center',height:'100%',paddingTop:5}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-check-double"></i>
            
            </Grid>
            <Grid style={{alignSelf:'baseline'}} container sm={9} xs={9}>
                <Grid  item sm={12} xs={12}>
              <Typography className='job_company'>
                Skills
                </Typography> 
                </Grid> 
                <Grid display={'flex'} item sm={12} xs={12}>
                {job.skills&&job.skills[0]&&job.skills.map((val,idx)=>
  <Typography style={{marginRight:'2vw'}} className='job_salary' key={idx} >
{val.skill}</Typography>
)}
             
                </Grid>
            </Grid>
            </Grid>

            
            <Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',textAlign:'center',height:'100%',paddingTop:5}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-id-badge"></i>
            
            </Grid>
            <Grid container style={{alignSelf:'baseline'}} sm={9} xs={9}>
                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Minimum License Requirement
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                               
                               {job.licence_category[0].category} </Typography>  
                
                        
                </Grid>

            </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
<Typography className='job_details'>
Timings & Schedule</Typography>
            </Grid>
            <Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',textAlign:'center',height:'100%',paddingTop:5}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-calendar-alt"></i>
            
            </Grid>
            <Grid container sm={9} xs={9}>
                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Job Duration
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                From {(new Date(job.job_start_date).getDate()).toString().padStart(2, '0')+'/'+(new Date(job.job_start_date).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(job.job_start_date).getFullYear()} {job.job_start_time.substring(0,5)}
                                </Typography>  
                
                                <Typography className='job_salary'>
                To {(new Date(job.job_end_date).getDate()).toString().padStart(2, '0')+'/'+(new Date(job.job_end_date).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(job.job_end_date).getFullYear()} {job.job_end_time.substring(0,5)}
                                </Typography> 
                </Grid>

            </Grid>
            </Grid>
           
            <Grid style={{alignItems:'baseline'}} className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',height:'100%',paddingTop:5,textAlign:'center'}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-stopwatch"></i>
            
            </Grid>
            <Grid container sm={9} xs={9}>

                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Reporting Time
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                {(new Date(job.job_reporting_date).getDate()).toString().padStart(2, '0')+'/'+(new Date(job.job_reporting_date).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(job.job_reporting_date).getFullYear()} {job.job_reporting_time.substring(0,5)}
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            <Grid style={{alignItems:'baseline'}} className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center',height:'100%',paddingTop:5,textAlign:'center'}} sm={3} xs={3}>
            <i style={{color:'var(--green)',fontSize:'20px'}} class="fas fa-user-clock"></i>
            
            </Grid>
            <Grid container sm={9} xs={9}>

                <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Last Acceptance
                </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                {(new Date(job.last_acceptance_date).getDate()).toString().padStart(2, '0')+'/'+(new Date(job.last_acceptance_date).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(job.last_acceptance_date).getFullYear()} {job.last_acceptance_time.substring(0,5)}
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            <Grid className='cont_job_details'container sm={12} xs={12}>
           
            <Grid container sm={12} xs={12}>
           
                <Grid item sm={12} xs={12}>
              <Typography className='job_details'>
                Job Description
                </Typography> 
                </Grid> 
                <Grid item style={{maxHeight:'40vh',overflowY:'scroll'}} sm={12} xs={12}>

                <Typography dangerouslySetInnerHTML={{__html:job.job_description}} className='job_description'>
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
<Typography className='job_details'>
Company Description
</Typography>
            </Grid>
            {job.company_website?<Grid className='cont_job_details'container sm={4} xs={6}>
            <Grid item style={{alignSelf:'center'}} sm={2} xs={2}>
            <i style={{color:'var(--green)',fontSize:'20px',marginTop:'2vh'}} class="fas fa-globe"></i>
            
            </Grid>
            <Grid container sm={10} xs={10}>

            
                <Grid item sm={12} xs={12}>

                <Typography className='job_salary'>
                {job.company_website}
                </Typography>  
                </Grid>
            </Grid>
            </Grid>:null}
            <Grid className='cont_job_details'container sm={12} xs={12}>
           
            <Grid container sm={12} xs={12}>
           
                {/* <Grid item sm={12} xs={12}>
              <Typography className='job_company'>
                Full Company Description
                </Typography> 
                </Grid>  */}
                <Grid item style={{maxHeight:'40vh',overflowY:'scroll',marginBottom:'5vh'}} sm={12} xs={12}>

                <Typography dangerouslySetInnerHTML={{__html:job.company_description}} className='job_description'>
                </Typography>  
                </Grid>
            </Grid>
            </Grid>
            </Grid>

        </Grid>}
    </Container>
  )
}
