import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React,{ useEffect, useState,useRef } from 'react';
import { live } from '../../App';

import {useCookies} from 'react-cookie'


import './jobs.css'

export default function Business_Low_Credits(props){
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const ref=useRef()

  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
  const [search,setSearch]=useState('')

   const [processing,setProcessing]=useState(true)
   const [drivers,setDrivers]=useState([])
   const [edit,setEdit]=useState(false)
   const[errMsg,setError]=useState(false)
   const [open,setOpen]=useState(false)
   const [open1,setOpen1]=useState(false)
   const [open2,setOpen2]=useState(false)
   const [job_id,setJobID]=useState('')
   const [openF,setOpenF]=useState(false)
const [notify,setNotify]=useState('')
   const [reload,setReload]=useState(0)
    useEffect(() => {
      setProcessing(true)
        fetch(live+`business?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>driversResp(resp))
        }, [reload])

        const driversResp=(val)=>{

{
  if(val.data){
    setProcessing(false)
    setDrivers(val.data.filter(val=>parseFloat(val.credits)<100))
}

else{
    setProcessing(false)
    setOpen(true)
    setError(val.detail)
    setDrivers([])

}
             }

            
            
            

        }

     



const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setJobID('')
  setOpenF(false)


}


const Notify=(val)=>{
  setNotify(val.row.business_id)
  const formData=new FormData()
  formData.append('business',val.row.business_id)
  fetch(live+'payments',{
    method:'POST',
    body:formData
  }).then(res=>res.json()).then(resp=>notifyRes(resp))

}
const notifyRes=(val)=>{
if(val.status){
  setError(val.detail)
  setOpen1(true)

}
else{
  setError('Error in sending mail')

  setOpen(true)
}
}

  
  

        const renderEdit = (val) => {
          // console.log(val.row.published_on)
          
            return (
                <strong>
              
       <Button onClick={()=>Notify(val)}  className='btn_evd2'>{notify===val.row.business_id?'Notifing':'Notify'}</Button>

                </strong>
            )
          }
    
          const renderUrgent = (val) => {
            return (
                <strong>
                  {val.row.is_verified?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
                </strong>
            )
          }

    
      
      
      

     
        const rows= drivers&&drivers[0]&&!search?(drivers.map((item,k) => {
            return  {
        
              id:k+1,
              business_id:item.business_id,
              fname:item.fname,
              lname:item.lname,
              email:item.email,
              phone:item.country_code+' '+item.mobile,
              credits:'£'+item.credits,
              is_verified:item.is_verified,
              
              credits_history:item.credits_history,

              is_active:item.is_active

        
        
        
              
        
        
        
             
            }
        }
          )):drivers&&drivers[0]&&search&&search.length>2&&drivers.filter(val=>((val.business_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))))&&drivers.filter(val=>((val.business_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))))[0]?drivers.filter(val=>((val.business_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase())))).map((item,k) => {
            return  {
        
              id:k+1,
              business_id:item.business_id,
              fname:item.fname,
              lname:item.lname,
              email:item.email,
              phone:item.country_code+' '+item.mobile,
              credits:'£'+item.credits,
              is_verified:item.is_verified,
              
              credits_history:item.credits_history,

              is_active:item.is_active

              

        
        
        
              
        
        
        
             
            }
        }
          ):''
        const columns = [
          { field: 'id', headerName: 'S No.', width: 60 },
          { field: 'business_id', headerName: 'Business ID'},
        
          {
            field: 'fname',
            headerName: 'First Name',
           
          },
          {
            field: 'lname',
            headerName: 'Last Name',
          sortable:false,
          filterable:false
          
          },
          // {
          //   field: 'company_name',
          //   headerName: 'Company Name',
          //   // type: 'number',
          // },
          {
            field: 'email',
            headerName: 'Email',
            width: 180,  

            // type: 'number',
          }, {
            field: 'phone',
            headerName: 'Mobile',
            // type: 'number',
            width: 180,  


          }
          , {
            field: 'credits',
            headerName: 'credits',
            // type: 'number',
            width: 180,  


          },
         

           {
            field: 'is_verified',
            headerName: 'Verified',
            // type: 'number',
            sortable:false,
            filterable:false,
                        renderCell: renderUrgent,
            width: 100,  
          },
          
          
        
      
        
          {
            field: 'Edit',
            headerName: ' ',
            sortable:false,
            filterable:false,  
                                width: 160, 

                  renderCell: renderEdit,
             },
     

        ];

      
      
      
            
      
      
      
  

   
  

    return(


    
        <Grid container style={{textAlign:'start'}}>
        
 <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>

 
   

      {!edit? <Grid container style={{alignItems:'center',marginBottom:'5vh',textAlign:'center'}} >
<Grid item sm={12} xs={12}>
<TextField placeholder='Search businesses here ..' onChange={(e)=>setSearch(e.target.value)} className='field_search' />
</Grid>
{/* <Grid item sm={3} xs={4}>
<Button onClick={()=>setEdit(true)}  variant='contained' className='btn_new'>Create New Job</Button>
</Grid> */}
<Grid container>
<Grid  style={{marginTop:'2vh',marginBottom:'2vh',textAlign:'end',paddingRight:'2vw'}}item sm={12} xs={12}>
        <Chip onClick={()=>props.closeLive()} label='Back to dashboard' style={{color:'white',background:'var(--green)'}}  />
      </Grid>
      </Grid>
<Grid container>
{(drivers&&drivers[0]&&!processing&&!edit&&!search)||((drivers&&drivers[0]&&!processing&&!edit&&search&&search.length>2&&rows))?
<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!drivers[0]&&!processing)||(!processing&&!rows)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Business With Low Credits Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>

       </Grid>:
       
null}       </Grid>
    


)
}