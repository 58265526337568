import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React,{ useEffect, useState,useRef } from 'react';
import { live } from '../../App';

import {useCookies} from 'react-cookie'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CloseIcon from '@mui/icons-material/Close';

import './jobs.css'
import Job_post from './job_post';
import Job_post_Edit from './job_post_edit';
import Business_details from './business_details';
import Payments from './payments';
import Driver_details from './driver_details';
export default function Business_dash(props){
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const ref=useRef()

  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
  const [search,setSearch]=useState('')

   const [processing,setProcessing]=useState(true)
   const [drivers,setDrivers]=useState([])
   const [duplicate,setDuplicate]=useState(false)
   const [edit,setEdit]=useState(false)
   const[errMsg,setError]=useState(false)
   const [open,setOpen]=useState(false)
   const [open1,setOpen1]=useState(false)
   const [open2,setOpen2]=useState(false)
   const [del,setDel]=useState(false)
   const [job_id,setJobID]=useState('')
   const [job,setJob]=useState('')
   const [openC,setOpenC]=useState(false)
   const [openP,setOpenP]=useState(false)
   const [openF,setOpenF]=useState(false)
   const [redeem,setRedeem]=useState(false)
   const [submitted,setSubmitted]=React.useState(false)
const [data,setData]=useState({})
const [managed,setManaged]=useState('')
const [subscription,setSubscription]=useState(false)
   const [reload,setReload]=useState(0)
   const {verified}=props
   const [business,setBusiness]=useState('')
    useEffect(() => {
      setProcessing(true)
        fetch(live+`business?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>driversResp(resp))
        }, [reload])

       
       
       
        useEffect(() => {
          if(managed.row&&managed.row.business_id){
          //  setProcessing(true)
           fetch(live+`business-major?q=${managed.row.business_id}`).then((res)=>res.json()).then(resp=>setBusiness(resp.data))
          }
         }, [managed,reload])
        const driversResp=(val)=>{

{
  if(val.data&&verified){
    setProcessing(false)
    setDrivers(val.data.filter(val=>val.is_verified))
}
else if(val.data&&!verified){
  setProcessing(false)
  setDrivers(val.data.filter(val=>!val.is_verified))
}
else{
    setProcessing(false)
    setOpen(true)
    setError(val.detail)
    setDrivers([])

}
             }

            
            
            

        }

        const Edit=(id,val2)=>{
if(val2){
  setDuplicate(true)
  setEdit(drivers.filter(val=>val.business_id===id)[0])

}
else{
  setDuplicate(false)

  setEdit(drivers.filter(val=>val.business_id===id)[0])

}



        }

const backPressed=()=>{
    setProcessing(true)
setDuplicate(false)
    setReload(reload+1)
    setEdit(false)
    setManaged(false)
    setSubscription(false)

}

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setJobID('')
  setOpenF(false)


}
const Delete=(val)=>{

  
  if(job_id){
    setOpen2(false)
    setDel(true)
    const formdata=new FormData()
      formdata.append(`job_id`,job_id)
    fetch(live+'jobs',
    {
      method:'Delete',
      body:formdata
    }).then(res=>res.json()).then((resp)=>delRes(resp))
  
  
  
  }
  else{
    setJobID(val)
  setOpen2(true)
  }
  }
  
  const delRes=(resp)=>{
    if(resp.status){
  setDel(false)
  setError(resp.detail)
  setReload(reload+1)
  setOpen1(true)
  setJobID('')
    }
    else{
      setDel(false)
  
      setError(resp.detail)
      setOpen(true)
      setJobID('')
  
  
    }
  
  }


  const rows_completed= job&&job[0]?job.map((item,k) => {
    return  {
     job_id:item.job_id,
  
      id:k+1,
      job_title:item.job_title,
      job_type:item.job_type,
      town_name:item.town_name,
      post_code:item.post_code,
      company_name:item.company_name,
      
      job_start_date:item.job_start_date+' '+item.job_start_time,
      job_start_time:item.job_start_time,
      job_end_date:item.job_end_date+' '+item.job_end_time,
      job_end_time:item.job_end_time,
      driver_name:(item.completed[0].fname+' '+item.completed[0].lname),
      driver_id:(item.completed[0].driver_id),

      completed_on:(item.completed[0].completed_on).substring(0,10)+' '+(item.completed[0].completed_on).substring(11,16),
      
      
      credits:'£'+item.total_pay,
     
  
  
  
  
      
  
  
  
     
    }
  }
  ):''
  
  
  
  const columns_completed = [
    { field: 'id', headerName: 'S No.', width: 60 },
    { field: 'job_id', headerName: 'Job ID'},
  
    {
      field: 'job_title',
      headerName: 'Job Title',
     
    },
  
    {
      field: 'company_name',
      headerName: 'Company Name',
      // type: 'number',
      width:170,
  
    },
    {
      field: 'job_start_date',
      headerName: 'Job Start',
      width: 180,  
  
      // type: 'number',
    }, {
      field: 'job_end_date',
      headerName: 'Job End',
      // type: 'number',
      width: 180,  
  
    },
    {
      field: 'driver_id',
      headerName: 'Completed By',
      width:170,
  
      // type: 'number',
    },
    {
      field: 'driver_name',
      headerName: 'Driver',
      width:170,
  
      // type: 'number',
    },
    {
      field: 'completed_on',
      headerName: 'Completed On',
      width:170,
  
      // type: 'number',
    },
     
  
    {
      field: 'credits',
      headerName: 'Job Credits',
      width:170,
  
      // type: 'number',
    },
  
    // {
    //   field: 'town_name',
    //   headerName: 'Town Name',
    //   // type: 'number',
    // },
    // {
    //   field: 'post_code',
    //   headerName: 'Post Code',
    //   // type: 'number',
    // },
    
  
    
  
   
       
        
  ];
        const renderEdit = (val) => {
          // console.log(val.row.published_on)
          
            return (
                <strong>
                                            
                                             {/* <Tooltip title={val.row.published_on?'Edit disabled as job is published':'' }> */}
{/* <span>                 */}
       <Button onClick={()=>setManaged(val)}  className='btn_evd2'>Manage</Button>
{/* </span> */}

                {/* </Tooltip> */}
                </strong>
            )
          }
    
          const renderUrgent = (val) => {
            return (
                <strong>
                  {val.row.is_verified?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
                </strong>
            )
          }

     
        //   const renderDelete = (val) => {
        //               // console.log(val.row.job_reporting_date.replace(' ','T'))
        //               const oneDay1 = 24 * 60 * 60 * 1000; 
        //               const firstDate1= new Date();
        //               const secondDate1 = new Date(val.row.job_reporting_date.replace(' ','T'));
                      
        //               const diffDays1 = ((( secondDate1-firstDate1) / oneDay1));
                
        //     return (
        //         <strong>
        //                    <Tooltip title={diffDays1>0?'':'Delete disabled as reporting time is passed' }>
        //                    <span>
        //                    <Button  onClick={()=>Delete(val.row.job_id)} disabled={diffDays1>0?false:true} className='btn_evd3'>{job_id===val.row.job_id&&del?'Deleting':'Delete'}
                          

        //                    </Button>
        //                    </span>
        //                    </Tooltip>
        //            {/* <Button onClick={()=>Delete(val.row.eventID)} className='btn_evd3'>Delete</Button> */}
        //         </strong>
        //     )
        //   }
      
          const Freeze=(val)=>{
            // if (val.row.is_active){
              setJobID(val)
              setOpenF(true)

            // }

          }

      
          const  completeClicked=(val)=>{
            setJobID(val)     
            setProcessing(true) 
            setOpenC(true) 
            fetch(live+`jobs?q=${val}&q2=123`).then(res=>res.json()).then(resp=>completedRes(resp))
            
            }
            const completedRes=(res)=>{
              if (res.status){
                setJob(res.data.filter(val=>val.job_status==='Completed Successfully'))
                setProcessing(false)
              }
              else{
                setProcessing(false)
            
              }
            
            }

      
          const creditsHistory=(val)=>{
            // console.log(val)
            setProcessing(true)
            setOpenP(true)
            setJobID(val)

            fetch(live+`business-major?q=${val.business_id}`).then((res)=>res.json()).then(resp=>creditsHistoryResp(resp))


          }
          const creditsHistoryResp=(val)=>{
            if(val.status){

              setJob(val.data)
              setProcessing(false)

            }
            else{
              setProcessing(false)

            }
          }
        const rows= drivers&&drivers[0]&&!search?(drivers.map((item,k) => {
            return  {
        
              id:k+1,
              business_id:item.business_id,
              fname:item.fname,
              lname:item.lname,
              email:item.email,
              phone:item.country_code+' '+item.mobile,
              credits:'£'+item.credits,
              is_verified:item.is_verified,
              
              credits_history:item.credits_history,

              is_active:item.is_active

        
        
        
              
        
        
        
             
            }
        }
          )):drivers&&drivers[0]&&search&&search.length>2&&drivers.filter(val=>((val.business_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))))&&drivers.filter(val=>((val.business_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase()))))[0]?drivers.filter(val=>((val.business_id.toLowerCase().includes(search.toLowerCase()))||(val.fname.toLowerCase().includes(search.toLowerCase()))||(val.email.toLowerCase().includes(search.toLowerCase())))).map((item,k) => {
            return  {
        
              id:k+1,
              business_id:item.business_id,
              fname:item.fname,
              lname:item.lname,
              email:item.email,
              phone:item.country_code+' '+item.mobile,
              credits:'£'+item.credits,
              is_verified:item.is_verified,
              
              credits_history:item.credits_history,

              is_active:item.is_active

              

        
        
        
              
        
        
        
             
            }
        }
          ):''
        const columns = [
          { field: 'id', headerName: 'S No.', width: 60 },
          { field: 'business_id', headerName: 'Business ID'},
        
          {
            field: 'fname',
            headerName: 'First Name',
           
          },
          {
            field: 'lname',
            headerName: 'Last Name',
          sortable:false,
          filterable:false
          
          },
          // {
          //   field: 'company_name',
          //   headerName: 'Company Name',
          //   // type: 'number',
          // },
          {
            field: 'email',
            headerName: 'Email',
            width: 180,  

            // type: 'number',
          }, {
            field: 'phone',
            headerName: 'Mobile',
            // type: 'number',
            width: 180,  


          }
          , {
            field: 'credits',
            headerName: 'credits',
            // type: 'number',
            width: 180,  


          },
         

           {
            field: 'is_verified',
            headerName: 'Verified',
            // type: 'number',
            sortable:false,
            filterable:false,
                        renderCell: renderUrgent,
            width: 100,  
          },
          
          
        
      
        
          {
            field: 'Edit',
            headerName: ' ',
            sortable:false,
            filterable:false,  
                                width: 160, 

                  renderCell: renderEdit,
             },
 
        ];

   
      const backDrivers=()=>{
        setOpenP(false)
        setOpenC(false)
        setJob('')
        setJobID('')}


        const handleSubmit_Freeze=()=>{


          setRedeem(true)
          const formData=new FormData()
          formData.append('business_id',job_id.business_id)
          formData.append('message',data.freeze)
          
          
          fetch(live+'business-major',{
            method:'POST',
            body:formData
          }).then(res=>res.json()).then(resp=>freezeRes(resp))
          
          
                  }
          
          const freezeRes=(val)=>{
            if(val.status){
              setRedeem(false)
              setSubmitted(true)
              setReload(reload+1)
          
              setTimeout(()=>timeOutFreeze(),1500)
              
            }
            else{
              setJobID('')
          
              setRedeem(false)
              
          
            }
          
          }
          
          const timeOutFreeze=()=>{
      
          setSubmitted(false)
          setOpenF(false)
          setJobID('')
      
          }
          const backPressed1=()=>{
            setEdit(false)
            setSubscription(false)
          }

          const Subscription=()=>{
            setSubscription(true)
            setEdit(false)
          }
    return(


    
        <Grid container style={{textAlign:'start'}}>
             <Dialog
    fullScreen
    open={openC}
    onClose={()=>setOpenC(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
      {processing?
      <Grid item sm={12} xs={12}>
                        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>Completed jobs for business {job_id}</Typography>

        <Box sx={{ display: 'flex',justifyContent:'center' }} >

      <CircularProgress style={{marginTop:'5vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box></Grid>:!processing&&!job[0]?<>
    <Grid item sm={12} xs={12}>
      
      <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'5vh'}}>{'No completed jobs found for '}{job_id}</Typography>
    </Grid>
      <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>backDrivers()} className='pricing_plan_btn'>
      Back
      </Button>

      </Grid></>:
      <Grid container style={{justifyContent:'center'}}>
<Grid item>
<Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh',paddingBottom:'2vh'}}>Completed jobs for business {job_id} </Typography>

  </Grid>
      <Box  className='box_transactions' >
                <DataGrid
                  rows={rows_completed}
                  columns={columns_completed}
                  pageSize={[15]}
                  rowsPerPageOptions={[15]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
              <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>backDrivers()} className='pricing_plan_btn'>
      Back
      </Button>
      </Grid>
              </Grid>}

    </Grid>
   
  </Dialog>

  <Dialog
    fullScreen
    open={openP}
    onClose={()=>setOpenP(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center',justifyContent:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
   {<>
    <Grid item sm={12} xs={12}>
      
      <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'5vh',paddingBottom:'4vh'}}>{'Credits History of '}{job_id.business_id}</Typography>
    </Grid>
{processing?
  <Grid item sm={12} xs={12}>
        <Box sx={{ display: 'flex',justifyContent:'center' }} >

      <CircularProgress style={{marginTop:'5vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box></Grid>
:<>
<Grid container>
    <Payments transactions={job.credits_history}/>
    </Grid>
      <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>backDrivers()} className='pricing_plan_btn'>
      Back
      </Button>

      </Grid>
      </>}
      </>
     }

    </Grid>
   
  </Dialog>


  <Dialog
        open={openF}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{job_id.is_active?'Freeze ':'Unfreeze ' }business {job_id.business_id}         
          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
          <div className="Floating_Form2">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit_Freeze}
            >
              
         
          {  job_id.is_active?<div className="TF_div">
              <label className="TF_Label">Reason for freeze*</label>
              <TextValidator
            multiline
            maxRows={2}
              
                      label=""
                      
                      onChange={(e)=>setData({...data,
                        freeze:e.target.value
                    })}  name="freeze"
                      value={data.freeze}
                      validators={['required']}
                      errorMessages={['Reason for freezing account required']}
                  />
            </div>:null
      }
        
           
              
              <div className="Button_UL"></div>

              {  job_id.is_active? <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted||redeem}
                >
                   {
                    submitted&&!redeem?'Freezeed':redeem?'Freezing':'Freeze Account'
                    }
                </Button> :<Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted||redeem}
                >
                   {
                    submitted&&!redeem?'Unfreezeed':redeem?'UnFreezing':'UnFreeze Account'
                    }
                </Button> }      
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>

      <Dialog
    fullScreen
    open={managed?true:false}
    onClose={()=>setOpenC(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
      {
      <Grid item style={{background:'var(--green)'}} sm={12} xs={12}>
                        <Typography style={{fontSize:'1.6rem',color:'white'}}>Manage Business {managed&&managed.row.business_id}</Typography>

       </Grid>


   
     }
     <Grid item style={{textAlign:'end',marginTop:'2vh',paddingRight:'1vw'}} sm={12} xs={12}>
                        <Chip style={{fontSize:'1rem',color:'white',background:'var(--green)',cursor:'pointer'}} onClick={()=>backPressed()} label="Back to Dashboard" />

       </Grid>
     <Container style={{marginTop:'5vh'}}>
      <Grid container>
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>Edit(managed.row.business_id)} className={'dash_sub'}>
        Profile
        </Paper>
        </Grid>
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>Freeze(managed.row)} className={'dash_sub'}>
        {managed&&managed.row.is_active?"Freeze":'Unfreeze'}
        </Paper>
        </Grid>
     
        
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>completeClicked(managed.row.business_id)} className={'dash_sub'}>
       Jobs Completed
        </Paper>
        </Grid>

        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>creditsHistory(managed.row)} className={'dash_sub'}>
        Credits history
        </Paper>
        </Grid>
        <Grid item sm={3} xs={6}>
        <Paper elevation={6} onClick={()=>Subscription()} className={'dash_sub'}>
        Subscription
        </Paper>
        </Grid>
       {edit?<Grid item sm={12} xs={12}>
        <Business_details edit={edit} backPressed={backPressed} backPressed1={backPressed1} />
        </Grid>:null}
        {!edit&&subscription&&business?
  business.subscription_id?
  (<Grid container style={{padding:20,textAlignLast:'start'}}>
      <Grid style={{padding:'2vh 0px',alignItems:'start',justifyContent:'center'}} container sm={12} xs={12}>
      <Grid item style={{maxWidth:'1200px',marginBottom:'3vh'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)',fontSize:'1.5rem'}}>Subscription Details </b> <span><Chip  style={{backgroundColor:'darkgray',color:'white',marginLeft:'2vw'}}  onClick={()=>window.location.href='/for_business'} label='UPGRADE'/></span>
        </Typography>
        </Grid>
        {/* <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)'}}>Subscription ID : </b>{business.subscription_id}
        </Typography>
        </Grid> */}
        <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)'}}>Subscription Type : </b>{'£'+business.amount+'/'+business.payment_intervals+business.plan_type}
        </Typography>
        </Grid>

        <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)'}}>Started On : </b>{(business.subscription_start).substring(0,10)+' '+(business.subscription_start).substring(11,16)}
        </Typography>
        </Grid>
        <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)'}}>Renewal On : </b>{(business.renewal_on).substring(0,10)+' '+(business.renewal_on).substring(11,16)}
        </Typography>
        </Grid>
        <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)'}}>Subscription Status : </b> {business.subscription_active?<span style={{padding:'5px 12px',background:'var(--green)',color:'white',borderRadius:'25px'}}>Active</span>:<span style={{padding:'5px 12px',background:'red',color:'white',borderRadius:'25px'}}>Inactive</span>}
        </Typography>
        </Grid>
      </Grid>
      </Grid>):
      <Grid container>
      <Grid style={{padding:'2vh 0px',alignItems:'start',justifyContent:'center'}} container sm={12} xs={12}>
      <Grid item style={{maxWidth:'1200px',marginBottom:'3vh'}}sm={12} xs={12}>
       <Typography>
        <b style={{color:'var(--green)',fontSize:'1.5rem'}}>No active subscription </b>
        </Typography>
        </Grid>
      </Grid>
     </Grid>:null}
      </Grid>
     </Container>

    </Grid>
   
  </Dialog>
 <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>

  <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Delete()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Delete job permanently
</AlertTitle>

      </Alert>
     
     
      </Snackbar>
   

      {!edit? <Grid container style={{alignItems:'center',marginBottom:'5vh',textAlign:'center'}} >
<Grid item sm={12} xs={12}>
<TextField placeholder='Search businesses here ..' onChange={(e)=>setSearch(e.target.value)} className='field_search' />
</Grid>
{/* <Grid item sm={3} xs={4}>
<Button onClick={()=>setEdit(true)}  variant='contained' className='btn_new'>Create New Job</Button>
</Grid> */}
<Grid container>
<Grid  style={{marginTop:'2vh',marginBottom:'2vh',textAlign:'end',paddingRight:'2vw'}}item sm={12} xs={12}>
        <Chip onClick={()=>props.closeLive()} label='Back to dashboard' style={{color:'white',background:'var(--green)'}}  />
      </Grid>
      </Grid>
<Grid container>
{(drivers&&drivers[0]&&!processing&&!edit&&!search)||((drivers&&drivers[0]&&!processing&&!edit&&search&&search.length>2&&rows))?
<Box sx={{minHeight:'400px',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[15]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!drivers[0]&&!processing)||(!processing&&!rows)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      No Business Found
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>

       </Grid>:
       drivers&&drivers[0]&&!processing&&edit&&edit.business_id? <Business_details  edit={edit} backPressed={backPressed} />:
       
null}       </Grid>
    


)
}