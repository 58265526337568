import {Grid,Container,Typography,Chip, Dialog, DialogContent, IconButton} from '@mui/material';
import React, { useState } from 'react'
import './footer.css'
// import { Link, NavLink } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import Query_Form from './D6_SL_AF_Form';

const Footer = () => {
    const [open, setOpen] = React.useState(false);
    const [query,setQuery]=useState('')
    const handleClose = () => {
        setOpen(false);
    };
    const Open=(val)=>{
        setQuery(val)
        setOpen(true)
    }

    return (
 <Container style={{background:'#eee',maxWidth:'100%',textAlign:'-webkit-center'}}>

    
<Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your {query.toLocaleLowerCase()} here..            
          </Typography>
          <Query_Form query={query} />
        </DialogContent>
      </Dialog>
    <Grid container style={{maxWidth:1200}}>
        <Grid item  className='footer_1' sm={4} xs={12}>
           
            <Grid item sm={12} xs={12}>
                <Typography className='footer_head'>WHAT WE DO</Typography>
                </Grid>

                <Grid item sm={12} xs={12}>
                <Typography style={{fontSize:17,textAlign:'justify',padding:'1vh 0px'}}>
                    {/* DriveWide is owned by ABCD Services UK */}
                    DriveWide provides the most convenient way to earn for driving. 
                    Our user-friendly solution takes all of your hassles away by managing your driving needs. 
                    Bringing you a new age modern digital experience. 
                    </Typography>
                </Grid>
                {/* <Grid item sm={12} xs={12}>
                <Typography className='footer_head2'>Social Media</Typography>
                </Grid>
        <Grid style={{textAlign:'justify'}} item sm={12} xs={12}>
        <a href="https://www.linkedin.com/company/"  className="fab fa-linkedin-in circle-icon1"></a>
                        <a href="https://www.facebook.com/"  className="fab fa-facebook circle-icon1   ">
                      
                      
                        </a>
                        <a href="https://twitter.com/"  className="fab fa-twitter circle-icon1   ">
</a>
        </Grid> */}
        </Grid>
        <Grid item  className='footer_1' sm={4} xs={12}>
           
           <Grid item sm={12} xs={12}>
               <Typography className='footer_head'>SUPPORT AND CONTACT</Typography>
               </Grid>

             
               <Grid item sm={12} xs={12}>
               <Typography onClick={()=>Open('Query')} className='footer_text'>Post a query</Typography>
               </Grid>
               <Grid item sm={12} xs={12}>
               <Typography  onClick={()=>Open('Feedback')} className='footer_text'>Send us Feedback</Typography>
               </Grid>
               {/* <Grid item sm={12} xs={12}>
               <Typography className='footer_text'>Cookies Settings</Typography>
               </Grid> */}
             <Grid item sm={12} xs={12}>
                <Typography className='footer_head2'>Social Media</Typography>
                </Grid>
        <Grid style={{textAlign:'justify'}} item sm={12} xs={12}>
        <a href="https://www.linkedin.com/company/drivewide/"  className="fab fa-linkedin-in circle-icon1"></a>
                        <a href="https://www.facebook.com/profile.php?id=100091611340018"  className="fab fa-facebook circle-icon1   ">
                      
                      
                        </a>
                        <a href="https://instagram.com/drivewide.uk?igshid=YmMyMTA2M2Y="  className="fab fa-instagram circle-icon1   ">
</a>
        </Grid>
    
       </Grid>
       <Grid item  className='footer_2' sm={4} xs={12}>
           
           <Grid item sm={12} xs={12}>
               <Typography className='footer_head'>ABOUT DRIVEWIDE</Typography>
               </Grid>

               {/* <Grid item sm={12} xs={12}>
               <Typography className='footer_text'>About us</Typography>
               </Grid> */}
          
               <Grid item sm={12} xs={12}>
               <Typography onClick={()=>window.open('/privacy_policy')} className='footer_text'>Privacy Policy</Typography>
               </Grid>
               <Grid item sm={12} xs={12}>
               <Typography onClick={()=>window.open('/terms_conditions')} className='footer_text'>Terms &amp; Conditions</Typography>
               </Grid>
               <Grid item sm={12} xs={12}>
                <Typography className='footer_head2'>Get the app</Typography>
                </Grid>
                <Grid container sm={12} xs={12}>
<Grid item >
<img src={require('../../../images/app-store.png')} className='home_bg_app' />

</Grid>
<Grid item style={{paddingLeft:'2vw'}} >
<img src={require('../../../images/play-store.png')} className='home_bg_app' onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.drivewide.drivewide")}/>

</Grid>

</Grid>
    
       </Grid>
       
    </Grid>
 </Container>
    )
}

export default Footer
