import React,{useState} from 'react'
import Button from '@mui/material/Button';
import './D6_SL_AF_Form_CSS.css'

import {Container,Paper,Grid, Alert} from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import ReactPhoneInput from "react-phone-input-2";
 import 'react-phone-input-2/lib/style.css' 
 import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { live } from '../../../App';



 

 

const  Query_Form =(props)=> {
  const {query}=props
  const ref=React.useRef()

  const [data,setData]=React.useState({
    name:'',
    email:'',
    phone:'',
    message:'',
    service:'',
  category:query?query:'Query'
  
  

})
const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)

const handleSubmit=()=>{
  setProcessing(true)
    const formData=new FormData
    formData.append('name',data.name)
    formData.append('email',data.email)


    formData.append('phone',data.phone)
    formData.append('message',data.message)
    formData.append('company',data.company?data.company:' ')
    formData.append('type',data.category)










    fetch(live+'contact'
    // fetch('http://127.0.0.1:8000/mail-TBI'
    ,
    {
    
      method:'POST',
    
      body:formData
      })
    
      .then(res=>res.json())
      .then((resp)=>MailRes(resp.detail))
    
    
      .catch(error=>console.log(error))
  
  }


    const MailRes=(val)=>{
      if(val.status){
      setProcessing(false)
setSubmitted(true)
setData({
...data,name:'',email:'',phone:'',message:'',company:''
})
setSubmitted(()=>setTimeout(() => setSubmitted(false), 10000))   
}
else{
  alert('Unable to send mail,please try again later')
  setProcessing(false)
}}
    return (
      <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
          <div className="Floating_Form2">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
              
            <div className="TF_div">
                <label className="TF_Label">Name*</label>
                <TextValidator
                    label=""
                    onChange={(e)=>setData({...data,
                      name:e.target.value
                  })}
                  name="name"
                  value={data.name}
                    validators={['required', 'matchRegexp:[A-Za-z]+']}
                    errorMessages={['Name required', 'Invalid name']}  
                />  
            </div>
            <div className="TF_div">
              <label className="TF_Label">Email*</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email required', 'Invalid email']}
                  />
            </div>
            <div className="PNDiv_SA">
              <label className="PN_Label2">Phone Number*</label>
              <ReactPhoneInput className="PN_Input2"
                
                inputProps={{
                  name: 'phonenumber',
                  required: true
            /*      autoFocus: true     */
                }}
                country={'gb'}
                value={data.phonenumber}  
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: '+value+', '+country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  }
                  else if (value.match(/1234/)) {
                    return 'Phone number is invalid';
                  }
                   else {
                    return true;
                  }
                }}
                onChange={phonenumber =>setData({...data,phone: phonenumber })}  
              />
            </div>
            <div className="TF_div">
             
                </div>
         
            <div className="TF_div">
              <label className="TF_Label">Company (If any)</label>
              <TextValidator
                      label=""
                      onChange={(e)=>setData({...data,
                        company:e.target.value
                    })}  name="company"
                      value={data.company}
                  
                  />
            </div>
          
          

            <div className="TF_div">
              <div>
                <label className="TF_Label">Message*</label>
              </div>
                
               <TextValidator 
               multiline
               maxRows={2}
               
                        name='message'
                        onChange={(e)=>setData({...data,
                            message:e.target.value
                        })}       value={data.message}
                        validators={['required']}
                        errorMessages={['Message required']}
                    /> 
              </div>
   
              <Grid  style={{textAlign:'left',paddingTop:'1vh',width:'90%'}}item sm={12} xs={12}>
            <FormControl>
      <RadioGroup

      onChange={(e)=>setData({
        ...data,category:e.target.value
      })}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        value={data.category?data.category:'Query'}
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Query" control={<Radio style={{color:'var(--green)'}} />} label="Query" />
        <FormControlLabel value="Feedback" control={<Radio style={{color:'var(--green)'}} />} label="Feedback" />
    
      </RadioGroup>
    </FormControl>
            </Grid>
          
            
           
           
              
              <div className="Button_UL"></div>

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted}
                >
                    {
                    submitted&&!processing?'Submitted':processing?'Submitting':'submit'
                    }
                </Button>       
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
  
    )
    }


export default Query_Form
