import { DataGrid } from '@mui/x-data-grid';
import React,{ useEffect, useState ,useRef} from 'react';
import { live } from '../../App';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Grid, TextField, Typography, Snackbar, Alert,AlertTitle, Tooltip, Dialog, DialogContent, IconButton, Container, Paper, Slide, Chip } from '@mui/material';

import {useCookies} from 'react-cookie'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import './jobs.css'
import Job_post from './job_post';
import Job_post_Edit from './job_post_edit';
export default function Jobs_live(props){
  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
  const {active}=props

   const [processing,setProcessing]=useState(true)
   const [jobs,setJobs]=useState([])
   const [duplicate,setDuplicate]=useState(false)
   const [edit,setEdit]=useState(false)
   const[errMsg,setError]=useState(false)
   const [open,setOpen]=useState(false)
   const [open1,setOpen1]=useState(false)
   const [openR,setOpenR]=useState(false)
const[submitted,setSubmitted]=useState(false)
   const [open2,setOpen2]=useState(false)
   const [del,setDel]=useState(false)
   const [job_id,setJobID]=useState('')
   const [search,setSearch]=useState('')
   const [reload,setReload]=useState(0)
   const [redeem,setRedeem]=useState(false)
   const [data,setData]=useState({})
   const ref=useRef()

    useEffect(() => {
      setProcessing(true)
        fetch(live+`jobs?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>jobsResp(resp))
        }, [reload])

        const jobsResp=(val)=>{
          // window.scrollTo(0,0)

          if(active){
            setJobs(val.data.filter(val=>(val.is_active)&&(val.job_status!='Completed Successfully')&&(new Date()<=new Date(val.job_end_date+'T'+val.job_end_time))))
            setProcessing(false)

          }
          else{
            setProcessing(false)
            setJobs(val.data)
        
          }
               
            
            

        }


        const Edit=(id,val2)=>{
if(val2){
  if(auth.drivewide.is_verified){
    setDuplicate(true)
  setEdit(jobs.filter(val=>val.job_id===id)[0])
  }
  else{
    setError('Unverified business cannot create/post jobs')
    setOpen(true)

  }
}
else{
  setDuplicate(false)

  setEdit(jobs.filter(val=>val.job_id===id)[0])

}



        }

const backPressed=()=>{
  window.scrollTo(0,0)
    setProcessing(true)
setDuplicate(false)
    setReload(reload+1)
    setEdit(!edit)
}

const handleClose=()=>{
  setOpen(false)
  setOpen1(false)
  setOpen2(false)
  setJobID('')
  setOpenR(false)


}
const Delete=(val)=>{

  
  if(job_id){
    setOpen2(false)
    setDel(true)
    const formdata=new FormData()
      formdata.append(`job_id`,job_id)
    fetch(live+'delete-job',
    {
      method:'Delete',
      body:formdata
    }).then(res=>res.json()).then((resp)=>delRes(resp))
  
  
  
  }
  else{
    setJobID(val)
  setOpen2(true)
  }
  }
  
  const delRes=(resp)=>{
    if(resp.status){
      
  setDel(false)
  setError(resp.detail)
  setReload(reload+1)
  setOpen1(true)
  setJobID('')
    }
    else{
      setDel(false)

      setError(resp.detail)

      setOpen(true)
      setJobID('')
      setReload(reload+1)

  
  
    }
  
  }

        const renderEdit = (val) => {
          const oneDay1 = 24 * 60 * 60 * 1000; 
          const firstDate1= new Date();
          let acceptance_date=new Date(val.row.last_acceptance_date)

          var dd1 = acceptance_date.getDate().toString().padStart(2,'0');
          var mm1 = (acceptance_date.getMonth()+1).toString().padStart(2,'0'); //January is 0!
          var yyyy1 = acceptance_date.getFullYear();
           acceptance_date= yyyy1+'-'+mm1+'-'+dd1;
           acceptance_date=new Date(acceptance_date+"T"+val.row.last_acceptance_time)
          //  console.log(acceptance_date)
           

          // const secondDate1 = new Date(val.row.job_reporting_date.replace(' ','T'));
          
          const diffDays1 = ((( acceptance_date-firstDate1) / oneDay1));          
            return (



                <strong>
                           <Tooltip title={diffDays1<0?'Edit disabled as acceptance time is passed':(val.row.applied&&val.row.applied[0]||val.row.accepted&&val.row.accepted[0])?'Job applied/accepted by driver':'' }>
<span>                   <Button onClick={()=>Edit(val.row.job_id)} disabled={(diffDays1<0||val.row.applied&&val.row.applied[0]||val.row.accepted&&val.row.accepted[0])?true:false} className='btn_evd2'>Edit</Button>
</span>

                </Tooltip>
                </strong>
            )
          }
          const renderActive = (val) => {
            return (
                <strong>
                  {val.row.is_active?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
                </strong>
            )
          }
          const renderUrgent = (val) => {
            return (
                <strong>
                  {val.row.urgent_job?<i  className="fas fa-check-circle icon_check"></i>:<i  className="fas fa-times-circle icon_check1"></i>}
                </strong>
            )
          }
          const renderRefund = (val) => {
                    
                
            return (
                active?null:<strong>
                           <Button  onClick={()=>Refund(val.row)}  className='btn_evd2'>{'Refund'}
    

                           </Button>
                </strong>
            )
          }

          const Refund=(val)=>{
            setJobID(val)

            setOpenR(val)

          }
          const renderID = (val) => {
            return (
                <strong>
                  <a style={{color:'var(--green)'}} href={`/jobs/${val.row.job_id}`} target='_blank' >{val.row.job_id}</a>
                </strong>
            )
          } 
        const rows= 
        jobs&&jobs[0]&&!search?
        (jobs.map((item,k) => {
            return  {
             job_id:item.job_id,
        
              id:k+1,
              created_by:item.created_by,
              job_title:item.job_title,
              job_type:item.job_type,
              town_name:item.town_name,
              post_code:item.post_code,
              company_name:item.company_name,
              experience_years:item.experience_years+' Years '+item.experience_months+' '+'months',
              experience_months:item.experience_months,
              job_start_date:item.job_start_date+' '+item.job_start_time,
              job_start_time:item.job_start_time,
              urgent_job:item.urgent_job,
              job_end_date:item.job_end_date+' '+item.job_end_time,
              job_end_time:item.job_end_time,
              job_reporting_date:item.job_reporting_date+' '+item.job_reporting_time,
              job_reporting_time:item.job_reporting_time,
              last_acceptance_date:item.last_acceptance_date+' '+item.last_acceptance_time,
              last_acceptance_time:item.last_acceptance_time,
              pay_rate_type:'£'+item.pay_rate+'/'+item.pay_rate_type,
              pay_rate:item.pay_rate,
              is_active:item.is_active,
              created_on:item.created_on,
              published_on:item.published_on,
              applied:item.applied,
              accepted:item.accepted,
              total_pay:'£'+item.total_pay,


        
        
        
              
        
        
        
             
            }
        }
          )):jobs&&jobs[0]&&search&&search.length>2&&jobs.filter(val=>((val.job_id.toLowerCase().includes(search.toLowerCase()))||(val.town_name.toLowerCase().includes(search.toLowerCase()))||(val.post_code.toLowerCase().includes(search.toLowerCase()))||(val.job_title.toLowerCase().includes(search.toLowerCase()))))&&jobs.filter(val=>((val.job_id.toLowerCase().includes(search.toLowerCase()))||(val.town_name.toLowerCase().includes(search.toLowerCase()))||(val.post_code.toLowerCase().includes(search.toLowerCase()))||(val.job_title.toLowerCase().includes(search.toLowerCase()))))[0]?jobs.filter(val=>((val.job_id.toLowerCase().includes(search.toLowerCase()))||(val.town_name.toLowerCase().includes(search.toLowerCase()))||(val.post_code.toLowerCase().includes(search.toLowerCase()))||(val.job_title.toLowerCase().includes(search.toLowerCase())))).map((item,k) => {
            return  {
             job_id:item.job_id,
        
              id:k+1,
              job_title:item.job_title,
              job_type:item.job_type,
              town_name:item.town_name,
              post_code:item.post_code,
              company_name:item.company_name,
              experience_years:item.experience_years+' Years '+item.experience_months+' '+'months',
              experience_months:item.experience_months,
              job_start_date:item.job_start_date+' '+item.job_start_time,
              job_start_time:item.job_start_time,
              urgent_job:item.urgent_job,
              job_end_date:item.job_end_date+' '+item.job_end_time,
              job_end_time:item.job_end_time,
              job_reporting_date:item.job_reporting_date+' '+item.job_reporting_time,
              job_reporting_time:item.job_reporting_time,
              last_acceptance_date:item.last_acceptance_date+' '+item.last_acceptance_time,
              last_acceptance_time:item.last_acceptance_time,
              pay_rate_type:'£'+item.pay_rate+'/'+item.pay_rate_type,
              pay_rate:item.pay_rate,
              is_active:item.is_active,
              created_on:item.created_on,
              published_on:item.published_on,
              total_pay:'£'+item.total_pay,

        
        
        
              
        
        
        
             
            }
        }
          ):''
        const columns = [
          { field: 'id', headerName: 'S No.', width: 60 },
          { field: 'job_id', headerName: 'Job ID', renderCell: renderID,},
        
          {
            field: 'job_title',
            headerName: 'Job Title',
           
          },
          {
            field: 'job_type',
            headerName: 'Job Type',
          sortable:false,
          filterable:false
          
          },
          // {
          //   field: 'company_name',
          //   headerName: 'Company Name',
          //   // type: 'number',
          // },
          {
            field: 'job_start_date',
            headerName: 'Job Start',
            width: 180,  

            // type: 'number',
          }, {
            field: 'job_end_date',
            headerName: 'Job End',
            // type: 'number',
            width: 180,  

          },
          // {
          //   field: 'experience_years',
          //   headerName: 'Experience',
          //   // type: 'number',
          //   width: 180,  

          // },
          //  {
          //   field: 'job_reporting_date',
          //   headerName: 'Job Reporting',
          //   // type: 'number',
          //   width: 180,  

          // },
           {
            field: 'total_pay',
            headerName: 'Job value',
            // type: 'number',
          
          },
          // {
          //   field: 'last_acceptance_date',
          //   headerName: 'Last Acceptance',
          //   // type: 'number',
          //   width: 180,  

          // },
          {
            field: 'pay_rate_type',
            headerName: 'Pay Rate',
            // type: 'number',
          },
          // {
          //   field: 'created_on',
          //   headerName: 'Created On',
          //   // type: 'number',
          // },
          {
            field: 'town_name',
            headerName: 'Town Name',
            // type: 'number',
          },
          {
            field: 'post_code',
            headerName: 'Post Code',
            // type: 'number',
          },
          
        
          {
            field: 'is_active',
            headerName: 'Published',
            sortable:false,
            filterable:false,            renderCell: renderActive,
            width: 110,    
          
          },
        
        
        //   {
        //     field: 'Edit',
        //     headerName: ' ',
        //     sortable:false,
        //     filterable:false,          
        //       renderCell: renderEdit,
        //      },
             {
              field:active?' ':'Delete',
              headerName: ' ',
              sortable:false,
              filterable:false,              renderCell: renderRefund,
              width: 160, 

               },

        ];


 
const handleSubmit=()=>{
  setRedeem(true)
const formData=new FormData()



formData.append('job_id',job_id.job_id)
formData.append('credits',data.credits)

formData.append('reference_id',data.id)

fetch(live+'delete-job',{
method:'DELETE',
body:formData
}).then(res=>res.json()).then(resp=>redeemRes(resp))


}

const redeemRes=(val)=>{
if(val.status){
setRedeem(false)
setJobID('')
setSubmitted(true)
setReload(reload+1)

setTimeout(()=>timeOut(),1500)

}
else{
setJobID('')

setRedeem(false)


}

}

const timeOut=()=>{
setSubmitted(false)
setOpenR(false)
setData({})
}

  return (



        <Grid container style={{textAlign:'start'}}>
 <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="error">{errMsg}</Alert>       
  </Snackbar>


  <Snackbar
anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open1} autoHideDuration={6000} onClose={handleClose}>
    <Alert  sx={{ width: '100%' }} onClose={handleClose}  severity="success">{errMsg}</Alert>       
  </Snackbar>

  <Snackbar open={open2}  anchorOrigin={{vertical:'top', horizontal:'center'}}  onClose={handleClose}>
      <Alert   style={{alignItems:'center'}}  action={
          <div style={{paddingRight:30}}>
          <Button onClick={()=>handleClose()} className='btn_no'  size="small">
            No
          </Button>
            <Button onClick={()=>Delete()}  className='btn_yes' size="small">
            Yes
          </Button>
          </div>} onClose={handleClose} severity="warning">
        <AlertTitle>Warning!! <br />Credits will be redeemed to business
</AlertTitle>

      </Alert>
     
     
      </Snackbar>
      <Dialog
        open={openR}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn21'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Refund credits to business {job_id.created_by}         
          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
          <div className="Floating_Form2">
            <ValidatorForm
                ref={ref}
                onSubmit={handleSubmit}
            >
             

            <div className="TF_div">
                <label className="TF_Label">Reference Id*</label>
                <TextValidator
                    label=""
                    onChange={(e)=>setData({...data,
                      id:e.target.value
                  })}
                  name="id"
                  value={data.id}
                  
                    validators={['required']}
                    errorMessages={['Reference id required']}  
                />  
            </div>
            <div className="TF_div">
              <label className="TF_Label">Credits*</label>
              <TextValidator
                      label=""
                      
                      onChange={(e)=>setData({...data,
                        credits:e.target.value
                    })}  name="credits"
                      value={data.credits}
                      validators={['required']}
                      errorMessages={['Credits required']}
                  />
            </div>
           
            <div className="TF_div">
             
                </div>
         
      
          

   
        
          
            
           
           
              
              <div className="Button_UL"></div>

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    className="primary_btn"
                    type="submit"
                    disabled={submitted||redeem}
                >
                   {
                    submitted&&!redeem?'Refunded':redeem?'Refunding':'Refund credits'
                    }
                </Button>       
        </ValidatorForm>
        </div>
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>

      {!edit? <Grid container style={{alignItems:'center',marginBottom:'5vh',textAlign:'center'}} >
<Grid item sm={12} xs={12}>
<TextField placeholder='Search jobs here ..' className='field_search' onChange={(e)=>setSearch(e.target.value)} />
</Grid>

<Grid container>
<Grid  style={{marginTop:'2vh',marginBottom:'2vh',textAlign:'end',paddingRight:'2vw'}}item sm={12} xs={12}>
        <Chip onClick={()=>props.closeLive()} label='Back to dashboard' style={{color:'white',background:'var(--green)'}}  />
      </Grid>


{(jobs&&jobs[0]&&!processing&&!search)||(jobs&&jobs[0]&&!processing&&search&&search.length>2&&rows)?
<Box sx={{minHeight:'80vh',width: '100%',padding:'20px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={[20]}
        rowsPerPageOptions={[20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>:(!jobs[0]&&!processing)||(!processing&&!rows)?
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>

    <Typography className='txt_evd'>
      {active?'No Active Jobs Found':'No Jobs Found'}
    </Typography>
    </Grid>:
    <Grid sm={12} style={{textAlign:'center',paddingTop:'10vh',minHeight:'80vh'}} xs={12} item>
            <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'5vh',marginBottom:'10vh'}} />
            </Grid>}
     
  

</Grid>

       </Grid>:
       jobs&&jobs[0]&&!processing&&edit&&edit.job_id? <Job_post_Edit duplicate={duplicate} edit={edit} backPressed={backPressed} />:
       
       <Job_post backPressed={backPressed} />}
       </Grid>   )
}
