import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';
import { green } from '@mui/material/colors';


const Privacy_policy = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid1">
                <h3 className="TBI_PrivacyPolicy_Block_MH TBI_PrivacyPolicy_Block_H">
                Privacy policy
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                <h2 style={{color:'var(--green)'}}>DriveWide Privacy Notice  </h2>
                Your privacy is important to us at DriveWide. We will only use your personal information to administer your account, provide the products and services you have requested from us, and to keep you informed about our products and services. The protection, confidentiality and integrity of your personal data are our prime concerns. This privacy notice explains what personal information we collect from you when you interact with our products and services, and how we use this information. 

We will inform you of any future changes to our processing purposes or our Privacy Policy.     </p><br/>

                   </Grid>



                   <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                <h2 style={{color:'var(--green)'}}>Our services   </h2>
                DriveWide offers a digital solution that enables you to find active driving jobs in your area. Our mobile app allows you to search, apply and confirm jobs with a transport operator directly without the requirement of applying through various job posting websites and/or recruitment agencies. Instead, you register with us and apply directly through the app, we will periodically (weekly) release payments directly to your bank account for the amount of work you have carried out.  

When offering you our mobile app system we are facilitating the payment of completed driving jobs on behalf of the transport operator. For more details on the specific services we provide, please review <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/how-it-works' target='_blank'>https://drivewide.co.uk/how-it-works </a>      </p><br/>

                   </Grid>




                   <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                <h2 style={{color:'var(--green)'}}>What personal data do we collect? </h2>
                We need certain personal information to be able to offer you the best driving experience and to keep you informed of our products and services. You provide some of this data directly, for instance when you create an account or enter your parking details into our mobile application. Additionally, we receive some of your personal data indirectly through your devices, by recording how you interact with our services (e.g. through cookies). This allows us to personalise and improve our products. 

When you create an account with DriveWide, before starting a driving job, or otherwise use our services, we process the following details: <br/>

1. First and Last Name; <br/> 

2. Title (Mr, Mrs etc.); <br/>

3. Email address; <br/>

4. Your telephone number; <br/>

{/* 5.Social media account information, if you use a social media plugin to register; <br/> */}

5. Your address; <br/>

6. Driving Licence Information; <br/>

7. Driver Card, CPC Information (if applicable); <br/>

8. Passport Information; <br/>

9. Visa Information (if applicable); <br/>

10. Share Code information; <br/>

11. Your location details when you use our Services; <br/>

12. IP address; <br/>

13. Phone ID; <br/>

14. Payment and billing information, e.g. bank account, payment card or PayPal details; <br/>

15. Company name (if applicable); <br/>

16. Business registration number (if applicable)      </p><br/>

                   </Grid>

<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>Device and location   </h2>
If you have enabled this in your phone and app settings, we receive data about your location when you use our services. We use this information to verify and facilitate your driving transactions by for example helping you to identity your job location and to warn you in case you forgot to end your job session. You can always change your preferences in your settings. </p><br/>

</Grid>

<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>How do we use your personal data?   </h2>
We only use your data for the purposes for which it was collected and, where relevant, to meet local legal obligations. We use your personal data for the following purposes:<br/><br/>
<b style={{color:'var(--green)'}}>Performance of agreements with users -</b> To provide you with the driving related products and services of your choice, we process your information for example to complete job transactions; facilitate payment of the relevant fees and communicate your driving status with the relevant driving control bodies. <br/><br/>

<b style={{color:'var(--green)'}}>Customer support -</b> We use your information to support you in your use of our products and services. This includes the use of personal information to diagnose product problems, rectify erroneous transactions and provide other customer support related services. <br/><br/>

<b style={{color:'var(--green)'}}>Customer engagement and communication -</b> We use your information to communicate with you via email, SMS text or other electronic media for instance on service‐related matters, such as invoices. Other instances where we engage with you include confirming the opening of an account, resolving complaints, and asking you to take part in one of our surveys. <br/><br/>

<b style={{color:'var(--green)'}}>Marketing -</b> We would like to send you information which may be of interest to you, about our products and services and those of other companies (in so far as you have consented to this) that may be of interest to you. We communicate with you via email, or other electronic media. You have the right at any time to stop us from contacting you for marketing related purposes. Depending on your preferences, you can unsubscribe, change your settings on‐line or contact our <a href='mailto:customerservice@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >Customer Support team</b></a> to do so. <br/><br/>

<b style={{color:'var(--green)'}}>Safety, security and dispute settlement -</b> We use your details to protect the security, integrity and safety of our products, services and customers. This includes processing in the context of detecting and preventing fraud, theft and other misuse of our services. We may also use personal information in the context of dispute settlements, to enforce our agreement with you, to enforce our rights towards third parties or defend ourselves against any third party claims or allegations. <br/><br/>

<b style={{color:'var(--green)'}}>Legal obligations -</b> We may process your information in relation to a legal obligation. This includes cases where we must comply with requests from competent authorities, administrative obligations and in cases involving legal disputes. <br/><br/>

<b style={{color:'var(--green)'}}>Improving and personalising our products and services -</b> We use your personal information to improve our products and services. Clicking behaviour and search results on our website can for instance help us to prioritise or identify new product features. <br/><br/>

<b style={{color:'var(--green)'}}>Maintenance, development and incident management -</b> If you experience issues with one of our products or services, we may need to process some of your personal information, such as your name, driving licence number and DriveWide client ID, in order to resolve the respective issue. We may also use your personal information in relation to certain system development processes, for instance if we move customer data to a new database. <br/><br/>

<b style={{color:'var(--green)'}}>General business process execution, internal management and management reporting -</b> To be able to run our business, we also use your personal information for general business processes. This includes processing your information for archiving, insurance and other administration related purposes, management reporting or processing in relation to audits.  <br/><br/>
 </p><br/>

</Grid>

<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>With whom do we share your personal data?    </h2>
We share your personal data with third parties such as law enforcement agencies, suppliers (e.g. hosting, customer care and e‐marketing facilities), clients (e.g. corporate clients/employers), and other partners. Who we share your information with, depends on your location on the services you use. We share your information: <br/> <br/>
1. To the extent necessary to operate our business, to provide you our services, or our partner services, to complete any related transactions and collect related payments, to provide customer care and to communicate with you in connection with our services. For instance, to process payments and transactions.  <br/>

2. Where you gave us explicit consent to do so;  <br/>

3. If we are legitimately requested to share information with competent authorities, such as law enforcement, pursuant to law, e.g. for investigation of illegal activities;  <br/>

4. To enforce our agreement with you, for instance in cases where you have not paid for your parking transactions, we may share your information with a debt collector;  <br/>

5. To enforce our rights towards third parties, or to defend ourselves against any third‐party claims or allegations;  <br/>

6. To protect the security, integrity or safety of our Services.  <br/>

To be able to provide you with our services, we make use of some intra‐company facilities, e.g. for database purposes, administration, marketing and fraud prevention. As such, we also share certain personal data with our affiliates. Such intra‐company sharing of data will only occur for purposes of operating our business, performing your service contract and as has otherwise been outlined in this Privacy Notice. <br/></p>

</Grid>




<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>Where is your personal data processed?   </h2>
Your personal data is mainly processed by our staff in the United Kingdom. We may also share data with our affiliates in the context of operating our business, providing our services and other processing purposes as outlined in this Privacy Notice. For hosting and maintenance purposes your personal data is located in the United Kingdom and India. 

To the extent any other personal data will be transferred to a country outside the European Union or an international organisation, we will make sure that this only happens to such countries and international organisations that ensure an adequate level of protection, have put appropriate safeguards in place to protect your data and your rights in accordance with the EU privacy law (GDPR), or as is otherwise allowed under the GDPR. 

If you wish to receive more information of the safeguards we have implemented, please contact our <a href='mailto:customerservice@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >Customer Support team</b></a> .</p><br/>

</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>How long do we keep your personal data?  </h2>
We will retain your personal information only for as long as is necessary for the purposes for which the information was collected, or as long as is required pursuant to law. 

If you do not use your account for more than 18 months, we will mark it in our database as "inactive". We will then no longer actively use your account information, e.g. to inform you on our products and services, but will archive the account to be able to easily re‐activate it should you wish to use our services again in the future. 

In case you wish to close your account, please contact our <a href='mailto:customerservice@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >Customer Support team</b></a>. Upon closure, we will remove your personal information without undue delay except for information that we are obliged to keep pursuant to law. For instance in order to meet statutory administrative retention obligations, we need to keep some of your account related information (e.g. copies of invoices/receipts), for six years. 

Data may be retained longer in cases where it is used in relation to a legal claim or is used in relation to a valid legal process.  </p><br/>

</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>Accessing and managing your personal data </h2>
You can directly view and edit your personal details via your personal account through <a style={{color:'var(--green)'}} href='https://drivewide.co.uk/signin' target='_blank'>https://drivewide.co.uk/signin </a>. 

If you cannot access, rectify, or erase certain personal data yourselves, if you want a copy of the personal data that we process of you, if you wish to request a restriction of processing of your personal data or to object to processing, please contact our Customer Care department using our web form. We will respond to such requests within 30 days. </p><br/>

</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>Lawful basis for data processing    </h2>
We only collect and process your personal data when there is a lawful basis to do so. The lawful basis we rely on in this respect includes: <br/> <br/>

1. Contract: the processing is necessary to perform your Service contract; <br/>

2. Legitimate interest: for example, (i) a legitimate commercial interest to process certain of your personal data, e.g. for running our business and the purposes of certain forms of direct marketing and profiling; (ii) to archive certain account information to facilitate you to re‐use your account in the future; or (iii) if there is a legitimate interest from business or security perspective, e.g. to prevent fraud or abuse of our Services, or for purpose of network and information security of our IT systems; <br/>

3. Legal obligation: the processing necessary to comply with a legal obligation, e.g. the legal requirement to keep administrative records for a certain period of time, or the legal obligation to share certain data on a police order for criminal investigation purposes; <br/>

4. Public interest task: the processing necessary to assist in tasks in the public interest. For instance to aid enforcement agents in verifying the validity of a parking action or parking permit; <br/>

5. Consent: where you gave us explicit consent to process the data concerned, for example ‐ if applicable ‐ to share your data with partners or other third parties for commercial purposes. <br/>

  

If you have given your consent to the processing, you have the right to withdraw your consent at any time, by completing a web form. We will discontinue the processing of your information upon receipt of your withdrawal. However, any processing performed prior to your withdrawal remains a legitimate processing based on a valid consent at the time. We will not be under the obligation to reverse the processing.  </p><br/>

</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>Questions, concerns and complaints    </h2>
If you have questions or concerns about the way in which we use your personal data, you can contact our customer service team at <a href='mailto:customerservice@drivewide.co.uk' target='_blank' style={{color:'var(--green)'}}><b >customerservice@drivewide.co.uk</b></a>   <br />

If you feel that an issue has not properly been resolved, you also have the right to lodge a complaint with the supervisory authority ICO:  <a style={{color:'var(--green)'}} href='https://ico.org.uk/concerns/' target='_blank'>https://ico.org.uk/concerns/ </a>. </p><br/>

</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
<p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">

<h2 style={{color:'var(--green)'}}>Responsible Entity </h2>
Drive Wide Ltd address is 88 Crowland Avenue, Hayes, UB3 4JR, and registered with Companies House under no. 14631229.   </p><br/>

</Grid>



       
       
       </Grid> </div>

    

    )
}

export default Privacy_policy
