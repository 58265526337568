import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography ,Grid,Paper, Slide, Dialog, CircularProgress, Button, TextField, Chip} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useCookies} from 'react-cookie'

import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import './dashboard.css'
import Jobs from './jobs';
import Profile from './profile';
import Payments from './payments';
import { live } from '../../App';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useState } from 'react';
import { useEffect } from 'react';
import Overtime_requests from './overtime_requests';

export default function Dashboard_Business(props) {





  const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])
const [business,setBusiness]=useState('')
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const[openA,setOpenA]=React.useState(auth&&auth.drivewide&&auth.drivewide.is_active?false:true)

  const [openS, setOpenS] = React.useState(false);
  const [msg,setMsg]=React.useState('')
const [credits,setCredits]=React.useState('')
  const [state, setState] = React.useState({
 
    left: false,
   
  });
  const [screen,setScreen]=React.useState('Dashboard')
  const [anchor]=React.useState('left')
  const [message,setMessage]=React.useState('')
  const [history,setHistory]=React.useState(false)
  const [subscription,setSubscription]=React.useState(false)
  const [reload1,setReload1]=useState(0)
  const handleClose = () => {
    // setOpen(false);
    setOpenS(false);
    // setOpen2(false);



  };
  const Subscription=()=>{
    setHistory(false)
    setSubscription(!subscription)
  }
  const History=()=>{
    setSubscription(false)
    setHistory(!history)
  }
  useEffect(() => {
    if(auth.drivewide.business_id){
    //  setProcessing(true)
     fetch(live+`business-major?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then(resp=>setBusiness(resp.data))
    }
   }, [auth,reload1])
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      // setSuccess(true);
      setOpen(true)
    

      creditsSuccess(query.get('id'))
    }

    if (query.get('cancel')) {
      setOpen(true)
      Cancel(query.get('id'))
      // setMessage(
      //   "Credits Addition canceled -- Add Credits when you need to post job."
      // );
    }
  }, []);


const reloadHistory=()=>{
  setReload1(reload1+1)
}
  const creditsSuccess=(val)=>{
    const formData=new FormData()
    formData.append('session_id',val)
    fetch(live+`credits-session`,{
      method:'PUT',
      body:formData

    }).then(res=>res.json()).then(resp=>creditsSuccessRes(resp))

  }

  const creditsSuccessRes=(res)=>{
if(res.status){
  // setOpen(false)
  setMessage(
    "Credits added successfully to your account"
  );
  setAuth('drivewide',res.data,{
    maxAge: 3600 ,
    expires:0,
    path:'/'
 })



}
else{
  // setOpen(true)
  setMessage(
    "Session expired, please try again later"
  );

}
  }



  const Cancel=(val)=>{
    fetch(live+`credits-session?id=${val}`).then(res=>res.json()).then(resp=>cancelRes(resp))

  }

  const cancelRes=(val)=>{
    if(val.status){
       setMessage(
        "Transaction cancelled"
      );
    }
    else{
      setMessage(val.detail)
    }

  }
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
     
      <Divider />
      


      <List>
        {((["Profile","Jobs History","Account Info","Overtime Requests","Payments"])).map((text, index) => (
          <ListItem style={{width:'100%'}} key={index} onClick={()=>setScreen(text)} disablePadding>
            <ListItemButton>
             
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> 
        ))}
      </List>
    </Box>
  );
  const sessionCredits =()=>{
    setHistory(false)
    if(auth.drivewide.subscription_active){
    if(credits&&credits>0&&open2){
      setOpen2(false)
      setOpen(true)
      const formData=new FormData()
      formData.append('business_id',auth.drivewide.business_id)
      formData.append('customer_id',auth.drivewide.customer_id)
      formData.append('amount',parseFloat(parseFloat(credits)+0.2*credits).toFixed(2))



      fetch(live+'credits-session',{
        method:'POST',
        body:formData
      }).then(res=>res.json()).then(resp=>creditsRes(resp))
    
    }
    if(!credits&&!credits>0&&open2){
      setMsg('Valid Credits required')
setOpenS(true)
    }
    else{
      setOpen2(true)
    }
   }
  else{
    setMsg('Please upgrade or purchase subscription from pricing page to continue')
    setOpenS(true)
  }}

  const creditsRes=(val)=>{
    if(val && val.url){
      window.open(val.url,'_self')
    }
    else{
      setOpen(false)
      setMsg('Error in adding credits, please try again later')
      setOpenS(true)
      
    }

  }
  const signOut=()=>{
  
deleteAuth('drivewide')
// window.location.href='/'
  }
 

  const Credits=(val)=>{
 
    if(val&&val.includes('.')){

      
      if((val.toString().split(".")[1].length)<3){
        setOpenS(false)

        setCredits(val)

      }
      else{
        setMsg('Credits with two decimals places are valid')
        setOpenS(true)
      }


    }
    else{
      setOpenS(false)

      setCredits(val)
    }

  }


  const backPressed1=()=>{
    setScreen('')
  }
if(!auth['drivewide'])(
  window.location.href='/signin'

)


else{


  return (
    <div>
    <Snackbar open={openS} autoHideDuration={6000} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>
         {msg}
        </Alert>
      </Snackbar>
        <Dialog
    fullScreen
    open={open}
    onClose={handleClose}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      {!message?<Grid item sm={12} xs={12}>
      <Box sx={{ display: 'flex',justifyContent:'center' }} >
      <CircularProgress style={{marginTop:'23vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
    </Box>
        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'2vh'}}>Payment processing, please wait</Typography>
      </Grid>:
      <>
      <Grid item sm={12} xs={12}>
      
        <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'25vh'}}>{message}</Typography>
      </Grid>
        <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
        <Button onClick={()=>window.location.href='/dashboard'} className='pricing_plan_btn'>
        Back to Dashboard
        </Button>

        </Grid>
        </>
      }
    </Grid>
   
  </Dialog>
  <Dialog
    fullScreen
    open={open2}
    onClose={handleClose}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center',}}>

      <>
      <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      <Grid item style={{marginTop:'23vh'}} sm={12}  xs={12}>
      <TextField  onChange={(e)=>Credits(e.target.value)} style={{width:'70%'}} placeholder='Enter credits' value={credits} label='credits' type={'number'}/>
      </Grid>
      {credits?<Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
     <Typography style={{fontWeight:'700'}}>
      VAT(20%) - £{parseFloat(0.2*credits).toFixed(2)}<br /> Total - £{parseFloat(parseFloat(credits)+0.2*credits).toFixed(2)}
     </Typography>
        </Grid>:null}
        <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
        <Button onClick={()=>sessionCredits()} className='pricing_plan_btn'>
        Add Credits
        </Button>
        <Button onClick={()=>setOpen2(false)} className='pricing_plan_btn2'>
      Back
        </Button>
        </Grid>
        </>
      
    </Grid>
   
  </Dialog>
  <Dialog
    fullScreen
    open={openA}
    onClose={()=>setOpenA(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <Grid item sm={12} xs={12}>
    <img   style={{height:100,marginTop:'2vh'}} src={require('../../images/DRIVEWIDE.svg').default}></img>

    </Grid>
      
 
    <Grid item sm={12} xs={12}>
      
      <Typography style={{fontSize:'2.2rem',fontWeight:'500',color:'var(--green)',paddingTop:'5vh'}}>{'Your account has been freezed '}<br/>Please contact our <a style={{fontSize:'2.2rem',fontWeight:'700',color:'var(--green)',textDecoration:'none'}}href="mailto:customerservice@drivewide.co.uk">support team </a>to continue</Typography>
    </Grid>
      <Grid item style={{textAlign:'center',paddingTop:'2vh'}} sm={12} xs={12}>
      <Button onClick={()=>signOut()} className='pricing_plan_btn'>
      signout
      </Button>

      </Grid>

    </Grid>
   
  </Dialog>
 <React.Fragment >
          <CssBaseline />
      <AppBar
        position="fixed" 
        className="appbarMain"
       style={{top:120}}
      >
            <Toolbar>
            <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
              <div >
            <IconButton
             onClick={()=>setState({...state,left:!state.left})}
                color="inherit"
                aria-label="open drawer"
                
                edge="start"
              >
                <MenuIcon  />
              </IconButton>
              </div>
              <div style={{alignSelf:'center'}} >
              <Typography variant="h6" noWrap>
                {screen}
              </Typography>
              </div>
              <div style={{display:'flex',justifyContent:'end',width:'100%'}}>
              {/* <div onClick={()=>window.location.href='/'} style={{paddingRight:'20px',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                HOME
              </div> */}
              <div onClick={()=>signOut()} style={{paddingRight:'20px',alignSelf:'center',textAlign:'end',cursor:'pointer'}} >
                SIGN OUT
              </div>
              </div>
              </div>
        </Toolbar>
      </AppBar>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}

          >

<Grid container  >
            <Grid style={{textAlign:'center'}} item xs='12'>
            <img src={require('../../images/DRIVEWIDE.svg').default} style={{maxWidth:'100%',height:80}} />
              </Grid>
              <Grid  style={{textAlign:'center'}} item xs='8'>
              <h1 style={{marginTop:'10px',marginRight:'auto',fontSize:'16px',paddingLeft:18,textAlign:'initial'}}>Hey <b style={{color:'var(--green)'}}>{auth['drivewide'].fname}</b></h1>
              </Grid>

            
          <Grid item style={{alignSelf:'center',textAlign:'center'}} xs='4'>
          <IconButton               onClick={()=>setState({...state,left:!state.left})}
>
            {state.left ? <ChevronLeftIcon style={{fontSize: 40}} /> : <ChevronRightIcon />}
          </IconButton>

              </Grid>

            </Grid>
                 <Grid> 
            {list(anchor)}
            </Grid> 
          </Drawer>
          <main
        style={{minheight:'50vh'}}
      >


        
    <div style={{height:'100%',minHeight:'90vh'}}>
      
    { <Grid className='grid_contPPr' container>
    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Profile')}  className={screen==='Profile'?'dash_subA':'dash_sub'}>
        Profile
        </Paper>
        </Grid>

    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Jobs History')}  className={screen==='Jobs History'?'dash_subA':'dash_sub'}>
        Jobs History
        </Paper>
        </Grid> 
        
    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Overtime Requests')}  className={screen==='Overtime Requests'?'dash_subA':'dash_sub'}>
    Overtime Request
        </Paper>
        </Grid> 
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Account Info')}  className={screen==='Account Info'?'dash_subA':'dash_sub'}>
        Account
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6}onClick={()=>setScreen('Payments')}  className={screen==='Payments'?'dash_subA':'dash_sub'}>
        Payments
        </Paper>
        </Grid>

   
  
        </Grid>  }


        {screen==='Jobs History'?<Jobs reloadHistory={reloadHistory} />:screen==='Overtime Requests'?<Overtime_requests  backPressed1={backPressed1}/>:screen==='Profile'?<Profile  backPressed1={backPressed1}/>:screen==='Payments'?<Payments payments={business} />:screen==='Account Info'?
        <Grid style={{padding:'8vh 0px',textAlign:'-webkit-center',justifyContent:'center'}} container>
   
   <Grid item   sm={3} xs={6}>
    <Paper elevation={6} onClick={()=>Subscription()}  className={'dash_subA'}>
        Subscription <br /> 
       
        </Paper>
        </Grid>
    <Grid item   sm={3} xs={6}>
    <Paper elevation={6}  className={'dash_subA'}>
        Credits Available <br /> 
        £{auth.drivewide.credits?auth.drivewide.credits:0}
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6} onClick={()=>History(!history)}  className={'dash_subA'}>
        Credits History <br /> 
        </Paper>
        </Grid>
        <Grid item   sm={3} xs={6}>
    <Paper elevation={6} onClick={()=>sessionCredits()} className={'dash_subA'}>
   Add Credits <br /> 
     
        </Paper>
        </Grid>
 

   
  
        </Grid> :null}


        {screen==='Account Info'&&history&&business?
        // <Grid container style={{width:'100%'}}>
        <Payments  transactions={business.credits_history}/>
        // </Grid>
        :null}

    {screen==='Account Info'&&business&&subscription? 
    
    business.subscription_id?
    (<Grid container style={{padding:10}}>
        <Grid style={{padding:'2vh 0px',alignItems:'start',justifyContent:'center'}} container sm={12} xs={12}>
        <Grid item style={{maxWidth:'1200px',marginBottom:'3vh'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)',fontSize:'1.5rem'}}>Subscription Details </b> <span><Chip  style={{backgroundColor:'darkgray',color:'white',marginLeft:'2vw'}}  onClick={()=>window.location.href='/for_business'} label='UPGRADE'/></span>
          </Typography>
          </Grid>
          {/* <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)'}}>Subscription ID : </b>{business.subscription_id}
          </Typography>
          </Grid> */}
          <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)'}}>Subscription Type : </b>{'£'+business.amount+'/'+business.payment_intervals+business.plan_type}
          </Typography>
          </Grid>

          <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)'}}>Started On : </b>{(business.subscription_start).substring(0,10)+' '+(business.subscription_start).substring(11,16)}
          </Typography>
          </Grid>
          <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)'}}>Renewal On : </b>{(business.renewal_on).substring(0,10)+' '+(business.renewal_on).substring(11,16)}
          </Typography>
          </Grid>
          <Grid item style={{maxWidth:'1200px',padding:'1vh 0px'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)'}}>Subscription Status : </b> {business.subscription_active?<span style={{padding:'5px 12px',background:'var(--green)',color:'white',borderRadius:'25px'}}>Active</span>:<span style={{padding:'5px 12px',background:'red',color:'white',borderRadius:'25px'}}>Inactive</span>}
          </Typography>
          </Grid>
        </Grid>
        </Grid>):
        <Grid container>
        <Grid style={{padding:'2vh 0px',alignItems:'start',justifyContent:'center'}} container sm={12} xs={12}>
        <Grid item style={{maxWidth:'1200px',marginBottom:'3vh'}}sm={12} xs={12}>
         <Typography>
          <b style={{color:'var(--green)',fontSize:'1.5rem'}}>No active subscription </b>
          </Typography>
          </Grid>
        </Grid>
       </Grid>
       :null}


    </div>
   
   

      </main>
        </React.Fragment>
     
      
      
    </div>
  );}
}
