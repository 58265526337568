import React, { useEffect, useState } from 'react'
import { Typography ,Grid,Paper, Dialog, Slide, CircularProgress, Container, Chip, Box} from '@mui/material';
import { live } from '../../App';
import Jobs_live from './jobs_live';
import Report_live from './report_live/report_live';
import Complete_live from './complete_live/complete_live';
import DownloadLive from './download_live';
import Drivers_dash from './drivers';
import { useCookies } from 'react-cookie';
import Business_dash from './business';
import Business_Low_Credits from './low_credits_business';
import Invoices_Live from './invoices';

export default function Live_dash(props) {


const[processing,setProcessing]=useState(true)
const [jobs,setJobs]=useState('')
const [completed,setCompleted]=useState('')
const [completed_jobs,setCompleted_Jobs]=useState('')

const [active,setActive]=useState('')

const [screen,setScreen]=useState('')
const [subScreen,setSubScreen]=useState('')
const [reload,setReload]=useState(0)
const[reports,setReports]=useState('')
const [head,setHead]=useState('')
const [head1,setHead1]=useState('')
const [openD,setOpenD]=useState(false)
const [drivers,setDrivers]=useState([])
const [business,setBusiness]=useState([])

const[auth,setAuth,deleteAuth]=useCookies(['drivewide'])

    useEffect(() => {
        setProcessing(true)
 fetch(live+'report-job',{
    method:'PUT'
 }).then(res=>res.json()).then(resp=>jobRes(resp))
}, [reload])
 const jobRes=(res)=>{
    if(res.status){
        setProcessing(false)
        setJobs(res.jobs)
        setCompleted(res.completed)
    }

 }

 useEffect(() => {
fetch(live+'delete-job',{
method:'PUT'
}).then(res=>res.json()).then(resp=>completedRes(resp))
}, [reload])
const completedRes=(val)=>{
  if(val.jobs){
    setCompleted_Jobs(val.jobs)
  }

}
 useEffect(() => {
    fetch(live+`driver?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>driversResp(resp))
    }, [reload])

    const driversResp=(val)=>{


// setProcessing(false)
setDrivers(val.data)
}


useEffect(() => {
    fetch(live+`business?q=${auth.drivewide.business_id}`).then((res)=>res.json()).then((resp)=>businessResp(resp))
    }, [reload])

    const businessResp=(val)=>{

{

setBusiness(val.data) 
 
}

        
        
        

    }     
        

    

 const Screen=(val,val1)=>{
    setScreen(val)
    setSubScreen(val1)

 }
 const closeLive=()=>{
setScreen('')
setSubScreen('')
setReload(reload+1)
setOpenD(false)
 }
//  console.log(jobs&&jobs.filter(val=>!val.status))

 const reportFxn=async(val)=>{
 
  if(val==='To Be Reported'){

    let filter=await jobs.filter(val=>!val.job_status&&(new Date()<new Date(val.job_start_date+'T'+val.job_start_time)))
    setReports(filter)
    setHead1('')

    setHead(val)
  }
  if(val==='Unreported Jobs'){
    setHead1('')

    setReports(jobs.filter(val=>(!val.job_status&&(new Date()>new Date(val.job_start_date+'T'+val.job_start_time)))))
    setHead(val)
  }
if(val==='Reported Jobs'){
  setHead1('')

setHead(val)
setReports('')
}
 }
 const completeFxn=async(val)=>{
 
  if(val==='To Be Completed'){

    let filter=await completed.filter(val=>val.job_status&&val.job_status!=='Completed Successfully'&&(new Date()<=new Date(val.job_end_date+'T'+val.job_end_time)))
    setReports(filter)
    setHead1(val)
    setHead('')
  }
  if(val==='Uncompleted Jobs'){
    setReports(completed.filter(val=>val.job_status&&val.job_status!=='Completed Successfully'&&val.job_status!=='Completed By Driver'&&(new Date()>new Date(val.job_end_date+'T'+val.job_end_time))))
    setHead1(val)
    setHead('')

  }
if(val==='Completed Jobs'){
  setHead('')

setReports(completed.filter(val=>val.job_status==='Completed Successfully'))
setHead1(val)

}
 }

//  console.log(reports)

const signOut=()=>{
  
  deleteAuth('drivewide')
  // window.location.href='/'
    }
const closeReport=()=>{
  setHead('')
  setReports('')
  setHead1('')
  setReload(reload+1)
  setScreen('')


}
  return (
<div>

<Dialog
    fullScreen
    open={openD}
    onClose={()=>setOpenD(false)}
    // TransitionComponent={Transition}
  >
    <Grid container style={{textAlign:'center'}}>
    <DownloadLive closeLive={closeLive} />
    </Grid>
   
  </Dialog>
    {head?<Report_live jobs={reports} head={head}closeReport={closeReport} />:
    head1?<Complete_live jobs={reports} completed={head1==='Completed Jobs'?true:false} head={head1} closeReport={closeReport} />:<Grid container style={{textAlign:'center',marginBottom:'10vh'}}>
    <Grid item  className='icon_gridM' sm={3} xs={6}>
        <img onClick={()=>window.location.href='/'}  style={{height:65}} src={require('../../images/DRIVEWIDE.svg').default}></img>
        {/* <Typography style={{fontSize:22,fontWeight:'600',color:'var(--green)'}}>DriveWide</Typography> */}

    </Grid>

    {processing?
    <Grid item sm={12}xs={12}>
    <Box sx={{ display: 'flex',justifyContent:'center' }} >

<CircularProgress style={{marginTop:'35vh',height:60,width:60,marginBottom:'2vh',color:'var(--green)'}} />
</Box>
    </Grid> 
    :screen==='Job'?
    <Jobs_live closeLive={closeLive} />
    :screen==='Completed Jobs'?
    <Complete_live  jobs={completed_jobs} completed={true} head={'Completed Jobs'}  closeReport={closeReport} />
    :screen==='Active Jobs'?
    <Jobs_live active={true} closeLive={closeLive} />
   :screen==='Verified Drivers'?
   <Drivers_dash verified={true} closeLive={closeLive} />
   :screen==='UnVerified Drivers'?
   <Drivers_dash  closeLive={closeLive} />
   :screen==="Verified Business"?
   <Business_dash  verified={true}  closeLive={closeLive} />
   :screen==="UnVerified Business"?
   <Business_dash  closeLive={closeLive} />
   :screen==='Low Credits Business'?
   <Business_Low_Credits  closeLive={closeLive} />
   :screen==='Invoices'?<Invoices_Live  closeLive={closeLive}/>
  :
   
   <>
    <Grid container style={{padding:'5vh 2vw'}}>

      <Grid  style={{marginBottom:'2vh',textAlign:'end'}}item sm={12} xs={12}>
      <Chip onClick={()=>setReload(reload+1)} label='Refresh' style={{color:'white',background:'grey',marginRight:'3vw'}}  />
      <Chip onClick={()=>signOut()}label='Signout' style={{color:'white',background:'black',marginRight:'3vw'}}  />

        <Chip onClick={()=>window.location.href='/'}   label='Back To Home' style={{color:'white',background:'var(--green)'}}  />

      </Grid>
      <Grid item sm={9} xs={12}>
      <Paper className='ppr_today'>
<Grid item sm={12} xs={12}>
  <Typography className='head_today'>
    Today
  </Typography>
</Grid>
<Grid container sm={12} xs={12} style={{marginTop:'4vh',justifyContent:'space-evenly'}}>
<Grid item style={{padding:2}} sm={3} xs={6}>
<Paper onClick={()=>setActive('report')} className={active==='report'?'ppr_sub_today_active':'ppr_sub_today'}>
<Typography className='head_sub_today'>
Total  Reporting jobs   <br /> <br /><b>{jobs.length}</b>
  </Typography>
 </Paper>
  </Grid>
  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>setActive('complete')} className={active==='complete'?'ppr_sub_today_active':'ppr_sub_today'}>
<Typography className='head_sub_today'>
Total  Completing Jobs   <br /> <br /><b>{completed.filter(val=>val.job_status).length}</b>
  </Typography>
 </Paper>
  </Grid>

</Grid>


</Paper>
      </Grid>
      <Grid item sm={3} style={{padding:'0px 1vw'}} xs={12}>
<Paper onClick={()=>setOpenD(true)} style={{height:'100%'}} className='ppr_sub_today'>
<Typography  className='head_download'>
    Downloads
  </Typography>
 </Paper>
  </Grid>
 {active=='report'? <Grid container style={{marginTop:'4vh',justifyContent:'space-evenly'}}>

  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>reportFxn('Reported Jobs')} className='head_sub_today_green'>
<Typography className='head_sub_today'>
    Reported Jobs<br /> <br /><b>{jobs.filter(val=>val.job_status).length}</b>
  </Typography>
 </Paper>
  </Grid>
  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>reportFxn('To Be Reported')}  className='head_sub_today_grey'>
<Typography  className='head_sub_today'>
To Be Reported <br /> <br /><b>{jobs.filter(val=>!val.job_status&&(new Date()<new Date(val.job_start_date+'T'+val.job_start_time))).length}</b>
  </Typography>
 </Paper>
  </Grid>
  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>reportFxn('Unreported Jobs')} className='head_sub_today_red'>
<Typography className='head_sub_today'>
    Unreported Jobs <br /> <br /><b>{jobs.filter(val=>(!val.job_status&&(new Date()>new Date(val.job_start_date+'T'+val.job_start_time)))).length}</b>
  </Typography>
 </Paper>
  </Grid>
</Grid>
:active=='complete'?<Grid container style={{marginTop:'4vh',justifyContent:'space-evenly'}}>

  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>completeFxn('Completed Jobs')} className='head_sub_today_green'>
<Typography className='head_sub_today'>
    Completed Jobs <br /> <br /><b>{completed.filter(val=>val.job_status==='Completed Successfully').length}</b>
  </Typography>
 </Paper>
  </Grid>
  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>completeFxn('To Be Completed')} className='head_sub_today_grey'>
<Typography  className='head_sub_today'>
    To Be Completed<br /> <br /><b>{completed.filter(val=>val.job_status&&val.job_status!=='Completed Successfully'&&(new Date()<=new Date(val.job_end_date+'T'+val.job_end_time))).length}</b>
  </Typography>
 </Paper>
  </Grid>
  <Grid style={{padding:2}} item sm={3} xs={6}>
<Paper onClick={()=>completeFxn('Uncompleted Jobs')} className='head_sub_today_red'>
<Typography className='head_sub_today'>
    Uncompleted Jobs <br /> <br /><b>{completed.filter(val=>val.job_status&&val.job_status!=='Completed Successfully'&&val.job_status!=='Completed By Driver'&&(new Date()>new Date(val.job_end_date+'T'+val.job_end_time))).length}</b>
  </Typography>
 </Paper>
  </Grid>
</Grid>:null}

     
    </Grid>

    <Grid container style={{padding:'0px 2vw'}}>
      <Grid item sm={12} xs={12}>

<Grid container style={{marginTop:'4vh',justifyContent:'space-evenly'}}>
<Grid item padding={'2vw 2vw'} sm={3} xs={12}>

<Paper className='ppr_today' style={{minHeight:300}}>
<Grid item sm={12} xs={12}>
<Typography  className='head_today'>
    Drivers
  </Typography>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>setScreen('Verified Drivers')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}}   className='ppr_sub_today'>
Verified Drivers ({drivers.filter(val=>val.is_verified).length})
  </Paper>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>setScreen('UnVerified Drivers')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Unverified Drivers ({drivers.filter(val=>!val.is_verified).length})
  </Paper>
</Grid>
{/* <Grid item sm={12} xs={12}>
  <Paper style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Redeem Requests
  </Paper>
</Grid> */}
 </Paper>
  </Grid>
  <Grid item padding={'2vw 2vw'} sm={3} xs={12}>

<Paper className='ppr_today' style={{minHeight:300}}>
<Grid item sm={12} xs={12}>
<Typography  className='head_today'>
    Business
  </Typography>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>setScreen('Verified Business')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Verified Businesses ({business.filter(val=>val.is_verified).length})
  </Paper>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>setScreen('UnVerified Business')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Unverified Businesses ({business.filter(val=>!val.is_verified).length})
  </Paper>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>setScreen('Low Credits Business')}  style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Low Credits
  </Paper>
</Grid>
 </Paper>
  </Grid>
  <Grid item padding={'2vw 2vw'} sm={3} xs={12}>

<Paper className='ppr_today' style={{minHeight:300}}>
<Grid item sm={12} xs={12}>
<Typography  className='head_today'>
    Jobs
  </Typography>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>Screen('Job','Search Job')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Search Job
  </Paper>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>Screen('Active Jobs')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Active Jobs
  </Paper>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper onClick={()=>Screen('Completed Jobs')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Completed jobs
  </Paper>
</Grid>

 </Paper>
  </Grid>
  <Grid item padding={'2vw 2vw'} sm={3} xs={12}>

<Paper className='ppr_today' style={{minHeight:300}}>
<Grid item sm={12} xs={12}>
<Typography  className='head_today'>
    Payments
  </Typography>
</Grid>
{/* <Grid item sm={12} xs={12}>
  <Paper style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Received Payments
  </Paper>
</Grid>
<Grid item sm={12} xs={12}>
  <Paper style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Payouts
  </Paper>
</Grid> */}
<Grid item sm={12} xs={12}>
  {/* <Paper style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Search Payment
  </Paper> */}
    <Paper onClick={()=>setScreen('Invoices')} style={{padding:'1vh 0px',color:'white',fontSize:17,margin:'3vh 0px'}} className='ppr_sub_today'>
Invoices  </Paper>
</Grid>
 </Paper>
  </Grid>

</Grid>
      </Grid>
   
     
    </Grid>
    </>}
    </Grid>} 
    </div>   )
}
