import React from 'react'
import HIW_below_bg from './HIW_below_bg/HIW_below_bg'
import HIW_bg from './HIW_bg/HIW_bg'

export default function How_It_Works() {
  return (
    <>
    <div style={{height:'90vh'}}>
<HIW_bg /> 
    </div>
    <HIW_below_bg />
    </>
 )
}
