import * as React from 'react';

import {useCookies} from 'react-cookie'


import './dashboard.css'
import Dashboard_Admin from './dashboard_admin';
import Dashboard_Business from './dashboard_business';
import Dashboard_Driver from './dashboard_driver';



export default function Dashboard(props) {
  const[auth]=useCookies(['drivewide'])






if(!auth['drivewide'])(
  window.location.href='/signin'

)
else{


  return (
    <div>

     
      {auth.drivewide.is_admin?<Dashboard_Admin/>:  auth.drivewide.type==='Driver'&&!auth.drivewide.is_admin?<Dashboard_Driver />:auth.drivewide.type==='Business'&&!auth.drivewide.is_admin?<Dashboard_Business/>:null}
      
    </div>
  );}
}
